import { MutationTree } from "vuex";
import MutationTypes from "@/store/types/mutation-types";
import { State } from "@/store/state";

export type Mutations<S = State> = {
  [MutationTypes.SET_SELECTED_CLIENT](state: S, payload: number): void;
  [MutationTypes.SET_SELECTED_CLIENT_FULL](state: S, payload: object): void;
  [MutationTypes.SET_DASHBOARD_SHOWN](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_MEMBER_DETAILS_MODAL_OPEN](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_EMPLOYEE_REVIEW_MODAL_OPEN](state: S, payload: boolean): void;
  [MutationTypes.SET_COMPANY_CLIENT_LIST](state: S, payload: Array<string | unknown>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SELECTED_CLIENT](state, payload) {
    state.selectedClient = payload;
  },
  //
  [MutationTypes.SET_SELECTED_CLIENT_FULL](state, payload) {
    state.selectedClientFull = payload;
  },
  //
  [MutationTypes.SET_DASHBOARD_SHOWN](state, payload) {
    state.isDashboardShown = payload;
  },
  //
  [MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN](state, payload) {
    state.isReqShiftModalOpen = payload;
  },
  //
  [MutationTypes.SET_IS_MEMBER_DETAILS_MODAL_OPEN](state, payload) {
    state.isMemberDetailsModalOpen = payload;
  },
  //
  [MutationTypes.SET_IS_EMPLOYEE_REVIEW_MODAL_OPEN](state, payload) {
    state.isEmployeeReviewModalOpen = payload;
  },
  //
  [MutationTypes.SET_COMPANY_CLIENT_LIST](state, payload) {
    state.companyClientList = payload;
  },
};
