/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NumberOfCompanyClientShiftsResponse
 */
export interface NumberOfCompanyClientShiftsResponse {
    /**
     * Total number of shifts for a client (including upcoming, past and in between).
     * @type {number}
     * @memberof NumberOfCompanyClientShiftsResponse
     */
    numberOfShifts: number;
    /**
     * Number of upcoming client shifts.
     * @type {number}
     * @memberof NumberOfCompanyClientShiftsResponse
     */
    numberOfUpcomingShifts?: number;
    /**
     * Number of client shifts for the future, that are open (number of signed up people is less then number of needed people).
     * @type {number}
     * @memberof NumberOfCompanyClientShiftsResponse
     */
    numberOfOpenShifts?: number;
    /**
     * Number of client shifts for the future, that are filled (number of signed up people is equal or greater then number of needed people).
     * @type {number}
     * @memberof NumberOfCompanyClientShiftsResponse
     */
    numberOfFilledShifts?: number;
    /**
     * Number of client shifts for the past (end time of shift is in the past).
     * @type {number}
     * @memberof NumberOfCompanyClientShiftsResponse
     */
    numberOfPastShifts?: number;
}

export function NumberOfCompanyClientShiftsResponseFromJSON(json: any): NumberOfCompanyClientShiftsResponse {
    return NumberOfCompanyClientShiftsResponseFromJSONTyped(json, false);
}

export function NumberOfCompanyClientShiftsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumberOfCompanyClientShiftsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfShifts': json['numberOfShifts'],
        'numberOfUpcomingShifts': !exists(json, 'numberOfUpcomingShifts') ? undefined : json['numberOfUpcomingShifts'],
        'numberOfOpenShifts': !exists(json, 'numberOfOpenShifts') ? undefined : json['numberOfOpenShifts'],
        'numberOfFilledShifts': !exists(json, 'numberOfFilledShifts') ? undefined : json['numberOfFilledShifts'],
        'numberOfPastShifts': !exists(json, 'numberOfPastShifts') ? undefined : json['numberOfPastShifts'],
    };
}

export function NumberOfCompanyClientShiftsResponseToJSON(value?: NumberOfCompanyClientShiftsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfShifts': value.numberOfShifts,
        'numberOfUpcomingShifts': value.numberOfUpcomingShifts,
        'numberOfOpenShifts': value.numberOfOpenShifts,
        'numberOfFilledShifts': value.numberOfFilledShifts,
        'numberOfPastShifts': value.numberOfPastShifts,
    };
}

