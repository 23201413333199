/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The class of a registration, TIME_REGISTRATION for TimeRegistrationLines, FREELANCE_REGISTRATION for FreelanceRegistrationLines or SHIFTS_ONLY_REGISTRATION for ShiftsOnlyRegistrationLines.
 * @export
 */
export const RegistrationClass = {
    ShiftsOnlyRegistration: 'SHIFTS_ONLY_REGISTRATION',
    TimeRegistration: 'TIME_REGISTRATION',
    FreelanceRegistration: 'FREELANCE_REGISTRATION'
} as const;
export type RegistrationClass = typeof RegistrationClass[keyof typeof RegistrationClass];


export function RegistrationClassFromJSON(json: any): RegistrationClass {
    return RegistrationClassFromJSONTyped(json, false);
}

export function RegistrationClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationClass {
    return json as RegistrationClass;
}

export function RegistrationClassToJSON(value?: RegistrationClass | null): any {
    return value as any;
}

