
import { defineComponent } from "vue";
import { Icon } from "mq-ui-kit/lib/Icon";

export default defineComponent({
  name: "SearchTwo",
  components: {
    Icon
  },
  emits: ["update:modelValue", "clear"],
  props: {
    iconRight: {
      type: Boolean
    },
    iconLeft: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    modelValue: {
      type: String
    }
  },
  setup(props, { emit }) {
    const onInput = (event: Event) => {
      emit("update:modelValue", event);
    };

    const removeInputValue = () => {
      // emit("update:modelValue", "");
      emit("clear", "");
    };

    return {
      onInput,
      removeInputValue
    };
  }
});
