/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewAverageResponse
 */
export interface CompanyClientEmployeeReviewAverageResponse {
    /**
     * Review score of this employee
     * @type {number}
     * @memberof CompanyClientEmployeeReviewAverageResponse
     */
    reviewScoreAverage: number;
}

export function CompanyClientEmployeeReviewAverageResponseFromJSON(json: any): CompanyClientEmployeeReviewAverageResponse {
    return CompanyClientEmployeeReviewAverageResponseFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewAverageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewAverageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewScoreAverage': json['reviewScoreAverage'],
    };
}

export function CompanyClientEmployeeReviewAverageResponseToJSON(value?: CompanyClientEmployeeReviewAverageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewScoreAverage': value.reviewScoreAverage,
    };
}

