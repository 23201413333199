
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import DashboardHeader from "@/components/dashboard/DashboardHeader.vue";
import DashboardTodo from "@/components/dashboard/DashboardTodo.vue";
import DashboardShifts from "@/components/dashboard/DashboardShifts.vue";
import DashboardRegistrations from "@/components/dashboard/DashboardRegistrations.vue";

import { useRoleCheck } from "@/composables/role-check";

export default defineComponent({
  name: "Home",
  components: {
    DashboardHeader,
    DashboardTodo,
    DashboardShifts,
    DashboardRegistrations,
  },
  // inject: ["provideKeycloak"],
  setup() {
    // const testInject = inject("provideKeycloak");
    // const instance = getCurrentInstance();
    // console.log("HOME Setup method 1: ", testInject);
    // console.log(
    //   "HOME Setup method 2: ",
    //   instance?.appContext.config.globalProperties.$keycloak
    // );

    const store = useStore();

    const dashboardShown = computed(() => store.state.isDashboardShown);

    const checkPermissions = (role: string) => useRoleCheck(role);

    return {
      dashboardShown,
      checkPermissions,
    };
  },
});
