/* tslint:disable */
/* eslint-disable */
/**
 * Company Clients API
 * API to get company clients data
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientOfUserResponse,
    CompanyClientOfUserResponseFromJSON,
    CompanyClientOfUserResponseFromJSONTyped,
    CompanyClientOfUserResponseToJSON,
} from './CompanyClientOfUserResponse';

/**
 * 
 * @export
 * @interface CompanyClientOfUserOptionalResponse
 */
export interface CompanyClientOfUserOptionalResponse {
    /**
     * Populated if no role mapppings are found.
     * @type {string}
     * @memberof CompanyClientOfUserOptionalResponse
     */
    errorId?: CompanyClientOfUserOptionalResponseErrorIdEnum;
    /**
     * 
     * @type {CompanyClientOfUserResponse}
     * @memberof CompanyClientOfUserOptionalResponse
     */
    client?: CompanyClientOfUserResponse;
}


/**
 * @export
 */
export const CompanyClientOfUserOptionalResponseErrorIdEnum = {
    SecurityDataNotFound: 'SECURITY_DATA_NOT_FOUND'
} as const;
export type CompanyClientOfUserOptionalResponseErrorIdEnum = typeof CompanyClientOfUserOptionalResponseErrorIdEnum[keyof typeof CompanyClientOfUserOptionalResponseErrorIdEnum];


export function CompanyClientOfUserOptionalResponseFromJSON(json: any): CompanyClientOfUserOptionalResponse {
    return CompanyClientOfUserOptionalResponseFromJSONTyped(json, false);
}

export function CompanyClientOfUserOptionalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientOfUserOptionalResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorId': !exists(json, 'errorId') ? undefined : json['errorId'],
        'client': !exists(json, 'client') ? undefined : CompanyClientOfUserResponseFromJSON(json['client']),
    };
}

export function CompanyClientOfUserOptionalResponseToJSON(value?: CompanyClientOfUserOptionalResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorId': value.errorId,
        'client': CompanyClientOfUserResponseToJSON(value.client),
    };
}

