/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';

/**
 * 
 * @export
 * @interface UpcomingShiftResponse
 */
export interface UpcomingShiftResponse {
    /**
     * Shift ID
     * @type {number}
     * @memberof UpcomingShiftResponse
     */
    shiftId: number;
    /**
     * Shift's gig name
     * @type {string}
     * @memberof UpcomingShiftResponse
     */
    gigName: string;
    /**
     * Time when shift starts
     * @type {Date}
     * @memberof UpcomingShiftResponse
     */
    startTimestamp: Date;
    /**
     * Time when shift ends
     * @type {Date}
     * @memberof UpcomingShiftResponse
     */
    endTimestamp: Date;
    /**
     * Number of employees needed
     * @type {number}
     * @memberof UpcomingShiftResponse
     */
    numberOfEmployeesNeeded: number;
    /**
     * Number of employees accepted
     * @type {number}
     * @memberof UpcomingShiftResponse
     */
    numberOfEmployeesAccepted: number;
    /**
     * Shift's location
     * @type {string}
     * @memberof UpcomingShiftResponse
     */
    workLocation: string;
    /**
     * Number of new applicants
     * @type {number}
     * @memberof UpcomingShiftResponse
     */
    numberOfNewApplicants: number;
    /**
     * List of new applicants that needs to be approved by the planner.
     * @type {Array<Employees>}
     * @memberof UpcomingShiftResponse
     */
    newApplicants: Array<Employees>;
}

export function UpcomingShiftResponseFromJSON(json: any): UpcomingShiftResponse {
    return UpcomingShiftResponseFromJSONTyped(json, false);
}

export function UpcomingShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpcomingShiftResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'],
        'gigName': json['gigName'],
        'startTimestamp': (new Date(json['startTimestamp'])),
        'endTimestamp': (new Date(json['endTimestamp'])),
        'numberOfEmployeesNeeded': json['numberOfEmployeesNeeded'],
        'numberOfEmployeesAccepted': json['numberOfEmployeesAccepted'],
        'workLocation': json['workLocation'],
        'numberOfNewApplicants': json['numberOfNewApplicants'],
        'newApplicants': ((json['newApplicants'] as Array<any>).map(EmployeesFromJSON)),
    };
}

export function UpcomingShiftResponseToJSON(value?: UpcomingShiftResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shiftId': value.shiftId,
        'gigName': value.gigName,
        'startTimestamp': (value.startTimestamp.toISOString()),
        'endTimestamp': (value.endTimestamp.toISOString()),
        'numberOfEmployeesNeeded': value.numberOfEmployeesNeeded,
        'numberOfEmployeesAccepted': value.numberOfEmployeesAccepted,
        'workLocation': value.workLocation,
        'numberOfNewApplicants': value.numberOfNewApplicants,
        'newApplicants': ((value.newApplicants as Array<any>).map(EmployeesToJSON)),
    };
}

