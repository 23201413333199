/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';
import {
    PaginationPage,
    PaginationPageFromJSON,
    PaginationPageFromJSONTyped,
    PaginationPageToJSON,
} from './PaginationPage';

/**
 * 
 * @export
 * @interface EmployeesOfCompanyClientResponse
 */
export interface EmployeesOfCompanyClientResponse {
    /**
     * 
     * @type {Array<Employees>}
     * @memberof EmployeesOfCompanyClientResponse
     */
    resultList: Array<Employees>;
    /**
     * 
     * @type {PaginationPage}
     * @memberof EmployeesOfCompanyClientResponse
     */
    page: PaginationPage;
}

export function EmployeesOfCompanyClientResponseFromJSON(json: any): EmployeesOfCompanyClientResponse {
    return EmployeesOfCompanyClientResponseFromJSONTyped(json, false);
}

export function EmployeesOfCompanyClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeesOfCompanyClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultList': ((json['resultList'] as Array<any>).map(EmployeesFromJSON)),
        'page': PaginationPageFromJSON(json['page']),
    };
}

export function EmployeesOfCompanyClientResponseToJSON(value?: EmployeesOfCompanyClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultList': ((value.resultList as Array<any>).map(EmployeesToJSON)),
        'page': PaginationPageToJSON(value.page),
    };
}

