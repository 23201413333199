import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3c46390"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-table-holder" }
const _hoisted_2 = { class: "data-table" }
const _hoisted_3 = {
  key: 0,
  class: "data-table__total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTableHeader = _resolveComponent("DataTableHeader")!
  const _component_DataTableBody = _resolveComponent("DataTableBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataTableHeader, {
        columns: _ctx.columns,
        onHeaderItemClick: _ctx.onHeaderItemClick
      }, _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (index, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
            ])
          }
        })
      ]), 1032, ["columns", "onHeaderItemClick"]),
      _createVNode(_component_DataTableBody, {
        columns: _ctx.columns,
        items: _ctx.items,
        "item-key-name": _ctx.itemKeyName,
        "item-logo-link": _ctx.itemLogoLink,
        onScrollReachedBottom: _ctx.onScrollReachedBottom,
        onItemSelect: _ctx.onItemSelect
      }, _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (index, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
            ])
          }
        })
      ]), 1032, ["columns", "items", "item-key-name", "item-logo-link", "onScrollReachedBottom", "onItemSelect"]),
      (_ctx.totalElements)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("results_in_total", _ctx.totalElements)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}