// import {
//   ShiftFilterShiftTypeEnum
// } from "@/services/rest/dashboard";

import { ShiftFilterShiftTypeEnum } from "@/../rpc-api-source/models";

export interface TabFilterInterface {
  name: string;
  type: ShiftFilterShiftTypeEnum;
  numOfItems: number;
}

export enum ErrorCodes {
  NotFound = "NOT_FOUND"
}
// export interface TabFilterNewestRegInterface
//   extends Omit<TabFilterInterface, "type"> {
//   type?: RegistrationsForCompanyClientRequestFiltersStatusEnum[];
// }

export enum CookieProps {
  UserToken = "USER_TOKEN",
  LogoutUri = "LOGOUT_URI",
  ClientId = "CLIENT_ID",
  RealmName = "REALM_NAME"
}

// TODO: in the future, fill this one with api call
export const Roles = {
  Admin: "admin",
  Bookkeeper: "bookkeeper",
  Planner: "planner",
  Floormanager: "floormanager"
} as const;