
import { defineComponent } from "vue";
import CheckBoxSmall from "@/components/ui/CheckBoxSmall.vue";

export default defineComponent({
  name: "RegistrationListItemCheckbox",

  components: {
    CheckBoxSmall,
  },

  props: {
    id: {
      type: Number
    },
    isChecked: {
      type: Boolean,
    },
  },

  emits: ["change"],

  setup(_props, { emit }) {
    const onChange = (value: boolean) => {
      emit("change", value);
    };

    return {
      onChange,
    };
  },
});
