import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3516dbb1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-bar" }
const _hoisted_2 = { class: "p-bar__progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeopleCountTag = _resolveComponent("PeopleCountTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "p-bar__progress--percentage",
        style: _normalizeStyle(_ctx.percOfUsers)
      }, null, 4)
    ]),
    _createVNode(_component_PeopleCountTag, {
      needed: _ctx.needed,
      accepted: _ctx.accepted,
      "people-right-tag": _ctx.peopleRightTag
    }, null, 8, ["needed", "accepted", "people-right-tag"])
  ]))
}