
import { computed, defineComponent, PropType, ref } from "vue";
import { useErrorImgHandler } from "@/composables/error-img-hanlder";

export default defineComponent({
  name: "Avatar",
  props: {
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "small",
      validator: (val: string) => ["small", "medium", "large"].includes(val),
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
    lastName: {
      type: String,
    },
    imgPath: {
      type: String,
    },
    // add your custom size avatar, one value bcs its square
    customSize: {
      type: Number,
    },
    // add your custom size avatar, one value bcs its square
    customFontSize: {
      type: Number,
    },
    shape: {
      type: String as PropType<"circle" | "square">,
      default: "circle",
      validator: (val: string) => ["circle", "square"].includes(val),
    },
    // add to show like option
    isLikeable: {
      type: Boolean,
      default: false,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["member-liked"],
  setup(props, { emit }) {
    const { isImgError, handleErrorImg, imgErrorClass } = useErrorImgHandler();

    const customClazz = computed(() => ({
        small: props.size === "small",
        medium: props.size === "medium",
        large: props.size === "large",
        square: props.shape === "square",
      }));

    const customSizeCalc = computed(() => `width: ${props.customSize}px; height: ${props.customSize}px`);

    const nameInitials = (name: string, lastName?: string) => lastName ?
     `${name?.charAt(0)}${lastName.charAt(0)}` : `${name?.charAt(0)}`;

    const isImgLoaded = ref(true);

    const likeMember = () => {
      emit("member-liked");
    };

    return {
      customClazz,
      nameInitials,
      customSizeCalc,
      isImgLoaded,
      likeMember,
      handleErrorImg,
      imgErrorClass,
      isImgError,
    };
  },
});
