<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- eslint-disable -->
    <defs>
      <path
        id="a"
        d="M15.6 15.229a.629.629 0 0 1 0 1.257H1.03a.629.629 0 1 1 0-1.257ZM11.255.199l3.111 3.143-2.784 2.779 1.364 1.313-3.112 3.143a.629.629 0 1 1-.892-.886l2.206-2.225-.452-.453-7.034 6.984H.57v-3.112L11.256.2ZM2.029 11.231l-.208.226v1.339h1.333l.207-.233-1.332-1.332Z"
      />
    </defs>
    <use xlink:href="#a" fill-rule="evenodd" />
  </svg>
</template>
