/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientReviewLabelOfEmployee,
    CompanyClientReviewLabelOfEmployeeFromJSON,
    CompanyClientReviewLabelOfEmployeeFromJSONTyped,
    CompanyClientReviewLabelOfEmployeeToJSON,
} from './CompanyClientReviewLabelOfEmployee';

/**
 * 
 * @export
 * @interface CompanyClientReviewScoreSummaryResponse
 */
export interface CompanyClientReviewScoreSummaryResponse {
    /**
     * Review score of this employee
     * @type {number}
     * @memberof CompanyClientReviewScoreSummaryResponse
     */
    reviewScoreAverage: number;
    /**
     * Total count of reviews given for this employee
     * @type {number}
     * @memberof CompanyClientReviewScoreSummaryResponse
     */
    countOfReviews: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CompanyClientReviewScoreSummaryResponse
     */
    reviewSummary?: { [key: string]: number; };
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientReviewScoreSummaryResponse
     */
    labelsPositive?: Array<CompanyClientReviewLabelOfEmployee>;
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientReviewScoreSummaryResponse
     */
    labelsToImprove?: Array<CompanyClientReviewLabelOfEmployee>;
}

export function CompanyClientReviewScoreSummaryResponseFromJSON(json: any): CompanyClientReviewScoreSummaryResponse {
    return CompanyClientReviewScoreSummaryResponseFromJSONTyped(json, false);
}

export function CompanyClientReviewScoreSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientReviewScoreSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewScoreAverage': json['reviewScoreAverage'],
        'countOfReviews': json['countOfReviews'],
        'reviewSummary': !exists(json, 'reviewSummary') ? undefined : json['reviewSummary'],
        'labelsPositive': !exists(json, 'labelsPositive') ? undefined : ((json['labelsPositive'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
        'labelsToImprove': !exists(json, 'labelsToImprove') ? undefined : ((json['labelsToImprove'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
    };
}

export function CompanyClientReviewScoreSummaryResponseToJSON(value?: CompanyClientReviewScoreSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewScoreAverage': value.reviewScoreAverage,
        'countOfReviews': value.countOfReviews,
        'reviewSummary': value.reviewSummary,
        'labelsPositive': value.labelsPositive === undefined ? undefined : ((value.labelsPositive as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
        'labelsToImprove': value.labelsToImprove === undefined ? undefined : ((value.labelsToImprove as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
    };
}

