/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used in an employee review to indicate whether the given label is meant as a strength of the employee, or a skill the employee can improve on. For example, if a review has a label 'punctuality', a 'POSITIVE' application of that label would mean the employee is punctual, an 'IMPROVEMENT_POINT' application of the label would mean the employee can improve on their punctuality.
 * @export
 */
export const CompanyClientReviewLabelApplicationKind = {
    Positive: 'POSITIVE',
    ImprovementPoint: 'IMPROVEMENT_POINT'
} as const;
export type CompanyClientReviewLabelApplicationKind = typeof CompanyClientReviewLabelApplicationKind[keyof typeof CompanyClientReviewLabelApplicationKind];


export function CompanyClientReviewLabelApplicationKindFromJSON(json: any): CompanyClientReviewLabelApplicationKind {
    return CompanyClientReviewLabelApplicationKindFromJSONTyped(json, false);
}

export function CompanyClientReviewLabelApplicationKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientReviewLabelApplicationKind {
    return json as CompanyClientReviewLabelApplicationKind;
}

export function CompanyClientReviewLabelApplicationKindToJSON(value?: CompanyClientReviewLabelApplicationKind | null): any {
    return value as any;
}

