import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3319031a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "employee-reviews"
}
const _hoisted_2 = { class: "card-wrap__container" }
const _hoisted_3 = { class: "card-wrap__content" }
const _hoisted_4 = { class: "card-wrap__name" }
const _hoisted_5 = { class: "card-wrap__location" }
const _hoisted_6 = {
  key: 0,
  class: "card-wrap__count"
}
const _hoisted_7 = { class: "card-wrap__tabs" }
const _hoisted_8 = { class: "card-wrap__about" }
const _hoisted_9 = { class: "card-wrap__about--headline" }
const _hoisted_10 = {
  key: 0,
  class: "card-wrap__about--description"
}
const _hoisted_11 = { class: "card-wrap__about--shifts" }
const _hoisted_12 = {
  key: 1,
  class: "employee-reviews__bottom"
}
const _hoisted_13 = { class: "employee-reviews__average" }
const _hoisted_14 = { class: "employee-reviews__average-container" }
const _hoisted_15 = { class: "employee-reviews__average-head" }
const _hoisted_16 = { class: "employee-reviews__average-score" }
const _hoisted_17 = { class: "employee-reviews__average-score-on" }
const _hoisted_18 = { class: "employee-reviews__progress-bar-holder" }
const _hoisted_19 = { class: "employee-reviews__star-content" }
const _hoisted_20 = { class: "employee-reviews__star-index" }
const _hoisted_21 = { class: "employee-reviews__progress-index" }
const _hoisted_22 = { class: "employee-reviews__sum--title" }
const _hoisted_23 = { class: "employee-reviews__sum--container" }
const _hoisted_24 = { class: "employee-reviews__sum--header" }
const _hoisted_25 = { class: "employee-reviews__sum--name" }
const _hoisted_26 = { class: "card-wrap__content" }
const _hoisted_27 = { class: "employee-reviews__sum--content" }
const _hoisted_28 = { class: "employee-reviews__sum--date" }
const _hoisted_29 = {
  key: 0,
  class: "employee-reviews__sum--note"
}
const _hoisted_30 = { class: "employee-reviews__sum--labels" }
const _hoisted_31 = { class: "employee-reviews__sum--positive" }
const _hoisted_32 = { class: "employee-reviews__sum--negative" }
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { class: "employee-reviews__no--reviews" }
const _hoisted_35 = { class: "employee-reviews__no--reviews--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_SvgStar = _resolveComponent("SvgStar")!
  const _component_ProgressBarEmployee = _resolveComponent("ProgressBarEmployee")!
  const _component_ExpandCollapse = _resolveComponent("ExpandCollapse")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_CardWrap = _resolveComponent("CardWrap")!

  return (_ctx.dashboardShown && !_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CardWrap, { class: "card-wrap" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Avatar, {
                name: _ctx.employee.firstName,
                "last-name": _ctx.employee.lastName,
                "img-path": _ctx.employee.profilePictureUrl,
                "custom-size": 120,
                class: "card-wrap__image",
                size: "medium",
                "is-liked": _ctx.employee.favourite,
                "is-likeable": "",
                onMemberLiked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favouriteEmployee(_ctx.employee.employeeId)))
              }, null, 8, ["name", "last-name", "img-path", "is-liked"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.employee.fullName), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.employee.city), 1),
                (_openBlock(), _createBlock(_component_Rating, {
                  key: _ctx.employee.reviewScoreAverage,
                  value: _ctx.employee.reviewScoreAverage,
                  scale: 1.5,
                  disabled: ""
                }, null, 8, ["value", "scale"])),
                (_ctx.employeeReviewCount > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, " (" + _toDisplayString(_ctx.employeeReviewCount) + ") ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", {
                class: _normalizeClass(["card-wrap__tab", { active: _ctx.isActiveTab === 'about' }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab('about')))
              }, _toDisplayString(_ctx.$t("global.about")), 3),
              _createElementVNode("p", {
                class: _normalizeClass(["card-wrap__tab", { active: _ctx.isActiveTab === 'reviews' }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectTab('reviews')))
              }, _toDisplayString(_ctx.$t("global.reviews")), 3)
            ]),
            (_ctx.isActiveTab === 'about')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["employee-reviews__bottom", {'employee-reviews__bottom--column' : _ctx.isActiveTab === 'about'}])
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("global.about")), 1),
                    (_ctx.employee.about)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.employee.about), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("shift_details.worked_shifts", { n: _ctx.employee.numberOfCompaniesShifts })) + ", " + _toDisplayString(_ctx.$t("shift_details.at_your_company", {
                n: _ctx.employee.numberOfCompaniesClientsShifts,
              })), 1)
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.employeeReviewOverviewScore.countOfReviews > 0 && _ctx.isActiveTab === 'reviews')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_SvgStar, { class: "employee-reviews__star" }),
                        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.roundDecimals(_ctx.employeeReviewOverviewScore.reviewScoreAverage)) + " / 5 ", 1),
                        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("based_on")) + " " + _toDisplayString(_ctx.$t("rating_count", _ctx.employeeReviewOverviewScore.countOfReviews)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(
                  _ctx.employeeReviewOverviewScore.reviewSummary
                )
                  .slice()
                  .reverse(), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "employee-reviews__progress-bar",
                            key: index
                          }, [
                            _createElementVNode("p", _hoisted_19, [
                              _createVNode(_component_SvgStar, { class: "employee-reviews__star-number" }),
                              _createElementVNode("span", _hoisted_20, _toDisplayString(item), 1)
                            ]),
                            _createVNode(_component_ProgressBarEmployee, {
                              class: "card-upcom__progress",
                              needed: Number(_ctx.employeeReviewOverviewScore.countOfReviews),
                              accepted: Number(_ctx.employeeReviewOverviewScore.reviewSummary[item]),
                              "people-right-tag": false
                            }, null, 8, ["needed", "accepted"]),
                            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.employeeReviewOverviewScore.reviewSummary[item]), 1)
                          ]))
                        }), 128))
                      ])
                    ]),
                    (_ctx.employeeReviewOverviewScore.labelsPositive!.length > 0)
                      ? (_openBlock(), _createBlock(_component_ExpandCollapse, {
                          key: 0,
                          name: _ctx.$t('qualities'),
                          items: _ctx.employeeReviewOverviewScore.labelsPositive
                        }, null, 8, ["name", "items"]))
                      : _createCommentVNode("", true),
                    (_ctx.employeeReviewOverviewScore.labelsToImprove!.length > 0)
                      ? (_openBlock(), _createBlock(_component_ExpandCollapse, {
                          key: 1,
                          name: _ctx.$t('improvements'),
                          items: _ctx.employeeReviewOverviewScore.labelsToImprove
                        }, null, 8, ["name", "items"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: "employee-reviews__sum",
                    onScroll: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.debouncedFn && _ctx.debouncedFn(...args)))
                  }, [
                    _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.employeeReviewOverview.totalElements) + " " + _toDisplayString(_ctx.$t("global.reviews")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeReviewOverview.resultList, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "employee-reviews__sum--progress-bar",
                        key: index
                      }, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_Avatar, {
                              name: item.reviewer!.firstName,
                              "last-name": item.reviewer!.lastName,
                              "img-path": item.reviewer!.profilePictureUrl,
                              "custom-size": 48,
                              "custom-font-size": 16,
                              class: "card-wrap__image",
                              size: "medium"
                            }, null, 8, ["name", "last-name", "img-path"]),
                            _createElementVNode("div", null, [
                              _createElementVNode("p", _hoisted_25, [
                                _createTextVNode(_toDisplayString(item.reviewer!.firstName) + " ", 1),
                                (item.reviewer!.middleName)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString(item.reviewer!.middleName), 1)
                                    ], 64))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(item.reviewer!.lastName), 1)
                              ]),
                              (_openBlock(), _createBlock(_component_Rating, {
                                key: item.reviewScore,
                                value: item.reviewScore,
                                scale: 1,
                                disabled: ""
                              }, null, 8, ["value"]))
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t("review_of", {
                        name: item.gigName,
                        date: _ctx.dateFormat(item.shiftStartTimestamp),
                      })), 1),
                              (item.note)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_29, _toDisplayString(item.note), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.labelsPositive, (label, indexPos) => {
                                    return (_openBlock(), _createElementBlock("p", {
                                      class: "employee-reviews__sum--label",
                                      key: indexPos
                                    }, [
                                      _createVNode(_component_FaIcon, {
                                        class: "employee-reviews__sum--plus",
                                        icon: "plus"
                                      }),
                                      _createElementVNode("span", null, _toDisplayString(label.description), 1)
                                    ]))
                                  }), 128))
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.labelsToImprove, (label, indexNeg) => {
                                    return (_openBlock(), _createElementBlock("p", {
                                      class: "employee-reviews__sum--label",
                                      key: indexNeg
                                    }, [
                                      _createVNode(_component_FaIcon, {
                                        class: "employee-reviews__sum--minus",
                                        icon: "minus"
                                      }),
                                      _createElementVNode("span", null, _toDisplayString(label.description), 1)
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ], 32)
                ]))
              : _createCommentVNode("", true),
            (_ctx.employeeReviewOverviewScore.countOfReviews === 0 && _ctx.isActiveTab !== 'about')
              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t("no_reviews_yet")), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}