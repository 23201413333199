<template>
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable -->
    <path
      d="M2.674 9.189h7.118l2.628 8.674H.03l2.645-8.674Zm3.548-.868c-2.185 0-3.957-1.747-3.957-3.903 0-2.156 1.772-3.904 3.957-3.904 2.186 0 3.957 1.748 3.957 3.904 0 2.156-1.771 3.903-3.957 3.903Z"
      fill="#41D8D3"
      fill-rule="nonzero"
    />
  </svg>
</template>
