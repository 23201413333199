import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0a4dfa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ppl-tag__ppl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_ctx.peopleRightTag)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["ppl-tag__right", _ctx.customClass])
      }, [
        _createVNode(_component_FaIcon, {
          icon: "user",
          class: "ppl-tag__icon icon"
        }),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.accepted) + "/" + _toDisplayString(_ctx.needed), 1)
      ], 2))
    : _createCommentVNode("", true)
}