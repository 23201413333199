import { CompanyClientOfUserResponse } from "@/services/rest/dashboard";

export type State = {
  selectedClient: number | unknown;
  selectedClientFull: object | unknown;
  isDashboardShown: boolean;
  isReqShiftModalOpen: boolean;
  isMemberDetailsModalOpen: boolean;
  isEmployeeReviewModalOpen: boolean;
  companyClientList: Array<CompanyClientOfUserResponse | unknown>;
};

export const state = {
  selectedClient: null,
  selectedClientFull: {},
  isDashboardShown: false,
  isReqShiftModalOpen: false,
  isMemberDetailsModalOpen: false,
  isEmployeeReviewModalOpen: false,
  companyClientList: []
};
