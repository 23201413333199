
import { computed, defineComponent, PropType, toRefs } from "vue";

import { ShiftResponse } from "@/services/rest/dashboard";
import { useErrorImgHandler } from "@/composables/error-img-hanlder";
import useRandomBg from "@/composables/random-bg-image";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "ShiftDetailsItem",
  props: {
    item: {
      type: Object as PropType<ShiftResponse>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const itemProp = toRefs(props.item);

    const { handleErrorImg, imgErrorClass } = useErrorImgHandler();

    // TODO: to composables
    const formatTimeToHoursMinutes = (time: Date) => {
      const date = new Date(time);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const shiftDate = computed(() => {
      const options = {
        day: "2-digit",
        month: "short",
      } as const;
      const properDate = new Date(itemProp.startTimestamp.value);

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        const customDate = properDate.toLocaleDateString("nl-NL", options).split(" ");

        // remove dot on dutch translations
        customDate[1] = customDate[1].replace(/\.$/, "");
        return customDate;
      }

      return properDate.toLocaleDateString("en-GB", options).split(" ");
    });

    const startEndShift = computed(() => {
      const one = new Date(itemProp.startTimestamp.value);
      const two = new Date(itemProp.endTimestamp.value);

      return `${formatTimeToHoursMinutes(one)} - ${formatTimeToHoursMinutes(two)}`;
    });

    return { shiftDate, startEndShift, handleErrorImg, imgErrorClass, useRandomBg };
  },
});
