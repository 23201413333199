
import { defineComponent, PropType } from "vue";

import DataTableRow from "../DataTableRow/DataTableRow.vue";

interface ColumnInfo {
  header: string;
  id: string;
  value: string;
  width?: string;
  headerAlign?: "left" | "center" | "right";
  rowAlign?: "left" | "center" | "right";
}
export default defineComponent({
  name: "DataTableBody",

  components: {
    DataTableRow,
  },

  props: {
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      default: () => [],
    },

    itemKeyName: {
      type: String,
      default: "",
    },

    itemLogoLink: {
      type: String,
      default: "",
    },

    items: {
      type: Array as PropType<
        {
          [key: string]: string;
        }[]
      >,
      default: () => [],
    },
  },

  emits: ["scroll-reached-bottom", "item-select"],

  watch: {
    items() {
      this.scrollReachedBottomInvoked = false;
    },
  },

  data: () => ({
    scrollReachedBottomInvoked: false,
  }),

  methods: {
    onScroll(event: UIEvent) {
      const gridScrollDiv = event.target as HTMLDivElement;

      const isScrollReachedBottomAndNotInvokedEvent = (
        scrollDiv: HTMLDivElement
      ) =>
        scrollDiv.scrollHeight -
        (scrollDiv.scrollTop + scrollDiv.clientHeight) <
        100 && !this.scrollReachedBottomInvoked;

      const isNeedToResetScrollReachedBottomFlag = (
        scrollDiv: HTMLDivElement
      ) =>
        scrollDiv.scrollHeight -
        (scrollDiv.scrollTop + scrollDiv.clientHeight) >=
        100 && this.scrollReachedBottomInvoked;

      if (isScrollReachedBottomAndNotInvokedEvent(gridScrollDiv)) {
        this.$emit("scroll-reached-bottom");
        this.scrollReachedBottomInvoked = true;
      } else if (isNeedToResetScrollReachedBottomFlag(gridScrollDiv)) {
        this.scrollReachedBottomInvoked = false;
      }
    },
    onItemSelect(item: unknown) {
      this.$emit("item-select", item);
    },
  },
});
