
import { defineComponent, onMounted, ref, watch, toRefs } from "vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";

export default defineComponent({
  name: "TabFilter",
  components: {
    CardWrap,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
    // added prop when active tab needs to be taken from url query param
    activeIndex: {
      type: Number,
    }
  },
  emits: ["click"],
  setup(props, { emit }) {
    /* eslint-disable-next-line */
    const allElements = ref() as any;
     const { activeIndex } = toRefs(props);

    const filterClicked = (index: number | string, isQueryParam = false) => {
      allElements?.value.forEach((el: Element) => {
        el.classList.remove("tab-filter__item--active");
      });
      allElements.value[index].classList.add("tab-filter__item--active");

      emit("click", index, isQueryParam);
    };

    watch(() => activeIndex.value, (val) => {
      filterClicked(val as number, true);
    });

    onMounted(() => {
      allElements.value = document.querySelectorAll(".tab-filter__item");
      allElements.value[0].classList.add("tab-filter__item--active");
    });

    return { filterClicked };
  },
});
