/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegistrationDto,
    RegistrationDtoFromJSON,
    RegistrationDtoFromJSONTyped,
    RegistrationDtoToJSON,
} from './RegistrationDto';

/**
 * 
 * @export
 * @interface GetRegistrationsResponse
 */
export interface GetRegistrationsResponse {
    /**
     * An array, containing all registrations (shifts-only-, time- and freelance-registrations).
     * @type {Array<RegistrationDto>}
     * @memberof GetRegistrationsResponse
     */
    registrations: Array<RegistrationDto>;
}

export function GetRegistrationsResponseFromJSON(json: any): GetRegistrationsResponse {
    return GetRegistrationsResponseFromJSONTyped(json, false);
}

export function GetRegistrationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRegistrationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrations': ((json['registrations'] as Array<any>).map(RegistrationDtoFromJSON)),
    };
}

export function GetRegistrationsResponseToJSON(value?: GetRegistrationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrations': ((value.registrations as Array<any>).map(RegistrationDtoToJSON)),
    };
}

