
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import CardTodo from "@/components/ui/cards/CardTodo.vue";
import SvgCalendar from "@/components/svg/SvgCalendar.vue";
import SvgPerson from "@/components/svg/SvgPerson.vue";
import SvgPenlined from "@/components/svg/SvgPenlined.vue";
import ActionTypes from "@/store/types/action-types";
import { RequiredActionsResponse } from "@/services/rest/dashboard";
import { useRoleCheck } from "@/composables/role-check";

export default defineComponent({
  name: "DashboardTodo",
  components: {
    CardTodo,
    SvgCalendar,
    SvgPerson,
    SvgPenlined,
  },
  setup() {
    const store = useStore();

    const todo = ref<RequiredActionsResponse>();

    const checkPermissions = (role: string) => useRoleCheck(role);

    onMounted(() => {
      store.dispatch(ActionTypes.GET_REQUIRED_ACTIONS).then((res) => {
        todo.value = res;
      });
    });

    return {
      todo,
      checkPermissions,
    };
  },
});
