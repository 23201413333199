/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Organiser of the shift
 * @export
 * @interface ShiftOrganiser
 */
export interface ShiftOrganiser {
    /**
     * Contact ID
     * @type {number}
     * @memberof ShiftOrganiser
     */
    contactId: number;
    /**
     * Contact's first name
     * @type {string}
     * @memberof ShiftOrganiser
     */
    firstName: string;
    /**
     * Contact's last name
     * @type {string}
     * @memberof ShiftOrganiser
     */
    lastName?: string;
    /**
     * Contact's picture url
     * @type {string}
     * @memberof ShiftOrganiser
     */
    profilePictureUrl?: string;
}

export function ShiftOrganiserFromJSON(json: any): ShiftOrganiser {
    return ShiftOrganiserFromJSONTyped(json, false);
}

export function ShiftOrganiserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftOrganiser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': json['contactId'],
        'firstName': json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
    };
}

export function ShiftOrganiserToJSON(value?: ShiftOrganiser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactId': value.contactId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'profilePictureUrl': value.profilePictureUrl,
    };
}

