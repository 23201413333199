import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store";

import Dashboard from "@/views/Dashboard.vue";
import ShiftsOverview from "@/views/ShiftsOverview.vue";
import RegistrationsOverview from "@/views/RegistrationsOverview.vue";
import ShiftDetails from "@/views/ShiftDetails.vue";
import Flexpool from "@/views/FlexPool.vue";
import { Roles } from "@/types/global";
import ReviewsOverview from "@/views/ReviewsOverview.vue";
import EmployeeReviews from "@/views/EmployeeReviews.vue";

import { CompanyClientOfUserResponse } from "@/services/rest/dashboard";

// import ShiftDetailsDescription from "@/components/shift-details/ShiftDetailsDescription.vue";
const ShiftDetailsDescription = () => import("@/components/shift-details/ShiftDetailsDescription.vue");

// TODO: add lazy load components

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          translationKey: "dashboard",
        },
      ],
    },
  },
  {
    path: "/shifts-overview",
    name: "ShiftsOverview",
    component: ShiftsOverview,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Shifts Overview",
          translationKey: "shifts_overview",
        },
      ],
      roles: [Roles.Planner, Roles.Admin, Roles.Floormanager]
    },
  },
  {
    path: "/shift-details/:id",
    name: "ShiftDetails",
    component: ShiftDetails,
    children: [
      {
        path: "",
        name: "ShiftDetailsDescription",
        component: ShiftDetailsDescription,
      }
    ],
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Shifts Overview",
          routeName: "ShiftsOverview",
          link: "/shifts-overview",
          translationKey: "shifts_overview",
        },
        {
          name: "Shift Details",
          translationKey: "details"
        }
      ],
    },
  },
  {
    path: "/registrations-overview",
    name: "RegistrationsOverview",
    component: RegistrationsOverview,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Registrations Overview",
          translationKey: "registrations_overview",
        },
      ],
      roles: [Roles.Bookkeeper, Roles.Admin]
    },
  },
  {
    path: "/flexpool",
    name: "Flexpool",
    component: Flexpool,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Flexpool",
          translationKey: "flexpool",
        },
      ],
    },
  },
  {
    path: "/reviews-overview",
    name: "ReviewsOverview",
    component: ReviewsOverview,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Reviews Overview",
          translationKey: "reviews_overview",
        },
      ],
    },
  },
  {
    path: "/flexpool/employee-profile/reviews/:id",
    name: "EmployeeReviews",
    component: EmployeeReviews,
    meta: {
      requiresLogin: true,
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Flexpool",
          routeName: "Flexpool",
          link: "/flexpool",
          translationKey: "flexpool",
        },
        {
          name: "Employee Profile",
          translationKey: "employee_profile",
        },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/404.vue"),
    meta: {
      breadcrumbs: [
        {
          name: "Dashboard",
          routeName: "Dashboard",
          link: "/",
          translationKey: "dashboard",
        },
        {
          name: "Not Found",
          translationKey: "not_found",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const { roles } = to.meta;

  const clientId = store.state.selectedClient;
  const listOfClients = store.state.companyClientList;
  /* eslint-disable-next-line */
  const rolesUser: any = listOfClients.find((client: CompanyClientOfUserResponse) =>
    client.companyClientId === clientId);

  if (roles && (roles as string[]).length) {

    if (rolesUser && rolesUser.roles.length) {
      let controlVal = false;

      (roles as string[]).forEach((role: string) => {
        if (rolesUser.roles.includes(role)) {
          controlVal = true;
        }
      });

      if (!controlVal) {
        return { name: "NotFound" }
      }

      return true;
    }
  }
  return true;
});

export default router;
