
import { defineComponent, ref, computed } from "vue";

import { useStore } from "vuex";
import MutationTypes from "@/store/types/mutation-types";
import OverlayDialog from "@/components/ui/OverlayDialog.vue";
import RequestShiftForm from "@/components/request-shift-form/RequestShiftForm.vue";


export default defineComponent({
  name: "RequestShiftFormDialog",
  components: { OverlayDialog, RequestShiftForm },
  setup() {
    const store = useStore();

    const reqShiftFormComp = ref<typeof RequestShiftForm | null>(null);
    const isModalOpen = computed(() => store.state.isReqShiftModalOpen);
    const isCloseDisabled = ref(false);

    const closeDialog = () => {
      reqShiftFormComp.value?.resetReqShiftForm();
      store.commit(MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN, false);
    };

    const isUploadingShiftReq = computed(() => reqShiftFormComp.value?.isUploading);

    return {
      isModalOpen,
      closeDialog,
      reqShiftFormComp,
      isCloseDisabled,
      isUploadingShiftReq,
    };
  },
});
