/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';
import {
    RegistrationClass,
    RegistrationClassFromJSON,
    RegistrationClassFromJSONTyped,
    RegistrationClassToJSON,
} from './RegistrationClass';
import {
    ShiftDto,
    ShiftDtoFromJSON,
    ShiftDtoFromJSONTyped,
    ShiftDtoToJSON,
} from './ShiftDto';
import {
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import {
    Unit,
    UnitFromJSON,
    UnitFromJSONTyped,
    UnitToJSON,
} from './Unit';

/**
 * 
 * @export
 * @interface ShiftRegistrationDto
 */
export interface ShiftRegistrationDto {
    /**
     * The id of the registration. Registrations can have duplicate id's, depending on the registrationClass. Each registrationClass has its own id-range.
     * @type {number}
     * @memberof ShiftRegistrationDto
     */
    registrationId: number;
    /**
     * The client tradename
     * @type {string}
     * @memberof ShiftRegistrationDto
     */
    clientName: string;
    /**
     * 
     * @type {RegistrationClass}
     * @memberof ShiftRegistrationDto
     */
    registrationClass: RegistrationClass;
    /**
     * The type of a registration
     * @type {string}
     * @memberof ShiftRegistrationDto
     */
    type: string;
    /**
     * 
     * @type {Status}
     * @memberof ShiftRegistrationDto
     */
    status: Status;
    /**
     * 
     * @type {Employees}
     * @memberof ShiftRegistrationDto
     */
    employeeData: Employees;
    /**
     * 
     * @type {ShiftDto}
     * @memberof ShiftRegistrationDto
     */
    shiftData?: ShiftDto;
    /**
     * 
     * @type {Date}
     * @memberof ShiftRegistrationDto
     */
    startDate: Date;
    /**
     * The start- and end-timestamps of a registration.
     * @type {string}
     * @memberof ShiftRegistrationDto
     */
    dateTimeRange: string;
    /**
     * The amount of a registration (hours, kilometers, days or euro, depending on unit).
     * @type {string}
     * @memberof ShiftRegistrationDto
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftRegistrationDto
     */
    breakDuration: string;
    /**
     * 
     * @type {Unit}
     * @memberof ShiftRegistrationDto
     */
    unit?: Unit;
}

export function ShiftRegistrationDtoFromJSON(json: any): ShiftRegistrationDto {
    return ShiftRegistrationDtoFromJSONTyped(json, false);
}

export function ShiftRegistrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftRegistrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationId': json['registrationId'],
        'clientName': json['clientName'],
        'registrationClass': RegistrationClassFromJSON(json['registrationClass']),
        'type': json['type'],
        'status': StatusFromJSON(json['status']),
        'employeeData': EmployeesFromJSON(json['employeeData']),
        'shiftData': !exists(json, 'shiftData') ? undefined : ShiftDtoFromJSON(json['shiftData']),
        'startDate': (new Date(json['startDate'])),
        'dateTimeRange': json['dateTimeRange'],
        'quantity': json['quantity'],
        'breakDuration': json['breakDuration'],
        'unit': !exists(json, 'unit') ? undefined : UnitFromJSON(json['unit']),
    };
}

export function ShiftRegistrationDtoToJSON(value?: ShiftRegistrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationId': value.registrationId,
        'clientName': value.clientName,
        'registrationClass': RegistrationClassToJSON(value.registrationClass),
        'type': value.type,
        'status': StatusToJSON(value.status),
        'employeeData': EmployeesToJSON(value.employeeData),
        'shiftData': ShiftDtoToJSON(value.shiftData),
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'dateTimeRange': value.dateTimeRange,
        'quantity': value.quantity,
        'breakDuration': value.breakDuration,
        'unit': UnitToJSON(value.unit),
    };
}

