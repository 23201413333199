/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationPage,
    PaginationPageFromJSON,
    PaginationPageFromJSONTyped,
    PaginationPageToJSON,
} from './PaginationPage';
import {
    ShiftResponse,
    ShiftResponseFromJSON,
    ShiftResponseFromJSONTyped,
    ShiftResponseToJSON,
} from './ShiftResponse';

/**
 * 
 * @export
 * @interface CompanyClientShiftsResponse
 */
export interface CompanyClientShiftsResponse {
    /**
     * 
     * @type {Array<ShiftResponse>}
     * @memberof CompanyClientShiftsResponse
     */
    resultList: Array<ShiftResponse>;
    /**
     * 
     * @type {PaginationPage}
     * @memberof CompanyClientShiftsResponse
     */
    page: PaginationPage;
}

export function CompanyClientShiftsResponseFromJSON(json: any): CompanyClientShiftsResponse {
    return CompanyClientShiftsResponseFromJSONTyped(json, false);
}

export function CompanyClientShiftsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientShiftsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultList': ((json['resultList'] as Array<any>).map(ShiftResponseFromJSON)),
        'page': PaginationPageFromJSON(json['page']),
    };
}

export function CompanyClientShiftsResponseToJSON(value?: CompanyClientShiftsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultList': ((value.resultList as Array<any>).map(ShiftResponseToJSON)),
        'page': PaginationPageToJSON(value.page),
    };
}

