
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ActionTypes from "@/store/types/action-types";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import SvgClock3d from "@/components/svg/SvgClock3d.vue";
import SvgPenlined from "@/components/svg/SvgPenlined.vue";
import DashboardRegistrationsRow from "@/components/dashboard/DashboardRegistrationsRow.vue";
import DashboardRegistrationsRowSkeleton from "@/components/dashboard/DashboardRegistrationsRowSkeleton.vue";
import {
  GetRegistrationsByCompanyClientIdRequest,
  RegistrationDto,
} from "@/services/rest/dashboard";

export default defineComponent({
  name: "DashboardRegistrations",
  components: {
    SvgClock3d,
    CardWrap,
    SvgPenlined,
    DashboardRegistrationsRow,
    DashboardRegistrationsRowSkeleton,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const newestRegistrations = ref<RegistrationDto[]>();
    const registrationSize = ref(3);

    const params: GetRegistrationsByCompanyClientIdRequest = {
      size: registrationSize.value,
      page: 0,
    };

    onMounted(() => {
      isLoading.value = true;

      store
        .dispatch(ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID, params)
        .then((res) => {
          newestRegistrations.value = res.registrations;
        })
        .catch((err) => {
          console.log("Error:", err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return { newestRegistrations, registrationSize, isLoading };
  },
});
