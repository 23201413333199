
import { computed, defineComponent, onMounted, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ToastService from "mq-ui-kit/lib/toast";
import { Icon } from "mq-ui-kit/lib/Icon";
import ActionTypes from "@/store/types/action-types";
import MutationTypes from "@/store/types/mutation-types";
import {
  Employee,
  EmployeeOnShiftFilterStatusEnum,
  GetEmployeeReviewOverviewsRequest,
  Employees,
  CompanyClientEmployeeReviewResponse
} from "@/services/rest/dashboard";
import TabFilter from "@/components/ui/TabFilter.vue";
import DataTable from "@/components/ui/DataTable/DataTable.vue";
import NoRecord from "@/components/ui/DataTable/NoRecord/NoRecord.vue";
import ListItemDate from "@/components/ui/ListItemDate.vue";
import Avatar from "@/components/ui/Avatar.vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import Spinner from "@/components/ui/Spinner.vue";
import Dropdown from "@/components/ui/Dropdown/Dropdown.vue";
import DropdownItem from "@/components/ui/Dropdown/DropdownItem.vue";
import EmployeeReviewModal from "@/components/shift-details/EmployeeReviewModal.vue";

enum CustomEmployeeOnShiftFilterStatusEnum {
  All= "ALL"
};

interface TabItemReviewLine {
  name: string;
  type: EmployeeOnShiftFilterStatusEnum | CustomEmployeeOnShiftFilterStatusEnum;
  numOfItems: number;
};

export default defineComponent({
  name: "ReviewsOverview",
  components: {
    TabFilter,
    DataTable,
    ListItemDate,
    Avatar,
    CardWrap,
    Spinner,
    NoRecord,
    Icon,
    Dropdown,
    DropdownItem,
    EmployeeReviewModal
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const dashboardShown = computed(() => store.state.isDashboardShown);
    const reviewsList = ref<Employee[]>([]);
    const isTabSwitched = ref(false);

    const selectedTabType = ref<EmployeeOnShiftFilterStatusEnum>(
      EmployeeOnShiftFilterStatusEnum.ForReview
    );

    const singleMemberShift = ref<CompanyClientEmployeeReviewResponse>({
      reviewScore: 0.0,
      gigName: "",
      labels: [],
      shiftId: 1,
      shiftStartTimestamp: new Date(),
      employee: {
        employeeId: 0,
        fullName: "",
        firstName: "",
        lastName: "",
        title: "",
        about: "",
        profilePictureUrl: "",
        skills: [],
        favourite: true,
        city: "",
        numberOfCompaniesShifts: 0,
        numberOfCompaniesClientsShifts: 0
      }
    });

    const pageIndex = ref(0);
    const perPage = ref(15);
    const totalPages = ref(0);
    const totalElements = ref(0);

    const memo = ref<string[]>([]);

    const componentKey = ref(0);

    const isLoading = ref(false);

    const isModalOpen = ref(false);

    const reviewsColumns = ref([
      {
        header: "",
        id: "logoLink",
        value: "logoLink",
        width: "60px",
      },
      {
        header: t("global.name"),
        id: "name",
        value: "full_name",
        sortable: true,
      },
      {
        header: t("global.shift"),
        id: "shift",
        value: "shift_name",
        sortable: true,
      },
      {
        header: t("global.date"),
        id: "date",
        value: "start_timestamp",
        width: "13%",
        sortable: true,
      },
    ]);

    const addActionMenu = () => {
      const menu =  {
        header: "",
        id: "menu",
        value: "menu",
        width: "100px",
        rowAlign: "right"
      };

      reviewsColumns.value.push(menu);
    }

    const tabItems = ref<TabItemReviewLine[]>([
      {
        name: t("global.to_do"),
        type: EmployeeOnShiftFilterStatusEnum.ForReview,
        numOfItems: 0,
      },
      {
        name: t("global.handled"),
        type: EmployeeOnShiftFilterStatusEnum.Reviewed,
        numOfItems: 0,
      },
      {
        name: t("global.all"),
        type: CustomEmployeeOnShiftFilterStatusEnum.All,
        numOfItems: 0,
      },
    ]);

    const hideSelector = computed(() =>
      selectedTabType.value === tabItems.value[2].type
    );

    const getEmployeeReviewOverviews = (
      type: EmployeeOnShiftFilterStatusEnum | string,
      sortParam?: string[]
    ) => {
      isLoading.value = true;

      const params = {
        ...(type !== CustomEmployeeOnShiftFilterStatusEnum.All && {
          employeeOnShiftFilter: {
            status: type
          },
        }),
        size: perPage.value,
        page: pageIndex.value,
        ...(sortParam?.length && { sort: sortParam })
      } as GetEmployeeReviewOverviewsRequest;

      store
        .dispatch(ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEWS, params)
        .then((res) => {
          totalPages.value = res.page.totalPages;
          totalElements.value = res.page.totalElements;
          perPage.value = res.page.size;

          reviewsList.value.push(...res.resultList);
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const formatSortData = (columnName: string, sort: string) => `${columnName},${sort}`;

    /* eslint-disable-next-line */
    const onHeaderItemClick = (item: any) => {
      if (!memo.value.length && item.sortOrder) {
        memo.value.push(formatSortData(item.item.value, item.sortOrder));
      } else {
        memo.value = memo.value.filter((el) => el.split(",")[0] !== item.item.value);

        if (item.sortOrder)
          memo.value.push(formatSortData(item.item.value, item.sortOrder));
      }

      pageIndex.value = 0;
      reviewsList.value = [];
      getEmployeeReviewOverviews(selectedTabType.value, memo.value);
    };

    const forceRerender = () => {
      componentKey.value += 1;
    };

    const tabClicked = (index: number) => {
      pageIndex.value = 0;
      isTabSwitched.value = true;

      reviewsList.value = [];

      selectedTabType.value = tabItems.value[index].type as EmployeeOnShiftFilterStatusEnum;

      forceRerender();

      memo.value = [];

      getEmployeeReviewOverviews(tabItems.value[index].type as EmployeeOnShiftFilterStatusEnum, memo.value);
    };

    const openEmployeeReviewModal = () => {
      store.commit(MutationTypes.SET_IS_EMPLOYEE_REVIEW_MODAL_OPEN, true);
    };

    const onScrollReachedBottom = () => {
      if (pageIndex.value >= totalPages.value - 1) return;

      pageIndex.value += 1;
      getEmployeeReviewOverviews(selectedTabType.value, memo.value);
    };

    const openReview = async (member: Employees, shiftId: number) => {
      try {
        const params = {
          employeeId: member.employeeId,
          shiftId
        };

        singleMemberShift.value = await store.dispatch(ActionTypes.GET_EMPLOYEE_REVIEW_OR_PREPARE_TEMPLATE, params);

        nextTick(()=> {
          openEmployeeReviewModal();
        });
      } catch (err) {
        console.log("Error", err);
        ToastService.errorToast(t("errors.something_went_wrong"));
      };
    };

    const updateList = () => {
      reviewsList.value = [];
      getEmployeeReviewOverviews(selectedTabType.value, memo.value);
    };

    onMounted(() => {
      addActionMenu();
      getEmployeeReviewOverviews(selectedTabType.value, memo.value);
    });

    return {
      dashboardShown,
      tabItems,
      reviewsColumns,
      isLoading,
      totalElements,
      isModalOpen,
      onScrollReachedBottom,
      tabClicked,
      reviewsList,
      selectedTabType,
      onHeaderItemClick,
      openEmployeeReviewModal,
      openReview,
      hideSelector,
      singleMemberShift,
      updateList,
      componentKey,
    };
  },
});
