import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBoxSmall = _resolveComponent("CheckBoxSmall")!

  return (_openBlock(), _createBlock(_component_CheckBoxSmall, {
    id: _ctx.id,
    checked: _ctx.isChecked,
    value: _ctx.isChecked,
    label: "",
    onChange: _ctx.onChange
  }, null, 8, ["id", "checked", "value", "onChange"]))
}