
import { computed, defineComponent, PropType } from "vue";
import { StatusEnum } from "@/services/rest/dashboard";

export default defineComponent({
  name: "Tag",
  props: {
    color: {
      type: String as PropType<StatusEnum>,
      required: true,
    },
  },
  setup(props) {
    const customClazz = computed(() => ({
        pending:
          props.color === StatusEnum.ForApproval ||
          props.color === StatusEnum.CompanyRegistered,
        approved: props.color === StatusEnum.Approved,
        declined: props.color === StatusEnum.Rejected,
      }));

    return { customClazz };
  },
});
