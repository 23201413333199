
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Dropdown",
  setup() {
    const isDropdownOpen = ref(false);

    const openOptions = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    }

    const closeDropDown = () => {
      isDropdownOpen.value = false;
    }

    return { isDropdownOpen, openOptions, closeDropDown };
  }
});
