/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShiftResponse,
    ShiftResponseFromJSON,
    ShiftResponseFromJSONTyped,
    ShiftResponseToJSON,
} from './ShiftResponse';

/**
 * 
 * @export
 * @interface ShiftMostRecentResponse
 */
export interface ShiftMostRecentResponse {
    /**
     * 
     * @type {ShiftResponse}
     * @memberof ShiftMostRecentResponse
     */
    shift?: ShiftResponse;
    /**
     * 
     * @type {string}
     * @memberof ShiftMostRecentResponse
     */
    errorId?: ShiftMostRecentResponseErrorIdEnum;
}


/**
 * @export
 */
export const ShiftMostRecentResponseErrorIdEnum = {
    ShiftNotFound: 'SHIFT_NOT_FOUND'
} as const;
export type ShiftMostRecentResponseErrorIdEnum = typeof ShiftMostRecentResponseErrorIdEnum[keyof typeof ShiftMostRecentResponseErrorIdEnum];


export function ShiftMostRecentResponseFromJSON(json: any): ShiftMostRecentResponse {
    return ShiftMostRecentResponseFromJSONTyped(json, false);
}

export function ShiftMostRecentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftMostRecentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shift': !exists(json, 'shift') ? undefined : ShiftResponseFromJSON(json['shift']),
        'errorId': !exists(json, 'errorId') ? undefined : json['errorId'],
    };
}

export function ShiftMostRecentResponseToJSON(value?: ShiftMostRecentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shift': ShiftResponseToJSON(value.shift),
        'errorId': value.errorId,
    };
}

