import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2939a08"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flexpool"
}
const _hoisted_2 = { class: "flexpool__header" }
const _hoisted_3 = { class: "flexpool__title" }
const _hoisted_4 = { class: "flexpool__actions" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "card-wrap-empty-state__text" }
const _hoisted_7 = { class: "card-wrap__container" }
const _hoisted_8 = { class: "card-wrap__content" }
const _hoisted_9 = { class: "card-wrap__name" }
const _hoisted_10 = { class: "card-wrap__title" }
const _hoisted_11 = { class: "card-wrap__location" }
const _hoisted_12 = { class: "card-wrap__shifts" }
const _hoisted_13 = { class: "card-wrap__shifts-total" }
const _hoisted_14 = { class: "card-wrap__shifts-count" }
const _hoisted_15 = { class: "card-wrap__shifts-text" }
const _hoisted_16 = { class: "card-wrap__shifts-company" }
const _hoisted_17 = { class: "card-wrap__shifts-count" }
const _hoisted_18 = { class: "card-wrap__shifts-text" }
const _hoisted_19 = { class: "card-wrap__skills-container" }
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_FlexPoolFilter = _resolveComponent("FlexPoolFilter")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CardWrap = _resolveComponent("CardWrap")!
  const _component_FlexPoolSkeleton = _resolveComponent("FlexPoolSkeleton")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_ShiftDetailsMemberModal = _resolveComponent("ShiftDetailsMemberModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dashboardShown && _ctx.$store.state.selectedClient)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_Teleport, { to: "#flex-pool-search" }, [
            _createVNode(_component_Search, {
              modelValue: _ctx.searchValue,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
                _ctx.debouncedSearch
              ],
              placeholder: _ctx.$t('menu.search'),
              "icon-right": ""
            }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"])
          ])),
          _createVNode(_component_FlexPoolFilter, {
            "aside-id": _ctx.asideId,
            "is-open": _ctx.isFilterOpen,
            onClose: _ctx.closeFilter,
            onClear: _ctx.clearFilterData,
            onShowResults: _ctx.applyFilterData
          }, null, 8, ["aside-id", "is-open", "onClose", "onClear", "onShowResults"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("routes.flexpool")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openFilter && _ctx.openFilter(...args))),
                class: "flexpool__btn-filter"
              }, [
                _createVNode(_component_Icon, { icon: "bars-filter" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("global.filter")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ 'flexpool__card-holder': _ctx.clientCompanyEmployees.length || _ctx.isLoading })
          }, [
            (!_ctx.clientCompanyEmployees.length && !_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_CardWrap, {
                  key: 0,
                  class: "card-wrap-empty-state--width"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("figure", null, [
                      _createElementVNode("img", {
                        src: require('@/assets/svg-icons/ghost-duotone.svg'),
                        class: "card-wrap-empty-state__image",
                        alt: _ctx.$t('global.no_record_found')
                      }, null, 8, _hoisted_5),
                      _createElementVNode("figcaption", null, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("flexpool.empty_state")), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.perPage, (item, index) => {
                  return (_openBlock(), _createBlock(_component_FlexPoolSkeleton, { key: index }))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.clientCompanyEmployees, (employee) => {
                  return (_openBlock(), _createBlock(_component_CardWrap, {
                    key: employee.employeeId,
                    class: "card-wrap--width"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_Avatar, {
                            name: employee.firstName,
                            "last-name": employee.lastName,
                            "img-path": employee.profilePictureUrl,
                            "custom-size": 107,
                            class: "card-wrap__image",
                            size: "medium",
                            "is-liked": employee.favourite,
                            "is-likeable": "",
                            onMemberLiked: ($event: any) => (_ctx.favouriteEmployee(employee.employeeId!))
                          }, null, 8, ["name", "last-name", "img-path", "is-liked", "onMemberLiked"]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", _hoisted_9, _toDisplayString(employee.fullName), 1),
                            _createElementVNode("p", _hoisted_10, _toDisplayString(employee.title), 1),
                            _createElementVNode("p", _hoisted_11, [
                              _createVNode(_component_FaIcon, {
                                class: "card-wrap__icon",
                                icon: "location-dot"
                              }),
                              _createTextVNode(" " + _toDisplayString(employee.city), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(employee.numberOfCompaniesShifts), 1),
                            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("flexpool.shifts", employee.numberOfCompaniesShifts as number)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("p", _hoisted_17, _toDisplayString(employee.numberOfCompaniesClientsShifts), 1),
                            _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("flexpool.company_shifts")), 1)
                          ])
                        ]),
                        _createElementVNode("ul", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(employee.skills, (skill) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: skill,
                              class: "card-wrap__skills"
                            }, _toDisplayString(skill), 1))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.employeeReviewsProfileClick(employee)),
                        class: "card-wrap__link"
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.$t("global.see_more")) + " ", 1),
                        _createVNode(_component_Icon, {
                          icon: "chevron-right",
                          class: "card-wrap__arrow"
                        })
                      ], 8, _hoisted_20)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
          ], 2),
          _createVNode(_component_el_pagination, {
            layout: "prev, pager, next",
            total: _ctx.totalElements,
            "page-size": _ctx.perPage,
            "hide-on-single-page": true,
            "pager-count": 5,
            "current-page": _ctx.currentPage,
            onCurrentChange: _ctx.onPageChange,
            "prev-text": `${_ctx.$t('global.prev')}`,
            "next-text": `${_ctx.$t('global.next')}`,
            class: "pagination-float"
          }, null, 8, ["total", "page-size", "current-page", "onCurrentChange", "prev-text", "next-text"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ShiftDetailsMemberModal, {
      member: _ctx.singleMember,
      onClose: _ctx.dialogClosed
    }, null, 8, ["member", "onClose"])
  ], 64))
}