
import { defineComponent, onMounted, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ShiftDetailsSidebar from "@/components/shift-details/ShiftDetailsSidebar.vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";

import ActionTypes from "@/store/types/action-types";
import {
  CompanyClientShiftsRequest,
  ShiftFilterShiftTypeEnum,
  ShiftResponse,
  ShiftFilter,
  CompanyClientShiftsResponse,
} from "@/services/rest/dashboard";

export default defineComponent({
  name: "ShiftDetails",
  components: { ShiftDetailsSidebar, CardWrap },
  setup() {
    const store = useStore();
    const route = useRoute();

    // TODO: error na propsu, ovdje ne smije biti empty value
    const itemData = ref<ShiftResponse | unknown>({});

    const isLoading = ref(false);

    // pagination settings
    const currentPage = ref(1);
    const perPage = ref(16);
    const perPageSkeleton = ref(6);

    const shiftIdHolder = ref(Number(route.params.id));

    const customUpcomingShifts = ref<ShiftResponse[]>([]);

    const filterUniqueMembers = (list: ShiftResponse[]) => list.filter(
        (el, index, arr) => arr.findIndex((el2) => el.shiftId === el2.shiftId) === index
      );

    const getCompanyClientsShiftsByType = (type: ShiftFilterShiftTypeEnum): Promise<ShiftResponse[]> => {
      const shiftFilter: ShiftFilter = {
        shiftId: shiftIdHolder.value,
        shiftType: type,
      };

      const params: CompanyClientShiftsRequest = {
        shiftFilter,
        size: perPage.value,
        page: currentPage.value - 1,
      };

      return new Promise((resolve) => {
        store
          .dispatch(ActionTypes.GET_COMPANY_CLIENTS_SHIFTS, params)
          .then((res: CompanyClientShiftsResponse) => {
            resolve([...res.resultList]);
          })
          .catch((err) => {
            console.log("Error:", err);
          });
      });
    };

    const handleScrollPosition = (position: boolean) => {
      // TRUE - on top
      // FALSE - on bottom

      const enumSwitch = position
        ? ShiftFilterShiftTypeEnum.Past
        : ShiftFilterShiftTypeEnum.Future;

      shiftIdHolder.value = position
        ? customUpcomingShifts.value[0].shiftId
        : customUpcomingShifts.value[customUpcomingShifts.value.length - 1].shiftId;

      getCompanyClientsShiftsByType(enumSwitch).then((res: ShiftResponse[]) => {
        // TODO: check if BE is sending 0 shifts now, this is to
        // block adding to array if there is no new shifts in PAST or FUTURE
        if (res.length === 1 && res[0].shiftId === shiftIdHolder.value) {
          return;
        }

        if (position) {
          const pastHolder = res
            .filter((el: ShiftResponse) => el.shiftId !== shiftIdHolder.value)
            .reverse();

          customUpcomingShifts.value = filterUniqueMembers([
            ...pastHolder,
            ...customUpcomingShifts.value,
          ]);

          nextTick(() => {
            document
              .querySelector(`#shift-details-sidebar__${shiftIdHolder.value}`)
              ?.scrollIntoView();
          });
        } else {
          const upcomingHolder = res.filter(
            (el: ShiftResponse) => el.shiftId !== shiftIdHolder.value
          );

          customUpcomingShifts.value = filterUniqueMembers([
            ...customUpcomingShifts.value,
            ...upcomingHolder,
          ]);
        }
      });
    };

    onMounted(() => {
      isLoading.value = true;

      const requests = [
        getCompanyClientsShiftsByType(ShiftFilterShiftTypeEnum.Past),
        getCompanyClientsShiftsByType(ShiftFilterShiftTypeEnum.Present),
        getCompanyClientsShiftsByType(ShiftFilterShiftTypeEnum.Future),
      ];

      Promise.all(requests).then((result: ShiftResponse[][]) => {
        const selectedShift = shiftIdHolder.value;

        const [pastShifts, presentShifts, upcomingShifts] = result;

        const reversedPastShifts = pastShifts
          .filter((el: ShiftResponse) => el.shiftId !== selectedShift)
          .reverse();

        const reversedPresentShifts = presentShifts.reverse();

        const listHolder = [...reversedPastShifts, ...reversedPresentShifts, ...upcomingShifts];

        customUpcomingShifts.value = filterUniqueMembers(listHolder);

        itemData.value = listHolder.find(
          (el: ShiftResponse) => el.shiftId === selectedShift
        );

        nextTick(() => {
          document
            .querySelector(`#shift-details-sidebar__${selectedShift}`)
            ?.scrollIntoView();
        });

        isLoading.value = false;
      });
    });

    return {
      itemData,
      customUpcomingShifts,
      handleScrollPosition,
      isLoading,
      perPageSkeleton
    };
  },
});
