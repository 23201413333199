import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f9e461"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "shifts-overview"
}
const _hoisted_2 = { class: "shifts-overview__header" }
const _hoisted_3 = { class: "shifts-overview__title" }
const _hoisted_4 = {
  key: 0,
  class: "list"
}
const _hoisted_5 = {
  key: 1,
  class: "list"
}
const _hoisted_6 = {
  key: 2,
  class: "shifts-overview__no-shifts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_TabFilter = _resolveComponent("TabFilter")!
  const _component_CardUpcomingShiftInline = _resolveComponent("CardUpcomingShiftInline")!
  const _component_NoRecord = _resolveComponent("NoRecord")!
  const _component_CardUpcomingShiftInlineSkeleton = _resolveComponent("CardUpcomingShiftInlineSkeleton")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_ctx.dashboardShown && _ctx.$store.state.selectedClient)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("routes.shifts_overview")), 1),
          _createVNode(_component_Button, {
            size: "medium",
            class: "shifts-overview__btn",
            onClick: _ctx.openRequestShiftDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode(" + " + _toDisplayString(_ctx.$t("global.add_new_request")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createVNode(_component_TabFilter, {
          items: _ctx.tabItems,
          onClick: _ctx.tabClicked,
          "active-index": _ctx.activeTabIndex
        }, null, 8, ["items", "onClick", "active-index"]),
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customUpcomingShifts, (item, index) => {
                return (_openBlock(), _createBlock(_component_CardUpcomingShiftInline, {
                  key: index,
                  item: item,
                  onClick: ($event: any) => (_ctx.shiftClick(item))
                }, null, 8, ["item", "onClick"]))
              }), 128)),
              (!_ctx.customUpcomingShifts.length && !_ctx.errorToggle)
                ? (_openBlock(), _createBlock(_component_NoRecord, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.perPageSkeleton, (item, index) => {
                return (_openBlock(), _createBlock(_component_CardUpcomingShiftInlineSkeleton, { key: index }))
              }), 128))
            ])),
        (_ctx.errorToggle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("errors.something_went_wrong")), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_el_pagination, {
          layout: "prev, pager, next",
          total: _ctx.shiftCount,
          "page-size": _ctx.perPage,
          "current-page": _ctx.currentPage,
          "hide-on-single-page": true,
          "pager-count": 5,
          onCurrentChange: _ctx.onPageChange,
          "prev-text": `${_ctx.$t('global.prev')}`,
          "next-text": `${_ctx.$t('global.next')}`
        }, null, 8, ["total", "page-size", "current-page", "onCurrentChange", "prev-text", "next-text"])
      ]))
    : _createCommentVNode("", true)
}