
import { computed, defineComponent } from "vue";
import PeopleCountTag from "@/components/ui/PeopleCountTag.vue";

export default defineComponent({
  name: "ProgressBarEmployee",
  components: {
    // Icon,
    PeopleCountTag,
  },
  props: {
    needed: {
      type: Number,
      required: true,
    },
    accepted: {
      type: Number,
      required: true,
    },
    peopleRightTag: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const percOfUsers = computed(() => {
      if (props.accepted > props.needed) return { width: "100%" };

      const perc = (props.accepted / props.needed) * 100;
      return { width: `${perc}%` };
    });

    return {
      percOfUsers,
    };
  },
});
