/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationsForCompanyClientRequestFilters
 */
export interface RegistrationsForCompanyClientRequestFilters {
    /**
     * Wage Component Types
     * @type {Array<string>}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    wageCompTypes?: Array<RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum>;
    /**
     * Incremental filter that includes the name of a shift. This filter is case-insensitive and will return a match if part of a shiftName matches the provided value.
     * @type {string}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    shiftName?: string;
    /**
     * Incremental filter that includes the name of a project. This filter is case-insensitive and will return a match if part of a projectName matches the provided value.
     * @type {string}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    projectName?: string;
    /**
     * Incremental filter that includes the status of the registration.
     * @type {Array<string>}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    status?: Array<RegistrationsForCompanyClientRequestFiltersStatusEnum>;
    /**
     * Incremental filter that includes the startTimestamp of a registration as a range from start to end, both inclusive. Timstamps should have ISO date-time format and be properly encoded.
     * @type {string}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    start?: string;
    /**
     * Incremental filter that includes the endTimestamp of a registration as a range from start to end, both inclusive. Timstamps should have ISO date-time format and be properly encoded.
     * @type {string}
     * @memberof RegistrationsForCompanyClientRequestFilters
     */
    end?: string;
}


/**
 * @export
 */
export const RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum = {
    RegularHours: 'REGULAR_HOURS',
    SurchargeHours: 'SURCHARGE_HOURS',
    OvertimeHours: 'OVERTIME_HOURS',
    GrossCompensation: 'GROSS_COMPENSATION',
    NetCompensation: 'NET_COMPENSATION',
    TransitionAllowance: 'TRANSITION_ALLOWANCE',
    LeaveAbsence: 'LEAVE_ABSENCE'
} as const;
export type RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum = typeof RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum[keyof typeof RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum];

/**
 * @export
 */
export const RegistrationsForCompanyClientRequestFiltersStatusEnum = {
    ForApproval: 'FOR_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    CompanyRegistered: 'COMPANY_REGISTERED'
} as const;
export type RegistrationsForCompanyClientRequestFiltersStatusEnum = typeof RegistrationsForCompanyClientRequestFiltersStatusEnum[keyof typeof RegistrationsForCompanyClientRequestFiltersStatusEnum];


export function RegistrationsForCompanyClientRequestFiltersFromJSON(json: any): RegistrationsForCompanyClientRequestFilters {
    return RegistrationsForCompanyClientRequestFiltersFromJSONTyped(json, false);
}

export function RegistrationsForCompanyClientRequestFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationsForCompanyClientRequestFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wageCompTypes': !exists(json, 'wageCompTypes') ? undefined : json['wageCompTypes'],
        'shiftName': !exists(json, 'shiftName') ? undefined : json['shiftName'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
    };
}

export function RegistrationsForCompanyClientRequestFiltersToJSON(value?: RegistrationsForCompanyClientRequestFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wageCompTypes': value.wageCompTypes,
        'shiftName': value.shiftName,
        'projectName': value.projectName,
        'status': value.status,
        'start': value.start,
        'end': value.end,
    };
}

