import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d2a4a4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tab-filter__list",
  ref: "tab-ref"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "pill"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardWrap = _resolveComponent("CardWrap")!

  return (_openBlock(), _createBlock(_component_CardWrap, { class: "tab-filter" }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "tab-filter__item",
            onClick: ($event: any) => (_ctx.filterClicked(index))
          }, [
            _createElementVNode("button", {
              class: "tab-filter__btn",
              title: item.name
            }, _toDisplayString(item.name), 9, _hoisted_3),
            (item.numOfItems)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.numOfItems), 1))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2))
        }), 128))
      ], 512)
    ]),
    _: 1
  }))
}