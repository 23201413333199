import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b90d993a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "process-lines-bar" }
const _hoisted_2 = { class: "amount-of-lines" }
const _hoisted_3 = { class: "item-count" }
const _hoisted_4 = { class: "item-count-text" }
const _hoisted_5 = { class: "process-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.itemCount), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.itemCountText), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "approve",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.approveLines && _ctx.approveLines(...args)))
      }, [
        _createVNode(_component_Icon, { icon: "check" }),
        _createTextVNode(" " + _toDisplayString(_ctx.t('global.approve')), 1)
      ])
    ])
  ]))
}