/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkRegistrationLinesRequest
 */
export interface BulkRegistrationLinesRequest {
    /**
     * List of time registration ID's
     * @type {Array<number>}
     * @memberof BulkRegistrationLinesRequest
     */
    timeRegistrationLines: Array<number>;
    /**
     * List of freelance registration ID's
     * @type {Array<number>}
     * @memberof BulkRegistrationLinesRequest
     */
    freelanceRegistrationLines: Array<number>;
    /**
     * List of time registration ID's. Interchangeable with 'TimeRegistrations'
     * @type {Array<number>}
     * @memberof BulkRegistrationLinesRequest
     */
    shiftOnlyRegistrationLines: Array<number>;
}

export function BulkRegistrationLinesRequestFromJSON(json: any): BulkRegistrationLinesRequest {
    return BulkRegistrationLinesRequestFromJSONTyped(json, false);
}

export function BulkRegistrationLinesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkRegistrationLinesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeRegistrationLines': json['timeRegistrationLines'],
        'freelanceRegistrationLines': json['freelanceRegistrationLines'],
        'shiftOnlyRegistrationLines': json['shiftOnlyRegistrationLines'],
    };
}

export function BulkRegistrationLinesRequestToJSON(value?: BulkRegistrationLinesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeRegistrationLines': value.timeRegistrationLines,
        'freelanceRegistrationLines': value.freelanceRegistrationLines,
        'shiftOnlyRegistrationLines': value.shiftOnlyRegistrationLines,
    };
}

