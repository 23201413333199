/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeOnShiftFilter
 */
export interface EmployeeOnShiftFilter {
    /**
     * Filter that includes the status of the employee review.
     * @type {string}
     * @memberof EmployeeOnShiftFilter
     */
    status: EmployeeOnShiftFilterStatusEnum;
}


/**
 * @export
 */
export const EmployeeOnShiftFilterStatusEnum = {
    ForReview: 'FOR_REVIEW',
    Reviewed: 'REVIEWED'
} as const;
export type EmployeeOnShiftFilterStatusEnum = typeof EmployeeOnShiftFilterStatusEnum[keyof typeof EmployeeOnShiftFilterStatusEnum];


export function EmployeeOnShiftFilterFromJSON(json: any): EmployeeOnShiftFilter {
    return EmployeeOnShiftFilterFromJSONTyped(json, false);
}

export function EmployeeOnShiftFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeOnShiftFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function EmployeeOnShiftFilterToJSON(value?: EmployeeOnShiftFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

