
import { defineComponent } from "vue";
//
import { Button } from "mq-ui-kit/lib/Button";
import { FormGroup } from "mq-ui-kit/lib/FormGroup";
import { FormRow } from "mq-ui-kit/lib/FormRow";
import { FormLabel } from "mq-ui-kit/lib/FormLabel";
import { TextInput } from "mq-ui-kit/lib/TextInput";
import { RichTextEditor } from "mq-ui-kit/lib/RichTextEditor";
import { DateTimePicker } from "mq-ui-kit/lib/DateTimePicker";
//
import { FormGroupModel } from "mq-ui-kit/lib/form-group-model";
import { FormControlModel } from "mq-ui-kit/lib/form-control-model";
import { Validators } from "mq-ui-kit/lib/form-validate";
import ToastService from "mq-ui-kit/lib/toast";
import Spinner from "@/components/ui/Spinner.vue";
import ActionTypes from "@/store/types/action-types";
import DragNDrop from "@/components/ui/DragDropFileUpload.vue";
import { ShiftMostRecentResponse } from "@/services/rest/dashboard";

export default defineComponent({
  name: "RequestShiftForm",
  components: {
    Button,
    FormGroup,
    FormRow,
    FormLabel,
    TextInput,
    RichTextEditor,
    DateTimePicker,
    DragNDrop,
    Spinner,
  },
  emits: ["closeDialog"],
  data() {
    return {
      isLoading: false,
      currentStep: 1,
      fileNumbLimit: 10,
      // file  size in MB
      fileSize: 15,
      defaultTimeDateForStart: this.getTimeDate(9, 0),
      defaultTimeDateForEnd: this.getTimeDate(17, 30),
      dateTimeTest: new FormControlModel({
        validations: [Validators.required()],
        value: "",
      }),
      formGroup: new FormGroupModel({
        title: new FormControlModel({
          validations: [Validators.required(), Validators.maxCharacter(255)],
          value: "",
          disabled: false,
        }),
        description: new FormControlModel({
          validations: [Validators.required(), Validators.maxCharacter(800)],
          value: "",
          disabled: false,
        }),
        startDate: new FormControlModel({
          validations: [Validators.required()],
          value: "",
        }),
        endDate: new FormControlModel({
          validations: [Validators.required()],
          value: "",
        }),
      }),
      fileList: [] as Array<Blob>,
      isUploading: false,
    };
  },
  computed: {
    disableEndDate() {
      let isDisabled = false;
      if (!this.formGroup.controls.startDate.value) {
        isDisabled = true;
      }
      return isDisabled;
    },
    endDateMinValue(): Date {
      return (this.formGroup.controls.startDate?.value || new Date()) as Date;
    },
    isModalOpen() {
      return this.$store.state.isReqShiftModalOpen as boolean;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    getCurrentDate() {
      const minDate = new Date();
      minDate.setMinutes(minDate.getMinutes() + 5);
      return minDate;
    },

    getTimeDate(hours: number, minutes: number) {
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setMilliseconds(0);
      return date;
    },

    resetForm() {
      this.formGroup.reset();
    },

    resetStep() {
      this.currentStep = 1;
    },

    resetFileList() {
      this.fileList = [];
      (this.$refs.dragDrop as typeof DragNDrop).clearList();
    },

    resetReqShiftForm() {
      this.resetForm();
      this.resetStep();
      this.resetFileList();
    },

    onListUpdate(items: Array<Blob>) {
      this.fileList = [] as Array<Blob>;
      /* eslint-disable-next-line */
      items.forEach((el: any) => {
        this.fileList.push(el.raw);
      });
    },

    submit() {
      if (!this.formGroup.valid) {
        ToastService.errorToast(this.$t("errors.missing_required_fields"));
        this.formGroup.getValue();
        return;
      }

      this.isUploading = true;

      const startTimeHolder = (this.formGroup.controls.startDate
        .value as Date).toISOString();
      const endTimeHolder = (this.formGroup.controls.endDate.value as Date).toISOString();

       /* eslint-disable-next-line */
      const params: any = {
        title: this.formGroup.controls.title.value,
        startTimestamp: startTimeHolder,
        endTimestamp: endTimeHolder,
        description: this.formGroup.controls.description.value,
        file: this.fileList,
      };

      this.$store
        .dispatch(ActionTypes.CREATE_SHIFT_REQUEST, params)
        .then(() => {
          this.closeDialog();
          this.resetReqShiftForm();
          ToastService.successToast(this.$t("success_msg.shift_created"));
        })
        .catch((err) => {
          ToastService.errorToast(this.$t("errors.something_went_wrong"));
          console.log("Error: ", err);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },

    stepClick(index: number) {
      this.currentStep = index;
    },

    nextClick() {
      this.currentStep += 1;
    },

    prevClick() {
      this.currentStep -= 1;
    },
  },
  watch: {
    isModalOpen(newVal) {
      this.isLoading = true;
      if(newVal) {
        this.$store.dispatch(ActionTypes.GET_COMPANY_CLIENT_RECENT_SHIFT_DETAILS, {} as any)
        .then((res: ShiftMostRecentResponse) => {
            this.formGroup.controls.description.setValue(res.shift?.shiftDescription || "");
          })
          .catch((err) => {
            console.log("Error", err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }
});
