
import { defineComponent, computed } from "vue";
import { Button } from "mq-ui-kit/lib/Button";
import { useStore } from "vuex";
import MutationTypes from "@/store/types/mutation-types";
import { useRoleCheck } from "@/composables/role-check";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "DashboardHeader",
  components: {
    Button,
  },
  setup() {
    const store = useStore();

    const checkPermissions = (role: string) => (useRoleCheck(role));

    const customDate = computed(() => {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      } as const;
      const today = new Date();

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        return today.toLocaleDateString("nl-NL", options);
      }

      return today.toLocaleDateString("en-GB", options);
    });

    const openRequestShiftDialog = () => {
      store.commit(MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN, true);
    };

    return {
      customDate,
      openRequestShiftDialog,
      checkPermissions
    };
  },
});
