
import { defineComponent, PropType } from "vue";

// import MutationTypes from '@/store/types/mutation-types';
interface ColumnInfo {
  header: string;
  id: string;
  value: string;
  width?: string;
  headerAlign?: "left" | "center" | "right";
  rowAlign?: "left" | "center" | "right";
}
export default defineComponent({
  name: "DataTableRow",

  props: {
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      default: () => [],
    },

    itemKeyName: {
      type: String,
      default: "",
    },

    itemLogoLink: {
      type: String,
      default: "",
    },

    item: {
      type: Object as PropType<{
        [key: string]: string;
      }>,
      default: () => ({}),
    },
  },

  computed: {
    gridColumnTemplates() {
      let templates = "";

      this.columns.forEach((column) => {
        templates += `${column.width || "1fr"} `;
      });

      return templates.trim();
    },
  },
  emits: ["item-select"],
  methods: {
    onItemSelect(item: unknown) {
      this.$emit("item-select", item);
    },
  },
});
