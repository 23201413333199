<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      transform="translate(-464 -360)"
      fill="#999"
      fill-rule="evenodd"
      font-family="AppleColorEmoji, Apple Color Emoji"
      font-size="14"
    >
      <tspan x="464" y="375">
        🔔
      </tspan>
    </text>
  </svg>
</template>
