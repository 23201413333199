import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f5e937d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dash-reg" }
const _hoisted_2 = { class: "dash-reg__header" }
const _hoisted_3 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgClock3d = _resolveComponent("SvgClock3d")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SvgPenlined = _resolveComponent("SvgPenlined")!
  const _component_CardWrap = _resolveComponent("CardWrap")!
  const _component_DashboardRegistrationsRowSkeleton = _resolveComponent("DashboardRegistrationsRowSkeleton")!
  const _component_DashboardRegistrationsRow = _resolveComponent("DashboardRegistrationsRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("newest_registrations")) + " ", 1),
        _createVNode(_component_SvgClock3d)
      ]),
      _createVNode(_component_router_link, {
        class: "link__all",
        to: "/registrations-overview"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("global.view_all")), 1)
        ]),
        _: 1
      })
    ]),
    (!_ctx.newestRegistrations?.length && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_CardWrap, {
          key: 0,
          class: "dash-reg__empty"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SvgPenlined)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("no_registrations_yet")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_registrations_submitted")), 1)
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_CardWrap, {
          key: 1,
          class: "card-wrap--padding-fix"
        }, {
          default: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.registrationSize, (item, index) => {
                  return (_openBlock(), _createBlock(_component_DashboardRegistrationsRowSkeleton, { key: index }))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.newestRegistrations, (value, name) => {
                  return (_openBlock(), _createBlock(_component_DashboardRegistrationsRow, {
                    key: name,
                    item: value
                  }, null, 8, ["item"]))
                }), 128))
          ]),
          _: 1
        }))
  ]))
}