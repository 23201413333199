import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55a19504"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "table__sort"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-header-item", [_ctx.column.headerAlign, _ctx.sortClass, { sortable: _ctx.column.sortable }]]),
    key: _ctx.column.id,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onHeaderItemClick(_ctx.column))),
    ref: _ctx.column.id
  }, [
    _renderSlot(_ctx.$slots, `header_${_ctx.column.id}`, { column: _ctx.column }, () => [
      _createTextVNode(_toDisplayString(_ctx.column.header), 1)
    ], true),
    (_ctx.column.sortable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.sortOrder === 'asc')
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 0,
                class: "mq-p-icon",
                icon: "sort-up"
              }))
            : _createCommentVNode("", true),
          (_ctx.sortOrder === 'desc')
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 1,
                class: "mq-p-icon",
                icon: "sort-down"
              }))
            : _createCommentVNode("", true),
          (!_ctx.sortOrder)
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 2,
                class: "mq-p-icon",
                icon: "sort"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}