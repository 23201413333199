
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Tag from "@/components/ui/Tag.vue";
import Avatar from "@/components/ui/Avatar.vue";
import { StatusEnum } from "@/services/rest/dashboard";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "DashboardRegistrationsRow",
  components: {
    Tag,
    Avatar,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const customMsg = (status: string) => {
      const values: { [key in StatusEnum]?: string } = {
        [StatusEnum.ForApproval]: t("global.pending"),
        [StatusEnum.Approved]: t("global.processed"),
        [StatusEnum.Rejected]: t("global.declined"),
        [StatusEnum.CompanyRegistered]: t("global.pending"),
      };

      return values[status as StatusEnum];
    };

    const formatTotalTime = (hours: string): string => {
      return hours.split(":", 2).join(":");
    };

    const dateFormat = (item: Date) => {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      } as const;

      const customDate = new Date(item);

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        return customDate.toLocaleDateString("nl-NL", options);
      }

      return customDate.toLocaleDateString("en-GB", options);
    };

    return { customMsg, dateFormat, formatTotalTime };
  },
});
