/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewExistResponse
 */
export interface CompanyClientEmployeeReviewExistResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyClientEmployeeReviewExistResponse
     */
    exist: boolean;
}

export function CompanyClientEmployeeReviewExistResponseFromJSON(json: any): CompanyClientEmployeeReviewExistResponse {
    return CompanyClientEmployeeReviewExistResponseFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewExistResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewExistResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exist': json['exist'],
    };
}

export function CompanyClientEmployeeReviewExistResponseToJSON(value?: CompanyClientEmployeeReviewExistResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exist': value.exist,
    };
}

