
import { defineComponent, PropType } from "vue";

// import { Button } from "mq-ui-kit/lib/Button";

import DataTableHeader from "./DataTableHeader/DataTableHeader.vue";
import DataTableBody from "./DataTableBody/DataTableBody.vue";

interface ColumnInfo {
  header: string;
  id: string;
  value: string;
  width?: string;
  headerAlign?: "left" | "center" | "right";
  rowAlign?: "left" | "center" | "right";
  sortable?: boolean;
}

export default defineComponent({
  name: "DataTable",

  components: {
    DataTableHeader,
    DataTableBody,
    // DataTableSearchBar,
    // Button,
  },

  props: {
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      default: () => [],
    },

    itemKeyName: {
      type: String,
      default: "",
    },

    itemLogoLink: {
      type: String,
      default: "",
    },

    items: {
      type: Array as PropType<
        {
          [key: string]: string | number | undefined;
        }[]
      >,
      default: () => [],
    },

    noRecordMessage: String,

    resultCount: Number,

    totalElements: Number,
  },

  emits: [
    "search",
    "scroll-reached-bottom",
    "open-modal",
    "item-select",
    "header-item-click",
  ],

  computed: {
    gridColumnTemplates() {
      let templates = "";

      this.columns.forEach((column) => {
        templates += `${column.width || "1fr"} `;
      });

      return templates.trim();
    },

    emptyRecords() {
      let isEmpty = false;

      if (!this.items || this.items.length === 0) {
        isEmpty = true;
      }

      return isEmpty;
    },
  },

  methods: {
    onSearch(searchValue: string) {
      this.$emit("search", searchValue);
    },

    onScrollReachedBottom() {
      this.$emit("scroll-reached-bottom");
    },

    openModal() {
      this.$emit("open-modal");
    },

    onItemSelect(item: unknown) {
      this.$emit("item-select", item);
    },

    onHeaderItemClick(item: unknown) {
      this.$emit("header-item-click", item);
    },
  },
});
