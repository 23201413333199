import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f950a34"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "modal__container",
  role: "dialog",
  "aria-modal": "true"
}
const _hoisted_3 = {
  key: 0,
  id: "modal-header",
  class: "modal__header"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  id: "modal-content-content",
  class: "modal-content-content"
}
const _hoisted_6 = {
  id: "modal-footer",
  class: "modal__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      id: _ctx.modalId,
      class: "modal micromodal-slide",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _normalizeProps({
        class: ['modal__overlay', { 'modal__overlay--dark': _ctx.darkOverlay }],
        tabindex: "-1",
        [_ctx.closeTrigger || ""]: ''
      }), [
        _createElementVNode("div", _hoisted_2, [
          (!!_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true),
                (!_ctx.hideClose)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "modal__close",
                      "aria-label": "Close modal",
                      disabled: _ctx.disableClose
                    }, [
                      _createVNode(_component_FaIcon, _normalizeProps({
                        class: "icon",
                        icon: "xmark",
                        [_ctx.closeTrigger || ""]: ''
                      }), null, 16)
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              id: "modal-content",
              class: _normalizeClass(_ctx.containerClass)
            }, [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ], 2)
          ]),
          _createElementVNode("footer", _hoisted_6, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ])
        ])
      ], 16)
    ], 8, _hoisted_1)
  ]))
}