import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTableRow = _resolveComponent("DataTableRow")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "row",
    tag: "div",
    class: "table-body",
    onScroll: _ctx.onScroll
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_DataTableRow, {
          key: index,
          columns: _ctx.columns,
          item: item,
          onItemSelect: _ctx.onItemSelect
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (slot, name) => {
            return {
              name: name,
              fn: _withCtx((data) => [
                _renderSlot(_ctx.$slots, name, _mergeProps(data, { index: index }), undefined, true)
              ])
            }
          })
        ]), 1032, ["columns", "item", "onItemSelect"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["onScroll"]))
}