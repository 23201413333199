/* tslint:disable */
/* eslint-disable */
/**
 * Company Clients API
 * API to get company clients data
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsCompanyClientUserResponse
 */
export interface IsCompanyClientUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsCompanyClientUserResponse
     */
    isContact: boolean;
}

export function IsCompanyClientUserResponseFromJSON(json: any): IsCompanyClientUserResponse {
    return IsCompanyClientUserResponseFromJSONTyped(json, false);
}

export function IsCompanyClientUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsCompanyClientUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isContact': json['isContact'],
    };
}

export function IsCompanyClientUserResponseToJSON(value?: IsCompanyClientUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isContact': value.isContact,
    };
}

