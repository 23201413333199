import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c6d374c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dash-todo" }
const _hoisted_2 = { class: "dash-todo__header" }
const _hoisted_3 = { class: "dash-todo__cards" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgBell = _resolveComponent("SvgBell")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CardUpcomingShift = _resolveComponent("CardUpcomingShift")!
  const _component_CardUpcomingShiftSkeleton = _resolveComponent("CardUpcomingShiftSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("upcoming_shifts")) + " ", 1),
        _createVNode(_component_SvgBell)
      ]),
      _createVNode(_component_router_link, {
        class: "link__all",
        to: "/shifts-overview"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("global.view_all")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isLoading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.customUpcomingShifts, (item, index) => {
            return (_openBlock(), _createBlock(_component_CardUpcomingShift, {
              key: index,
              item: item,
              position: index
            }, null, 8, ["item", "position"]))
          }), 128))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.numOfSkeletonShifts, (item, index) => {
            return (_openBlock(), _createBlock(_component_CardUpcomingShiftSkeleton, { key: index }))
          }), 128))
    ]),
    (_ctx.errorToggle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("errors.something_went_wrong")), 1))
      : _createCommentVNode("", true)
  ]))
}