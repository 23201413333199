/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';
import {
    ShiftOrganiser,
    ShiftOrganiserFromJSON,
    ShiftOrganiserFromJSONTyped,
    ShiftOrganiserToJSON,
} from './ShiftOrganiser';

/**
 * 
 * @export
 * @interface ShiftResponse
 */
export interface ShiftResponse {
    /**
     * Shift id
     * @type {number}
     * @memberof ShiftResponse
     */
    shiftId: number;
    /**
     * Shift's project name
     * @type {string}
     * @memberof ShiftResponse
     */
    projectName?: string;
    /**
     * Shift's gig name
     * @type {string}
     * @memberof ShiftResponse
     */
    gigName: string;
    /**
     * Shift description
     * @type {string}
     * @memberof ShiftResponse
     */
    shiftDescription?: string;
    /**
     * Shift's location
     * @type {string}
     * @memberof ShiftResponse
     */
    workLocation: string;
    /**
     * 
     * @type {ShiftOrganiser}
     * @memberof ShiftResponse
     */
    organiser?: ShiftOrganiser;
    /**
     * Image of the gig
     * @type {string}
     * @memberof ShiftResponse
     */
    shiftCoverImageUrl?: string;
    /**
     * Time when shift starts
     * @type {Date}
     * @memberof ShiftResponse
     */
    startTimestamp: Date;
    /**
     * Time when shift ends
     * @type {Date}
     * @memberof ShiftResponse
     */
    endTimestamp: Date;
    /**
     * Number of employees needed
     * @type {number}
     * @memberof ShiftResponse
     */
    numberOfEmployeesNeeded: number;
    /**
     * Number of employees accepted
     * @type {number}
     * @memberof ShiftResponse
     */
    numberOfEmployeesAccepted: number;
    /**
     * Number of new applicants
     * @type {number}
     * @memberof ShiftResponse
     */
    numberOfNewApplicants?: number;
    /**
     * List of new applicants that needs to be approved by the planner.
     * @type {Array<Employees>}
     * @memberof ShiftResponse
     */
    newApplicants?: Array<Employees>;
}

export function ShiftResponseFromJSON(json: any): ShiftResponse {
    return ShiftResponseFromJSONTyped(json, false);
}

export function ShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'gigName': json['gigName'],
        'shiftDescription': !exists(json, 'shiftDescription') ? undefined : json['shiftDescription'],
        'workLocation': json['workLocation'],
        'organiser': !exists(json, 'organiser') ? undefined : ShiftOrganiserFromJSON(json['organiser']),
        'shiftCoverImageUrl': !exists(json, 'shiftCoverImageUrl') ? undefined : json['shiftCoverImageUrl'],
        'startTimestamp': (new Date(json['startTimestamp'])),
        'endTimestamp': (new Date(json['endTimestamp'])),
        'numberOfEmployeesNeeded': json['numberOfEmployeesNeeded'],
        'numberOfEmployeesAccepted': json['numberOfEmployeesAccepted'],
        'numberOfNewApplicants': !exists(json, 'numberOfNewApplicants') ? undefined : json['numberOfNewApplicants'],
        'newApplicants': !exists(json, 'newApplicants') ? undefined : ((json['newApplicants'] as Array<any>).map(EmployeesFromJSON)),
    };
}

export function ShiftResponseToJSON(value?: ShiftResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shiftId': value.shiftId,
        'projectName': value.projectName,
        'gigName': value.gigName,
        'shiftDescription': value.shiftDescription,
        'workLocation': value.workLocation,
        'organiser': ShiftOrganiserToJSON(value.organiser),
        'shiftCoverImageUrl': value.shiftCoverImageUrl,
        'startTimestamp': (value.startTimestamp.toISOString()),
        'endTimestamp': (value.endTimestamp.toISOString()),
        'numberOfEmployeesNeeded': value.numberOfEmployeesNeeded,
        'numberOfEmployeesAccepted': value.numberOfEmployeesAccepted,
        'numberOfNewApplicants': value.numberOfNewApplicants,
        'newApplicants': value.newApplicants === undefined ? undefined : ((value.newApplicants as Array<any>).map(EmployeesToJSON)),
    };
}

