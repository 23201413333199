enum ActionTypes {
  GET_UPCOMING_SHIFTS = "GET_UPCOMING_SHIFTS",
  GET_REQUIRED_ACTIONS = "GET_REQUIRED_ACTIONS",
  GET_COMPANY_CLIENTS_SHIFTS = "GET_COMPANY_CLIENTS_SHIFTS",
  GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID = "GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID",
  NUMBER_OF_SHIFTS = "NUMBER_OF_SHIFTS",
  GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW = "GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW",
  CREATE_SHIFT_REQUEST = "CREATE_SHIFT_REQUEST",
  GET_COMPANY_CLIENT_SHIFT_DETAILS = "GET_COMPANY_CLIENT_SHIFT_DETAILS",
  GET_COMPANY_CLIENT_EMPLOYEES = "GET_COMPANY_CLIENT_EMPLOYEES",
  GET_COMPANY_CLIENT_EMPLOYEE = "GET_COMPANY_CLIENT_EMPLOYEE",
  FAVOURITE_EMPLOYEE = "FAVOURITE_EMPLOYEE",
  APPROVE_REGISTRATION_LINE = "APPROVE_REGISTRATION_LINE",
  GET_COMPANY_CLIENT_LIST = "GET_COMPANY_CLIENT_LIST",
  GET_COMPANY_CLIENT = "GET_COMPANY_CLIENT",
  GET_EMPLOYEE_REVIEW_OR_PREPARE_TEMPLATE = "GET_EMPLOYEE_REVIEW_OR_PREPARE_TEMPLATE",
  GET_EMPLOYEE_REVIEW_LABELS = "GET_EMPLOYEE_REVIEW_LABELS",
  CREATE_EMPLOYEE_REVIEW = "CREATE_EMPLOYEE_REVIEW",
  UPDATE_EMPLOYEE_REVIEW = "UPDATE_EMPLOYEE_REVIEW",
  EDIT_REGISTRATION_CLIENT_EMPLOYEE = "EDIT_REGISTRATION_CLIENT_EMPLOYEE",
  GET_EMPLOYEE_REVIEW_OVERVIEWS = "GET_EMPLOYEE_REVIEW_OVERVIEWS",
  GET_EMPLOYEE_REVIEW_COUNT = "GET_EMPLOYEE_REVIEW_COUNT",
  GET_EMPLOYEE_REVIEW_OVERVIEW_SCORE = "GET_EMPLOYEE_REVIEW_OVERVIEW_SCORE",
  GET_EMPLOYEE_REVIEW_OVERVIEW = "GET_EMPLOYEE_REVIEW_OVERVIEW",
  GET_COMPANY_CLIENT_SHIFT_APPLICANTS = "GET_COMPANY_CLIENT_SHIFT_APPLICANTS",
  GET_COMPANY_CLIENT_RECENT_SHIFT_DETAILS = "GET_COMPANY_CLIENT_RECENT_SHIFT_DETAILS",
}

export default ActionTypes;
