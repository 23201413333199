/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyClientEmployeeReviewAverageResponse,
    CompanyClientEmployeeReviewAverageResponseFromJSON,
    CompanyClientEmployeeReviewAverageResponseToJSON,
    CompanyClientEmployeeReviewCount,
    CompanyClientEmployeeReviewCountFromJSON,
    CompanyClientEmployeeReviewCountToJSON,
    CompanyClientEmployeeReviewCreateRequest,
    CompanyClientEmployeeReviewCreateRequestFromJSON,
    CompanyClientEmployeeReviewCreateRequestToJSON,
    CompanyClientEmployeeReviewExistResponse,
    CompanyClientEmployeeReviewExistResponseFromJSON,
    CompanyClientEmployeeReviewExistResponseToJSON,
    CompanyClientEmployeeReviewResponse,
    CompanyClientEmployeeReviewResponseFromJSON,
    CompanyClientEmployeeReviewResponseToJSON,
    CompanyClientEmployeeReviewUpdateRequest,
    CompanyClientEmployeeReviewUpdateRequestFromJSON,
    CompanyClientEmployeeReviewUpdateRequestToJSON,
    CompanyClientEmployeeSimpleReviewResponse,
    CompanyClientEmployeeSimpleReviewResponseFromJSON,
    CompanyClientEmployeeSimpleReviewResponseToJSON,
    CompanyClientReviewLabelResponse,
    CompanyClientReviewLabelResponseFromJSON,
    CompanyClientReviewLabelResponseToJSON,
    CompanyClientReviewOnShiftResponse,
    CompanyClientReviewOnShiftResponseFromJSON,
    CompanyClientReviewOnShiftResponseToJSON,
    CompanyClientReviewScoreSummaryResponse,
    CompanyClientReviewScoreSummaryResponseFromJSON,
    CompanyClientReviewScoreSummaryResponseToJSON,
    EmployeeFavouriteRequest,
    EmployeeFavouriteRequestFromJSON,
    EmployeeFavouriteRequestToJSON,
    EmployeeFavouriteResponse,
    EmployeeFavouriteResponseFromJSON,
    EmployeeFavouriteResponseToJSON,
    EmployeeOnShiftFilter,
    EmployeeOnShiftFilterFromJSON,
    EmployeeOnShiftFilterToJSON,
    EmployeeOnShiftResponse,
    EmployeeOnShiftResponseFromJSON,
    EmployeeOnShiftResponseToJSON,
    Employees,
    EmployeesFromJSON,
    EmployeesToJSON,
    EmployeesOfCompanyClientFilter,
    EmployeesOfCompanyClientFilterFromJSON,
    EmployeesOfCompanyClientFilterToJSON,
    EmployeesOfCompanyClientResponse,
    EmployeesOfCompanyClientResponseFromJSON,
    EmployeesOfCompanyClientResponseToJSON,
} from '../models';

export interface CreateEmployeeReviewRequest {
    companyClientEmployeeReviewCreateRequest: CompanyClientEmployeeReviewCreateRequest;
}

export interface DeleteEmployeeReviewRequest {
    reviewId: number;
}

export interface DeleteEmployeeReviewLabelRequest {
    labelId: number;
}

export interface EmployeeFavouriteOperationRequest {
    employeeFavouriteRequest: EmployeeFavouriteRequest;
}

export interface EmployeeOfCompanyClientRequest {
    employeeId: number;
}

export interface EmployeesOfCompanyClientRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    employeesOfCompanyClientFilter?: EmployeesOfCompanyClientFilter;
}

export interface ExistEmployeeReviewRequest {
    employeeId: number;
    shiftId: number;
}

export interface GetAllCompanyClientEmployeeReviewsRequest {
    employeeId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetAverageEmployeeReviewRequest {
    employeeId: number;
}

export interface GetEmployeeReviewCountRequest {
    employeeId: number;
}

export interface GetEmployeeReviewOrPrepareTemplateRequest {
    employeeId: number;
    shiftId: number;
}

export interface GetEmployeeReviewOverviewRequest {
    employeeId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetEmployeeReviewOverviewScoreRequest {
    employeeId: number;
}

export interface GetEmployeeReviewOverviewsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    employeeOnShiftFilter?: EmployeeOnShiftFilter;
}

export interface UpdateEmployeeReviewRequest {
    companyClientEmployeeReviewUpdateRequest: CompanyClientEmployeeReviewUpdateRequest;
}

/**
 * 
 */
export class EmployeesApi extends runtime.BaseAPI {

    /**
     * Creates new company client employee review
     * Creates new review
     */
    async createEmployeeReviewRaw(requestParameters: CreateEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeReviewResponse>> {
        if (requestParameters.companyClientEmployeeReviewCreateRequest === null || requestParameters.companyClientEmployeeReviewCreateRequest === undefined) {
            throw new runtime.RequiredError('companyClientEmployeeReviewCreateRequest','Required parameter requestParameters.companyClientEmployeeReviewCreateRequest was null or undefined when calling createEmployeeReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyClientEmployeeReviewCreateRequestToJSON(requestParameters.companyClientEmployeeReviewCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeReviewResponseFromJSON(jsonValue));
    }

    /**
     * Creates new company client employee review
     * Creates new review
     */
    async createEmployeeReview(requestParameters: CreateEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeReviewResponse> {
        const response = await this.createEmployeeReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes existing client employee review
     * Deletes existing employee review
     */
    async deleteEmployeeReviewRaw(requestParameters: DeleteEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling deleteEmployeeReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/{reviewId}/delete`.replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes existing client employee review
     * Deletes existing employee review
     */
    async deleteEmployeeReview(requestParameters: DeleteEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteEmployeeReviewRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a label if it\'s not used in an employee review
     * Deletes label
     */
    async deleteEmployeeReviewLabelRaw(requestParameters: DeleteEmployeeReviewLabelRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.labelId === null || requestParameters.labelId === undefined) {
            throw new runtime.RequiredError('labelId','Required parameter requestParameters.labelId was null or undefined when calling deleteEmployeeReviewLabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/label/{labelId}/delete`.replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters.labelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a label if it\'s not used in an employee review
     * Deletes label
     */
    async deleteEmployeeReviewLabel(requestParameters: DeleteEmployeeReviewLabelRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteEmployeeReviewLabelRaw(requestParameters, initOverrides);
    }

    /**
     * Like or unlike employee by specific Company Client
     */
    async employeeFavouriteRaw(requestParameters: EmployeeFavouriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmployeeFavouriteResponse>> {
        if (requestParameters.employeeFavouriteRequest === null || requestParameters.employeeFavouriteRequest === undefined) {
            throw new runtime.RequiredError('employeeFavouriteRequest','Required parameter requestParameters.employeeFavouriteRequest was null or undefined when calling employeeFavourite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/favourite-employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeFavouriteRequestToJSON(requestParameters.employeeFavouriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeFavouriteResponseFromJSON(jsonValue));
    }

    /**
     * Like or unlike employee by specific Company Client
     */
    async employeeFavourite(requestParameters: EmployeeFavouriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmployeeFavouriteResponse> {
        const response = await this.employeeFavouriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get details for one employee of Company Client
     * Get company client employee details
     */
    async employeeOfCompanyClientRaw(requestParameters: EmployeeOfCompanyClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Employees>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling employeeOfCompanyClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeesFromJSON(jsonValue));
    }

    /**
     * Get details for one employee of Company Client
     * Get company client employee details
     */
    async employeeOfCompanyClient(requestParameters: EmployeeOfCompanyClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Employees> {
        const response = await this.employeeOfCompanyClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all basic employee information for company client applicants. Basic information includes basic personal data and employee review count. This endpoint allows filtering per-name and per-favourite flag.
     * Get employee info
     */
    async employeesOfCompanyClientRaw(requestParameters: EmployeesOfCompanyClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmployeesOfCompanyClientResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeesOfCompanyClientFilterToJSON(requestParameters.employeesOfCompanyClientFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeesOfCompanyClientResponseFromJSON(jsonValue));
    }

    /**
     * Get all basic employee information for company client applicants. Basic information includes basic personal data and employee review count. This endpoint allows filtering per-name and per-favourite flag.
     * Get employee info
     */
    async employeesOfCompanyClient(requestParameters: EmployeesOfCompanyClientRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmployeesOfCompanyClientResponse> {
        const response = await this.employeesOfCompanyClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if review exist for employee and shift
     * Check if review exist
     */
    async existEmployeeReviewRaw(requestParameters: ExistEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeReviewExistResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling existEmployeeReview.');
        }

        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling existEmployeeReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/employee/{employeeId}/shift/{shiftId}/exist`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))).replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeReviewExistResponseFromJSON(jsonValue));
    }

    /**
     * Check if review exist for employee and shift
     * Check if review exist
     */
    async existEmployeeReview(requestParameters: ExistEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeReviewExistResponse> {
        const response = await this.existEmployeeReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all reviews on employee performed by company client
     * Get all reviews
     */
    async getAllCompanyClientEmployeeReviewsRaw(requestParameters: GetAllCompanyClientEmployeeReviewsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CompanyClientEmployeeSimpleReviewResponse>>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getAllCompanyClientEmployeeReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/employee/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyClientEmployeeSimpleReviewResponseFromJSON));
    }

    /**
     * Get all reviews on employee performed by company client
     * Get all reviews
     */
    async getAllCompanyClientEmployeeReviews(requestParameters: GetAllCompanyClientEmployeeReviewsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CompanyClientEmployeeSimpleReviewResponse>> {
        const response = await this.getAllCompanyClientEmployeeReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get average review on employee given by company client
     * Get average review
     */
    async getAverageEmployeeReviewRaw(requestParameters: GetAverageEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeReviewAverageResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getAverageEmployeeReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/employee/{employeeId}/average`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeReviewAverageResponseFromJSON(jsonValue));
    }

    /**
     * Get average review on employee given by company client
     * Get average review
     */
    async getAverageEmployeeReview(requestParameters: GetAverageEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeReviewAverageResponse> {
        const response = await this.getAverageEmployeeReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Count of all reviews performed on employee by this company client.
     * Count of reviews
     */
    async getEmployeeReviewCountRaw(requestParameters: GetEmployeeReviewCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeReviewCount>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployeeReviewCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/employee/{employeeId}/count`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeReviewCountFromJSON(jsonValue));
    }

    /**
     * Count of all reviews performed on employee by this company client.
     * Count of reviews
     */
    async getEmployeeReviewCount(requestParameters: GetEmployeeReviewCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeReviewCount> {
        const response = await this.getEmployeeReviewCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns employee labels list for company client reviews
     * Returns labels
     */
    async getEmployeeReviewLabelsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CompanyClientReviewLabelResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/label`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyClientReviewLabelResponseFromJSON));
    }

    /**
     * Returns employee labels list for company client reviews
     * Returns labels
     */
    async getEmployeeReviewLabels(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CompanyClientReviewLabelResponse>> {
        const response = await this.getEmployeeReviewLabelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get existing review of employee or prepare template
     * Get existing review or prepare template
     */
    async getEmployeeReviewOrPrepareTemplateRaw(requestParameters: GetEmployeeReviewOrPrepareTemplateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeReviewResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployeeReviewOrPrepareTemplate.');
        }

        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling getEmployeeReviewOrPrepareTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/employee/{employeeId}/shift/{shiftId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))).replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeReviewResponseFromJSON(jsonValue));
    }

    /**
     * Get existing review of employee or prepare template
     * Get existing review or prepare template
     */
    async getEmployeeReviewOrPrepareTemplate(requestParameters: GetEmployeeReviewOrPrepareTemplateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeReviewResponse> {
        const response = await this.getEmployeeReviewOrPrepareTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All reviews on shifts for this employee by company client users.
     * All reviews on this employee list
     */
    async getEmployeeReviewOverviewRaw(requestParameters: GetEmployeeReviewOverviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientReviewOnShiftResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployeeReviewOverview.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/overview/employee/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientReviewOnShiftResponseFromJSON(jsonValue));
    }

    /**
     * All reviews on shifts for this employee by company client users.
     * All reviews on this employee list
     */
    async getEmployeeReviewOverview(requestParameters: GetEmployeeReviewOverviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientReviewOnShiftResponse> {
        const response = await this.getEmployeeReviewOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Review score based on number of reviews by company client user.
     * Review score list
     */
    async getEmployeeReviewOverviewScoreRaw(requestParameters: GetEmployeeReviewOverviewScoreRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientReviewScoreSummaryResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployeeReviewOverviewScore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/overview/employee/{employeeId}/score-summary`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientReviewScoreSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Review score based on number of reviews by company client user.
     * Review score list
     */
    async getEmployeeReviewOverviewScore(requestParameters: GetEmployeeReviewOverviewScoreRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientReviewScoreSummaryResponse> {
        const response = await this.getEmployeeReviewOverviewScoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get overview of all reviews performed by logged-in user.
     * Get overview of all reviews
     */
    async getEmployeeReviewOverviewsRaw(requestParameters: GetEmployeeReviewOverviewsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmployeeOnShiftResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/overview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeOnShiftFilterToJSON(requestParameters.employeeOnShiftFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeOnShiftResponseFromJSON(jsonValue));
    }

    /**
     * Get overview of all reviews performed by logged-in user.
     * Get overview of all reviews
     */
    async getEmployeeReviewOverviews(requestParameters: GetEmployeeReviewOverviewsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmployeeOnShiftResponse> {
        const response = await this.getEmployeeReviewOverviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates existing company client employee review
     * Updates existing review
     */
    async updateEmployeeReviewRaw(requestParameters: UpdateEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientEmployeeSimpleReviewResponse>> {
        if (requestParameters.companyClientEmployeeReviewUpdateRequest === null || requestParameters.companyClientEmployeeReviewUpdateRequest === undefined) {
            throw new runtime.RequiredError('companyClientEmployeeReviewUpdateRequest','Required parameter requestParameters.companyClientEmployeeReviewUpdateRequest was null or undefined when calling updateEmployeeReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/employees/review/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyClientEmployeeReviewUpdateRequestToJSON(requestParameters.companyClientEmployeeReviewUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientEmployeeSimpleReviewResponseFromJSON(jsonValue));
    }

    /**
     * Updates existing company client employee review
     * Updates existing review
     */
    async updateEmployeeReview(requestParameters: UpdateEmployeeReviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientEmployeeSimpleReviewResponse> {
        const response = await this.updateEmployeeReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
