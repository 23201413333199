
import { defineComponent, computed } from "vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";

export default defineComponent({
  name: "CardTodo",
  components: {
    CardWrap,
  },
  props: {
    item: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    color: {
      type: String,
      default: "green",
    },
    desc: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const clazz = computed(() => ({
      "card-todo__img--orange": props.color === "orange",
      "card-todo__img--green": props.color === "green",
      "card-todo__img--purple": props.color === "purple",
    }));
    return {
      clazz,
    };
  },
});
