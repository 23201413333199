/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeesOfCompanyClientFilter
 */
export interface EmployeesOfCompanyClientFilter {
    /**
     * Indicates whether employee is liked by the company client
     * @type {boolean}
     * @memberof EmployeesOfCompanyClientFilter
     */
    favourite?: boolean;
    /**
     * Employee's full name
     * @type {string}
     * @memberof EmployeesOfCompanyClientFilter
     */
    fullName?: string;
}

export function EmployeesOfCompanyClientFilterFromJSON(json: any): EmployeesOfCompanyClientFilter {
    return EmployeesOfCompanyClientFilterFromJSONTyped(json, false);
}

export function EmployeesOfCompanyClientFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeesOfCompanyClientFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favourite': !exists(json, 'favourite') ? undefined : json['favourite'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
    };
}

export function EmployeesOfCompanyClientFilterToJSON(value?: EmployeesOfCompanyClientFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favourite': value.favourite,
        'fullName': value.fullName,
    };
}

