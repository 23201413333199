import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "row",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemSelect(_ctx.item)))
  }, [
    _createElementVNode("div", {
      class: "row-inner",
      style: _normalizeStyle({ 'grid-template-columns': _ctx.gridColumnTemplates })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          key: _ctx.item[column.value],
          class: _normalizeClass(["row-cell-item", [column.rowAlign]])
        }, [
          _renderSlot(_ctx.$slots, `item_${column.id}`, {
            column: column,
            item: _ctx.item
          }, () => [
            _createTextVNode(_toDisplayString(_ctx.item[column.value]), 1)
          ], true)
        ], 2))
      }), 128))
    ], 4)
  ]))
}