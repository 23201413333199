<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable -->
    <path
      d="M17.342 1.053h2.105V20H.5V1.053h2.105V0h4.21v1.053h6.317V0h4.21v1.053ZM8.395 3.684v3.158h3.158V3.684H8.395Zm5.263 0v3.158h3.158V3.684h-3.158ZM3.132 8.947v3.158h3.157V8.947H3.132Zm5.263 0v3.158h3.158V8.947H8.395Zm5.263 0v3.158h3.158V8.947h-3.158ZM3.132 14.316v3.158h3.157v-3.158H3.132Zm5.263 0v3.158h3.158v-3.158H8.395Z"
      fill-rule="nonzero"
    />
  </svg>
</template>
