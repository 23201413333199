import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown__dropdown", {open: _ctx.isDropdownOpen}])
  }, [
    _createElementVNode("button", {
      type: "button",
      "aria-haspopup": "true",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openOptions && _ctx.openOptions(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("ul", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDropDown && _ctx.closeDropDown(...args)))
    }, [
      _renderSlot(_ctx.$slots, "dropdown-menu", {}, undefined, true)
    ])
  ], 2)), [
    [_directive_click_away, _ctx.closeDropDown]
  ])
}