/* tslint:disable */
/* eslint-disable */
/**
 * Company Clients API
 * API to get company clients data
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyClientOfUserOptionalResponse,
    CompanyClientOfUserOptionalResponseFromJSON,
    CompanyClientOfUserOptionalResponseToJSON,
    CompanyClientsOfUserResponse,
    CompanyClientsOfUserResponseFromJSON,
    CompanyClientsOfUserResponseToJSON,
    IsCompanyClientUserResponse,
    IsCompanyClientUserResponseFromJSON,
    IsCompanyClientUserResponseToJSON,
} from '../models';

export interface GetForUserRequest {
    companyClientTradeName?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetForUserOfClientRequest {
    companyClientId: number;
}

export interface IsUserContactOfCompanyClientRequest {
    companyClientId: number;
}

/**
 * 
 */
export class CompaniesClientsApi extends runtime.BaseAPI {

    /**
     * Returns list of company clients and roles for logged-in user
     * List of clients and role mappings
     */
    async getForUserRaw(requestParameters: GetForUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientsOfUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters.companyClientTradeName !== undefined) {
            queryParameters['companyClientTradeName'] = requestParameters.companyClientTradeName;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        const response = await this.request({
            path: `/companies-clients/userinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientsOfUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns list of company clients and roles for logged-in user
     * List of clients and role mappings
     */
    async getForUser(requestParameters: GetForUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientsOfUserResponse> {
        const response = await this.getForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns roles mappings assigned to logged-in user as contact of Company Client.
     * Company client userinfo endpoint.
     */
    async getForUserOfClientRaw(requestParameters: GetForUserOfClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientOfUserOptionalResponse>> {
        if (requestParameters.companyClientId === null || requestParameters.companyClientId === undefined) {
            throw new runtime.RequiredError('companyClientId','Required parameter requestParameters.companyClientId was null or undefined when calling getForUserOfClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        const response = await this.request({
            path: `/companies-clients/{companyClientId}/userinfo`.replace(`{${"companyClientId"}}`, encodeURIComponent(String(requestParameters.companyClientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientOfUserOptionalResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns roles mappings assigned to logged-in user as contact of Company Client.
     * Company client userinfo endpoint.
     */
    async getForUserOfClient(requestParameters: GetForUserOfClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientOfUserOptionalResponse> {
        const response = await this.getForUserOfClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validates whether user is contact of company client
     * Is user a contact of company client flag
     */
    async isUserContactOfCompanyClientRaw(requestParameters: IsUserContactOfCompanyClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IsCompanyClientUserResponse>> {
        if (requestParameters.companyClientId === null || requestParameters.companyClientId === undefined) {
            throw new runtime.RequiredError('companyClientId','Required parameter requestParameters.companyClientId was null or undefined when calling isUserContactOfCompanyClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        const response = await this.request({
            path: `/companies-clients/{companyClientId}/check-is-user-contact`.replace(`{${"companyClientId"}}`, encodeURIComponent(String(requestParameters.companyClientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsCompanyClientUserResponseFromJSON(jsonValue));
    }

    /**
     * Validates whether user is contact of company client
     * Is user a contact of company client flag
     */
    async isUserContactOfCompanyClient(requestParameters: IsUserContactOfCompanyClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IsCompanyClientUserResponse> {
        const response = await this.isUserContactOfCompanyClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
