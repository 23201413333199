import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b6d6142"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-todo" }
const _hoisted_2 = { class: "card-todo__num" }
const _hoisted_3 = { class: "card-todo__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardWrap = _resolveComponent("CardWrap")!

  return (_openBlock(), _createBlock(_component_CardWrap, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.item), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["card-todo__img", _ctx.clazz])
        }, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ], 2)
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.desc), 1)
    ]),
    _: 3
  }))
}