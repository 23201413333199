import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dbeaac8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reg-row" }
const _hoisted_2 = { class: "reg-row__name" }
const _hoisted_3 = { class: "reg-row__shift" }
const _hoisted_4 = { class: "reg-row__date" }
const _hoisted_5 = { class: "reg-row__amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      name: _ctx.item.employeeData.firstName,
      "last-name": _ctx.item.employeeData.lastName,
      "img-path": _ctx.item.employeeData.profilePictureUrl
    }, null, 8, ["name", "last-name", "img-path"]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.item.employeeData.fullName), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.item.shiftData?.shiftName ?? _ctx.$t('not_applicable')), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.dateFormat(_ctx.item.startDate)), 1),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formatTotalTime(_ctx.item.quantity)) + " " + _toDisplayString(_ctx.item.unit), 1),
    _createVNode(_component_Tag, {
      color: _ctx.item.status
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.customMsg(_ctx.item.status)), 1)
      ]),
      _: 1
    }, 8, ["color"])
  ]))
}