
import { defineComponent, PropType } from "vue";
import { ColumnInfo } from ".";

type Order = null | "asc" | "desc";

export default defineComponent({
  name: "DataTableHeaderItem",
  props: {
    column: {
      type: Object as PropType<ColumnInfo>,
      required: true,
    },
  },

  emits: ["header-item-sort-click"],

  data() {
    return {
      sortOrder: null as Order,
    };
  },

  methods: {
    onHeaderItemClick(item: any) {
      if (item.sortable) this.sorting();
      this.$emit("header-item-sort-click", { item, sortOrder: this.sortOrder });
    },

    sorting() {
      if (!this.sortOrder) {
        this.sortOrder = "asc";
        return;
      }
      if (this.sortOrder === "asc") {
        this.sortOrder = "desc";
        return;
      }
      if (this.sortOrder === "desc") {
        this.sortOrder = null;
      }
    },
  },

  computed: {
    sortClass() {
      return `sort--${this.sortOrder}`;
    },
  },
});
