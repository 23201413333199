import en from "@/locales/en.json";
import nl from "@/locales/nl.json";

export enum Locales {
  EN = "en",
  NL = "nl",
}

function getUserLanguage(): string {
  const lang = navigator.language;
  if (lang.toUpperCase().includes("NL")) {
    return Locales.NL;
  }
  return Locales.EN;
}

export const locale = getUserLanguage();
export const defaultLocale = Locales.EN;

export const messages = {
  [Locales.EN]: en,
  [Locales.NL]: nl
};
