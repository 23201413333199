import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c394e16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expand-collapse__top" }
const _hoisted_2 = ["aria-expanded"]
const _hoisted_3 = {
  key: 0,
  class: "expand-collapse__count expand-collapse__count--style"
}
const _hoisted_4 = {
  key: 0,
  class: "expand-collapse__visible--container"
}
const _hoisted_5 = { class: "expand-collapse__count--style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "expand-collapse",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass({ 'expand-collapse__visible' : _ctx.visible }),
        "aria-expanded": _ctx.visible ? 'true' : 'false'
      }, [
        _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
        (_ctx.items)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.items.length), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2),
      _createVNode(_component_FaIcon, {
        class: _normalizeClass(["expand-collapse__icon--down", { 'expand-collapse__icon--up': !_ctx.visible }]),
        icon: ['far', 'angle-down']
      }, null, 8, ["class"])
    ]),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "expand-collapse__label"
            }, [
              _createTextVNode(_toDisplayString(item.description) + " ", 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(item.labelCount), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}