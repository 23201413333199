import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a0bca90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-upcom__date" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "card-upcom__desc" }
const _hoisted_4 = { class: "card-upcom__text" }
const _hoisted_5 = { class: "card-upcom__time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-upcom card-upcom--padding-fix card-upcom--order", { active: _ctx.isActive }])
  }, [
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.shiftDate[0]), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.shiftDate[1]), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["card-upcom__img", _ctx.useRandomBg(_ctx.item.shiftId)])
    }, [
      (_ctx.item.shiftCoverImageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(_ctx.imgErrorClass),
            src: _ctx.item.shiftCoverImageUrl,
            alt: _ctx.item.gigName,
            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleErrorImg && _ctx.handleErrorImg(...args)))
          }, null, 42, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.item.gigName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, [
          _createVNode(_component_FaIcon, {
            class: "mq-p-icon",
            icon: ['fal', 'clock']
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.startEndShift), 1)
        ])
      ])
    ])
  ], 2))
}