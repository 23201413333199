/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftFilter
 */
export interface ShiftFilter {
    /**
     * Shift ID cursor from which data will be paginated
     * @type {number}
     * @memberof ShiftFilter
     */
    shiftId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftFilter
     */
    shiftType: ShiftFilterShiftTypeEnum;
    /**
     * How many applicants to fetch
     * @type {number}
     * @memberof ShiftFilter
     */
    maxNumberOfApplicants?: number;
}


/**
 * @export
 */
export const ShiftFilterShiftTypeEnum = {
    Past: 'PAST',
    Present: 'PRESENT',
    Future: 'FUTURE',
    Upcoming: 'UPCOMING',
    Filled: 'FILLED',
    Open: 'OPEN'
} as const;
export type ShiftFilterShiftTypeEnum = typeof ShiftFilterShiftTypeEnum[keyof typeof ShiftFilterShiftTypeEnum];


export function ShiftFilterFromJSON(json: any): ShiftFilter {
    return ShiftFilterFromJSONTyped(json, false);
}

export function ShiftFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shiftId': !exists(json, 'shiftId') ? undefined : json['shiftId'],
        'shiftType': json['shiftType'],
        'maxNumberOfApplicants': !exists(json, 'maxNumberOfApplicants') ? undefined : json['maxNumberOfApplicants'],
    };
}

export function ShiftFilterToJSON(value?: ShiftFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shiftId': value.shiftId,
        'shiftType': value.shiftType,
        'maxNumberOfApplicants': value.maxNumberOfApplicants,
    };
}

