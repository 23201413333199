
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    spinning: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSpinnerEnabled: this.spinning,
    };
  },
  watch: {
    spinning(value) {
      this.isSpinnerEnabled = value;
    },
  },
  methods: {
    enableSpinner() {
      this.isSpinnerEnabled = true;
    },
    disableSpinner() {
      this.isSpinnerEnabled = false;
    },
  },
});
