
import { computed, defineComponent, PropType } from "vue";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "ListItemDate",
  props: {
    startDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const timeFormat = (time: string) => {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
      } as const;

      const customTime = new Date(time);

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        return customTime.toLocaleTimeString("nl-NL", options);
      }

      return customTime.toLocaleTimeString("en-GB", options);
    };

    const startEndTime = computed(() => `${timeFormat(props.startTime)} - ${timeFormat(props.endTime)}`);

    const dateFormat = (item: Date) => {
      const customDate = new Date(item);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      } as const;

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        return customDate.toLocaleDateString("nl-NL", options);
      }

      return customDate.toLocaleDateString("en-GB", options);
    };

    return { dateFormat, startEndTime };
  },
});
