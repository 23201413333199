import {CompaniesClientsApi, DashboardApi, EmployeesApi, RegistrationsApi, ShiftsApi,} from "@/../rpc-api-source/apis";
//
import {GetRegistrationsByCompanyClientIdRequest, UpcomingShiftsRequest,} from "@/../rpc-api-source/apis/DashboardApi";
//
import {GetForUserOfClientRequest, GetForUserRequest,} from "@/../rpc-api-source/apis/CompaniesClientsApi";
//
import {
  CompanyClientShiftApplicantsRequest,
  CompanyClientShiftDetailsRequest,
  CompanyClientShiftsRequest,
  ShiftRequestRequest,
} from "@/../rpc-api-source/apis/ShiftsApi";
//
import {
  ApproveBulkRegistrationsRequest,
  EditRegistrationForCompanyClientEmployeeRequest,
  RegistrationsByCompanyClientIdRequest
} from "@/../rpc-api-source/apis/RegistrationsApi";
//
import {
  CreateEmployeeReviewRequest,
  EmployeeFavouriteOperationRequest,
  EmployeeOfCompanyClientRequest,
  EmployeesOfCompanyClientRequest,
  GetEmployeeReviewCountRequest,
  GetEmployeeReviewOrPrepareTemplateRequest,
  GetEmployeeReviewOverviewRequest,
  GetEmployeeReviewOverviewScoreRequest,
  GetEmployeeReviewOverviewsRequest,
  UpdateEmployeeReviewRequest
} from "@/../rpc-api-source/apis/EmployeesApi";
//
import {
  BulkRegistrationLinesResponse,
  CompanyClientEmployeeReviewCount,
  CompanyClientEmployeeReviewCreateRequest,
  CompanyClientEmployeeReviewResponse,
  CompanyClientEmployeeReviewUpdateRequest,
  CompanyClientEmployeeSimpleReviewResponse,
  CompanyClientOfUserOptionalResponse,
  CompanyClientOfUserResponse,
  CompanyClientReviewLabelApplicationKind as CompanyClientReviewLabelApplicationKindEnum,
  CompanyClientReviewLabelOfEmployee,
  CompanyClientReviewLabelResponse,
  CompanyClientReviewOnShift,
  CompanyClientReviewOnShiftResponse,
  CompanyClientReviewScoreSummaryResponse,
  CompanyClientShiftsResponse,
  CompanyClientsOfUserResponse,
  EditShiftRegistrationDto,
  Employee,
  EmployeeFavouriteResponse,
  EmployeeOnShiftFilter,
  EmployeeOnShiftFilterStatusEnum,
  EmployeeOnShiftResponse,
  Employees,
  EmployeesOfCompanyClientFilter,
  EmployeesOfCompanyClientResponse,
  GetRegistrationsResponse,
  NumberOfCompanyClientShiftsResponse,
  RegistrationClass,
  RegistrationDto,
  RegistrationsForCompanyClientRequestFilters,
  RegistrationsForCompanyClientRequestFiltersStatusEnum,
  RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum,
  RegistrationsForCompanyClientResponse,
  RequiredActionsResponse,
  ShiftApplicantsResponse,
  ShiftFilter,
  ShiftFilterShiftTypeEnum,
  ShiftMostRecentResponse,
  ShiftRegistrationDto,
  ShiftRequestResponse,
  ShiftResponse,
  Status as StatusEnum,
  UpcomingShiftResponse
} from "@/../rpc-api-source/models";
//
import restConfigService from "./rest-api-config";

// export TYPES for future use in vuex etc
export type {
  UpcomingShiftsRequest,
  UpcomingShiftResponse,
  RequiredActionsResponse,
  Employees,
  Employee,
  EmployeeFavouriteOperationRequest,
  EmployeesOfCompanyClientRequest,
  CompanyClientShiftsRequest,
  CompanyClientShiftsResponse,
  ShiftResponse,
  ShiftMostRecentResponse,
  GetRegistrationsByCompanyClientIdRequest,
  GetRegistrationsResponse,
  NumberOfCompanyClientShiftsResponse,
  RegistrationDto,
  RegistrationsForCompanyClientResponse,
  RegistrationsByCompanyClientIdRequest,
  EmployeeOfCompanyClientRequest,
  ShiftRegistrationDto,
  ShiftRequestRequest,
  ShiftRequestResponse,
  CompanyClientShiftDetailsRequest,
  ShiftFilter,
  EmployeesOfCompanyClientResponse,
  EmployeeFavouriteResponse,
  ApproveBulkRegistrationsRequest,
  BulkRegistrationLinesResponse,
  CompanyClientOfUserResponse,
  CompanyClientsOfUserResponse,
  ShiftApplicantsResponse,
  EmployeesOfCompanyClientFilter,
  RegistrationsForCompanyClientRequestFilters,
  GetEmployeeReviewOrPrepareTemplateRequest,
  CompanyClientEmployeeReviewResponse,
  CompanyClientEmployeeSimpleReviewResponse,
  CompanyClientReviewLabelResponse,
  CreateEmployeeReviewRequest,
  UpdateEmployeeReviewRequest,
  EditRegistrationForCompanyClientEmployeeRequest,
  EditShiftRegistrationDto,
  CompanyClientEmployeeReviewCreateRequest,
  CompanyClientEmployeeReviewUpdateRequest,
  CompanyClientReviewLabelOfEmployee,
  GetEmployeeReviewOverviewsRequest,
  EmployeeOnShiftResponse,
  EmployeeOnShiftFilter,
  GetEmployeeReviewCountRequest,
  CompanyClientEmployeeReviewCount,
  GetEmployeeReviewOverviewScoreRequest,
  CompanyClientReviewScoreSummaryResponse,
  GetEmployeeReviewOverviewRequest,
  CompanyClientReviewOnShiftResponse,
  CompanyClientReviewOnShift,
  CompanyClientShiftApplicantsRequest,
  GetForUserRequest,
  GetForUserOfClientRequest,
  CompanyClientOfUserOptionalResponse,
};

// export ENUMS for future use in vuex etc
export {
  StatusEnum,
  ShiftFilterShiftTypeEnum,
  RegistrationsForCompanyClientRequestFiltersStatusEnum,
  RegistrationClass,
  RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum,
  CompanyClientReviewLabelApplicationKindEnum,
  EmployeeOnShiftFilterStatusEnum,
};

export default {
  getUpcomingShifts(
    reqObj: UpcomingShiftsRequest
  ): Promise<Array<UpcomingShiftResponse>> {
    const config = restConfigService.getBaseConfig();

    const createApi: DashboardApi = new DashboardApi(config);

    const params: UpcomingShiftsRequest = reqObj;

    return createApi.upcomingShifts(params);
  },
  //
  getRequiredActions(): Promise<RequiredActionsResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: DashboardApi = new DashboardApi(config);

    return createApi.getRequiredActions();
  },
  //
  getCompanyClientShifts(
    reqObj: CompanyClientShiftsRequest
  ): Promise<CompanyClientShiftsResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    const params: CompanyClientShiftsRequest = reqObj;

    return createApi.companyClientShifts(params);
  },
  //
  // for Dashboard newest Reg mostly
  getRegistrationsByCompanyClientId(
    reqParam: GetRegistrationsByCompanyClientIdRequest
  ): Promise<GetRegistrationsResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: DashboardApi = new DashboardApi(config);

    const params: GetRegistrationsByCompanyClientIdRequest = reqParam;

    return createApi.getRegistrationsByCompanyClientId(params);
  },
  //
  getNumberOfShifts(): Promise<NumberOfCompanyClientShiftsResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    return createApi.numberOfShifts();
  },
  //
  // for Registration overview mostly
  getRegistrationsByCompanyClientIdOverview(
    reqParam: RegistrationsByCompanyClientIdRequest
  ): Promise<RegistrationsForCompanyClientResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: RegistrationsApi = new RegistrationsApi(config);

    const params: RegistrationsByCompanyClientIdRequest = reqParam;

    return createApi.registrationsByCompanyClientId(params);
  },
  //
  createShiftRequest(reqParam: ShiftRequestRequest): Promise<ShiftRequestResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    const params: ShiftRequestRequest = reqParam;

    return createApi.shiftRequest(params)
  },
  //
  getCompanyClientShiftDetails(reqParam: CompanyClientShiftDetailsRequest): Promise<ShiftResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    const params: CompanyClientShiftDetailsRequest = reqParam;

    return createApi.companyClientShiftDetails(params)
  },
  //
  getCompanyClientEmployees(reqParam: EmployeesOfCompanyClientRequest): Promise<EmployeesOfCompanyClientResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: EmployeesOfCompanyClientRequest = reqParam;

    return createApi.employeesOfCompanyClient(params)
  },
  //
  companyClientEmployee(reqParam: EmployeeOfCompanyClientRequest): Promise<Employees> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: EmployeeOfCompanyClientRequest = reqParam;

    return createApi.employeeOfCompanyClient(params);
  },
  //
  favouriteEmployee(reqParam: EmployeeFavouriteOperationRequest): Promise<EmployeeFavouriteResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: EmployeeFavouriteOperationRequest = reqParam;

    return createApi.employeeFavourite(params)
  },
  //
  approveBulkRegistrations(reqParam: ApproveBulkRegistrationsRequest): Promise<BulkRegistrationLinesResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: RegistrationsApi = new RegistrationsApi(config);

    const params: ApproveBulkRegistrationsRequest = reqParam;

    return createApi.approveBulkRegistrations(params)
  },
  //
  getCompanyClientList(reqParam: GetForUserRequest): Promise<CompanyClientsOfUserResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: CompaniesClientsApi = new CompaniesClientsApi(config);

    const params = reqParam;

    return createApi.getForUser(params);
  },
  //
  getCompanyClient(reqParam: number): Promise<CompanyClientOfUserOptionalResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: CompaniesClientsApi = new CompaniesClientsApi(config);

    const params = {
      companyClientId: reqParam
    } as GetForUserOfClientRequest;

    return createApi.getForUserOfClient(params);
  },
  //
  getEmployeeReviewOrPrepareTemplate(reqParam: GetEmployeeReviewOrPrepareTemplateRequest):
    Promise<CompanyClientEmployeeReviewResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: GetEmployeeReviewOrPrepareTemplateRequest = reqParam;

    return createApi.getEmployeeReviewOrPrepareTemplate(params)
  },
  //
  getEmployeeReviewLabels(): Promise<Array<CompanyClientReviewLabelResponse>> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    return createApi.getEmployeeReviewLabels();
  },
  //
  getEmployeeReviewCount(reqParam: GetEmployeeReviewCountRequest):
    Promise<CompanyClientEmployeeReviewCount> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: GetEmployeeReviewCountRequest = reqParam;

    return createApi.getEmployeeReviewCount(params);
  },
  //
  getEmployeeReviewOverviewScore(reqParam: GetEmployeeReviewOverviewScoreRequest):
    Promise<CompanyClientReviewScoreSummaryResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: GetEmployeeReviewOverviewScoreRequest = reqParam;

    return createApi.getEmployeeReviewOverviewScore(params);
  },
  //
  getEmployeeReviewOverview(reqParam: GetEmployeeReviewOverviewRequest):
    Promise<CompanyClientReviewOnShiftResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: GetEmployeeReviewOverviewRequest = reqParam;

    return createApi.getEmployeeReviewOverview(params);
  },
  //
  createEmployeeReview(reqParam: CompanyClientEmployeeReviewCreateRequest):
    Promise<CompanyClientEmployeeReviewResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: CreateEmployeeReviewRequest = {
      companyClientEmployeeReviewCreateRequest: reqParam
    };

    return createApi.createEmployeeReview(params);
  },
  //
  updateEmployeeReview(reqParam: CompanyClientEmployeeReviewUpdateRequest):
    Promise<CompanyClientEmployeeSimpleReviewResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: UpdateEmployeeReviewRequest = {
      companyClientEmployeeReviewUpdateRequest: reqParam
    };

    return createApi.updateEmployeeReview(params);
  },
  //
  editRegistrationForCompanyClientEmployee(reqParams: EditRegistrationForCompanyClientEmployeeRequest):
    Promise<EditShiftRegistrationDto> {
    const config = restConfigService.getBaseConfig();

    const createApi: RegistrationsApi = new RegistrationsApi(config);

    const params: EditRegistrationForCompanyClientEmployeeRequest = reqParams;

    return createApi.editRegistrationForCompanyClientEmployee(params);
  },
  //
  getEmployeeReviewOverviews(reqParams: GetEmployeeReviewOverviewsRequest):
    Promise<EmployeeOnShiftResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: EmployeesApi = new EmployeesApi(config);

    const params: GetEmployeeReviewOverviewsRequest = reqParams;

    return createApi.getEmployeeReviewOverviews(params);
  },
  //
  getCompanyClientShiftApplicants(reqParam: CompanyClientShiftApplicantsRequest): Promise<ShiftApplicantsResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    const params: CompanyClientShiftApplicantsRequest = reqParam;

    return createApi.companyClientShiftApplicants(params);
  },
  //
  getCompanyClientRecentShiftDetails(): Promise<ShiftMostRecentResponse> {
    const config = restConfigService.getBaseConfig();

    const createApi: ShiftsApi = new ShiftsApi(config);

    return createApi.companyClientRecentShiftDetails();
  }
};
