import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe0c97ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-filter" }
const _hoisted_2 = { class: "flex-filter__title" }
const _hoisted_3 = { class: "flex-filter__content" }
const _hoisted_4 = { class: "flex-filter__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_FormRow = _resolveComponent("FormRow")!
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayAside = _resolveComponent("OverlayAside")!

  return (_openBlock(), _createBlock(_component_OverlayAside, {
    "modal-id": _ctx.asideId,
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeFilter
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('global.filter')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_CheckBox, {
                label: _ctx.$t('flexpool.show_only_favourites'),
                control: _ctx.favourite
              }, null, 8, ["label", "control"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            size: "medium",
            color: "outlined",
            onClick: _ctx.clearSelection
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('global.clear_selection')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            size: "medium",
            color: "primary",
            onClick: _ctx.showResults
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('global.show_results')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-id", "is-open", "onClose"]))
}