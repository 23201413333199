/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyClientReviewLabelResponse
 */
export interface CompanyClientReviewLabelResponse {
    /**
     * Label ID
     * @type {number}
     * @memberof CompanyClientReviewLabelResponse
     */
    labelId: number;
    /**
     * A skill the employee is good at or can improve on
     * @type {string}
     * @memberof CompanyClientReviewLabelResponse
     */
    description: string;
}

export function CompanyClientReviewLabelResponseFromJSON(json: any): CompanyClientReviewLabelResponse {
    return CompanyClientReviewLabelResponseFromJSONTyped(json, false);
}

export function CompanyClientReviewLabelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientReviewLabelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelId': json['labelId'],
        'description': json['description'],
    };
}

export function CompanyClientReviewLabelResponseToJSON(value?: CompanyClientReviewLabelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labelId': value.labelId,
        'description': value.description,
    };
}

