<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      transform="translate(-508 -761)"
      fill="#1F1F1F"
      fill-rule="evenodd"
      font-family="AppleColorEmoji, Apple Color Emoji"
      font-size="14"
    >
      <tspan x="508" y="776">
        🕒
      </tspan>
    </text>
  </svg>
</template>
