import { getCookie } from "typescript-cookie";
import { CookieProps } from "@/types/global";
import { Configuration, ResponseContext } from "@/../rpc-api-source/runtime";
/* eslint-disable*/

function getBaseConfig(): Configuration {
  const token = getCookie(CookieProps.UserToken) as string;
  const logoutUrl = getCookie(CookieProps.LogoutUri) as string;
  const clientId = getCookie(CookieProps.ClientId) as string;

  return new Configuration({
    basePath: `${window.location.origin}/rpc`,
    accessToken: `Bearer ${token}`,
    apiKey: clientId,
    middleware: [
      {
        post: async (context: ResponseContext) => {
          if (
            /* eslint-disable-next-line */
            context.response.status === 401
          ) {
            window.location.href = logoutUrl;
          }

          if (
            /* eslint-disable-next-line */
            context.response.status === 403
          ) {
            window.location.href = window.location.origin;
          }

          return context.response;
        }
      },
    ],
  });
}

export default {
  getBaseConfig,
};
