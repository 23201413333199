import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d18e957e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dash-todo" }
const _hoisted_2 = { class: "dash-todo__cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgCalendar = _resolveComponent("SvgCalendar")!
  const _component_CardTodo = _resolveComponent("CardTodo")!
  const _component_SvgPerson = _resolveComponent("SvgPerson")!
  const _component_SvgPenlined = _resolveComponent("SvgPenlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.checkPermissions('bookkeeper'))
        ? (_openBlock(), _createBlock(_component_CardTodo, {
            key: 0,
            item: _ctx.todo?.shiftsPlanned,
            desc: _ctx.$t('shifts_planed', _ctx.todo?.shiftsPlanned as number),
            color: "orange"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_SvgCalendar, { class: "svg--orange" })
            ]),
            _: 1
          }, 8, ["item", "desc"]))
        : _createCommentVNode("", true),
      (!_ctx.checkPermissions('bookkeeper'))
        ? (_openBlock(), _createBlock(_component_CardTodo, {
            key: 1,
            item: _ctx.todo?.applicantsToApprove,
            desc: _ctx.$t('applicants_to_approve', _ctx.todo?.applicantsToApprove as number),
            color: "green"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_SvgPerson, { class: "svg--green" })
            ]),
            _: 1
          }, 8, ["item", "desc"]))
        : _createCommentVNode("", true),
      (!_ctx.checkPermissions('planner') && !_ctx.checkPermissions('floormanager'))
        ? (_openBlock(), _createBlock(_component_CardTodo, {
            key: 2,
            item: _ctx.todo?.registrationsToApprove,
            desc: _ctx.$t('registrations_to_approve', _ctx.todo?.registrationsToApprove as number),
            color: "purple"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_SvgPenlined, { class: "svg--purple" })
            ]),
            _: 1
          }, 8, ["item", "desc"]))
        : _createCommentVNode("", true)
    ])
  ]))
}