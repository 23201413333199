/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewCount
 */
export interface CompanyClientEmployeeReviewCount {
    /**
     * Total number of results
     * @type {number}
     * @memberof CompanyClientEmployeeReviewCount
     */
    totalElements: number;
}

export function CompanyClientEmployeeReviewCountFromJSON(json: any): CompanyClientEmployeeReviewCount {
    return CompanyClientEmployeeReviewCountFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalElements': json['totalElements'],
    };
}

export function CompanyClientEmployeeReviewCountToJSON(value?: CompanyClientEmployeeReviewCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalElements': value.totalElements,
    };
}

