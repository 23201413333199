import { removeCookie } from "typescript-cookie";
import { CookieProps } from "@/types/global";

/* eslint-disable-next-line */
export function randomBgProvider(id: number, numOfBgClasses = 4): string {
  // Used for random generation BG instead of placeholder images
  return `placeholder__bg-${(id % numOfBgClasses) + 1}`;
}

export function debounce<T>(fn: T, wait: number) {
  let timer: ReturnType<typeof setTimeout>;
  return (event: Event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (typeof fn === "function") {
        fn(event);
      }
    }, wait);
  };
}

/* eslint-disable-next-line */
export function logoutClearCookie() {
  removeCookie(CookieProps.UserToken);
  removeCookie(CookieProps.LogoutUri);
  removeCookie(CookieProps.ClientId);
  removeCookie(CookieProps.RealmName);
}