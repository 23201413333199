import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShiftDetailsSidebar = _resolveComponent("ShiftDetailsSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CardWrap = _resolveComponent("CardWrap")!

  return (_openBlock(), _createBlock(_component_CardWrap, { class: "card-wrap--height card-wrap--order card-wrap--padding-fix" }, {
    default: _withCtx(() => [
      _createVNode(_component_ShiftDetailsSidebar, {
        "sidebar-items": _ctx.customUpcomingShifts,
        "is-loading": _ctx.isLoading,
        "per-page-skeleton": _ctx.perPageSkeleton,
        onScrollPosition: _ctx.handleScrollPosition
      }, null, 8, ["sidebar-items", "is-loading", "per-page-skeleton", "onScrollPosition"]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}