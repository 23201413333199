
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ToastService from "mq-ui-kit/lib/toast";
import { Icon } from "mq-ui-kit/lib/Icon";
import {
  RegistrationsByCompanyClientIdRequest,
  RegistrationsForCompanyClientRequestFiltersStatusEnum,
  ApproveBulkRegistrationsRequest,
  RegistrationsForCompanyClientRequestFilters,
  ShiftRegistrationDto,
  StatusEnum,
  RegistrationClass,
  RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum,
} from "@/services/rest/dashboard";
import ActionTypes from "@/store/types/action-types";
import TabFilter from "@/components/ui/TabFilter.vue";
import DataTable from "@/components/ui/DataTable/DataTable.vue";
import NoRecord from "@/components/ui/DataTable/NoRecord/NoRecord.vue";
import ListItemDate from "@/components/ui/ListItemDate.vue";
import Tag from "@/components/ui/Tag.vue";
import Avatar from "@/components/ui/Avatar.vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import SelectAll from "@/components/ui/SelectAll.vue";
import Spinner from "@/components/ui/Spinner.vue";
import Dropdown from "@/components/ui/Dropdown/Dropdown.vue";
import DropdownItem from "@/components/ui/Dropdown/DropdownItem.vue";
import ProcessRegistrationLinesBar from "@/components/ui/ProcessRegistrationLinesBar.vue";
import RegistrationListItemCheckbox from "@/components/ui/RegistrationListItemCheckbox.vue";
import EditRegistrationLineModal from "@/components/ui/EditRegistrationLineModal.vue";

interface selectedTimeLineForCheckbox {
  isChecked?: boolean;
  registrationId: number;
  registrationClass: RegistrationClass;
}

interface TabItemRegLine {
  name: string;
  type: RegistrationsForCompanyClientRequestFiltersStatusEnum[];
  numOfItems: number;
}

export default defineComponent({
  name: "RegistrationsOverview",
  components: {
    TabFilter,
    DataTable,
    ListItemDate,
    Tag,
    Avatar,
    CardWrap,
    NoRecord,
    RegistrationListItemCheckbox,
    ProcessRegistrationLinesBar,
    SelectAll,
    Spinner,
    Dropdown,
    DropdownItem,
    Icon,
    EditRegistrationLineModal
  },
  props: {
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const componentKey = ref(0);

    const dashboardShown = computed(() => store.state.isDashboardShown);

    const selectedTabType = ref<RegistrationsForCompanyClientRequestFiltersStatusEnum[]>([
      RegistrationsForCompanyClientRequestFiltersStatusEnum.ForApproval,
      RegistrationsForCompanyClientRequestFiltersStatusEnum.CompanyRegistered,
    ]);

    const wageFilter = ref<RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum[]>([
      RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum.RegularHours,
      RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum.SurchargeHours,
      RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum.OvertimeHours,
      // RegistrationsForCompanyClientRequestFiltersWageCompTypesEnum.LeaveAbsence
      // TODO: leave absence will be visible in next release
    ]);

    const selectAllIsChecked = ref(false);
    const registrationList = ref<ShiftRegistrationDto[]>([]);
    const timeRegistrationLines = ref<number[]>([]);
    const shiftOnlyRegistrationLines = ref<number[]>([]);
    const freelanceRegistrationLines = ref<number[]>([]);
    const selectedRegistration = ref<ShiftRegistrationDto>();

    const pageIndex = ref(0);
    const perPage = ref(15);
    const totalPages = ref(0);
    const totalElements = ref(0);

    const memo = ref<string[]>([]);

    const isLoading = ref(false);

    const isModalOpen = ref(false);

    const isPageFirstLoad = ref(false);

    const regColumns = ref([
      {
        header: "",
        id: "logoLink",
        value: "logoLink",
        width: "60px",
      },
      {
        header: t("global.name"),
        id: "name",
        value: "employee_full_name",
        sortable: true,
      },
      {
        header: t("global.type"),
        id: "registrationType",
        value: "registrationType",
      },
      {
        header: t("global.shift"),
        id: "shift",
        value: "shift_name",
        sortable: true,
      },
      {
        header: t("global.date"),
        id: "date",
        value: "start_timestamp",
        width: "13%",
        sortable: true,
      },
      {
        header: t("global.total"),
        id: "total",
        value: "total",
        width: "13%",
      },
      {
        header: t("global.status"),
        id: "status",
        value: "status",
        width: "150px",
      },
    ]);

    const checkIfRegistrationIdExistsInList = (
      registrationId: number,
      // eslint-disable-next-line
      registrationClass: string
    ) => {
      switch (registrationClass) {
        case "FREELANCE_REGISTRATION":
          return freelanceRegistrationLines.value.includes(registrationId);
        case "SHIFTS_ONLY_REGISTRATION":
          return shiftOnlyRegistrationLines.value.includes(registrationId);
        case "TIME_REGISTRATION":
        default:
          return timeRegistrationLines.value.includes(registrationId);
      }
    }

    const tabItems = ref<TabItemRegLine[]>([
      {
        name: t("global.to_do"),
        type: [
          RegistrationsForCompanyClientRequestFiltersStatusEnum.ForApproval,
          RegistrationsForCompanyClientRequestFiltersStatusEnum.CompanyRegistered,
        ],
        numOfItems: 0,
      },
      {
        name: t("global.handled"),
        type: [
          RegistrationsForCompanyClientRequestFiltersStatusEnum.Approved,
          RegistrationsForCompanyClientRequestFiltersStatusEnum.Rejected,
        ],
        numOfItems: 0,
      },
      {
        name: t("global.all"),
        type: Object.values(RegistrationsForCompanyClientRequestFiltersStatusEnum),
        numOfItems: 0,
      },
    ]);

    const customMsg = (status: string) => {
      const values: { [key in StatusEnum]?: string } = {
        [StatusEnum.ForApproval]: t("global.pending"),
        [StatusEnum.Approved]: t("global.processed"),
        [StatusEnum.Rejected]: t("global.declined"),
        [StatusEnum.CompanyRegistered]: t("global.pending"),
      };

      return values[status as StatusEnum];
    };

    const formatTotalTime = (hours: string): string => {
      return hours.split(":", 2).join(":");
    };

    // TODO: calc height of viewport to hack top to bottom
    // const tableHeight = () => {
    //   const vh = window.innerHeight;
    //   const table = document?.querySelector(".reg-over__table-wrap") as any;

    //   const height = vh - table.getBoundingClientRect().top - 64;
    //   table.style.height = `${height}px`;
    // };

    const selectedRegistrationsCount = computed(
      () =>
        timeRegistrationLines.value.length
          + shiftOnlyRegistrationLines.value.length
          + freelanceRegistrationLines.value.length
    );

    const deSelectAllRegistrationLines = () => {
      timeRegistrationLines.value = [];
      shiftOnlyRegistrationLines.value = [];
      freelanceRegistrationLines.value = [];
    };

    const getRegistrationsOverviewByStatus = (
      type: RegistrationsForCompanyClientRequestFiltersStatusEnum[],
      sortParam?: string[]
    ) => {

      const params: RegistrationsByCompanyClientIdRequest = {
        size: perPage.value,
        page: pageIndex.value,
        registrationsForCompanyClientRequest: {
          filters: {
            status: [...type],
            wageCompTypes: wageFilter.value
          } as RegistrationsForCompanyClientRequestFilters,
        },
        ...(sortParam?.length && { sort: sortParam })
      };

      isLoading.value = true;

      store
        .dispatch(ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW, params)
        .then((res) => {
          totalPages.value = res.page.totalPages;
          totalElements.value = res.page.totalElements;
          perPage.value = res.page.size;

          if(isPageFirstLoad.value) {
            registrationList.value = res.registrations;
            isPageFirstLoad.value = false;

            // const scrollEl = document.querySelector(".reg-over .table-body");

            // scrollEl?.scrollTo({
            //   top: 0,
            // });

            return;
          }

          registrationList.value.push(...res.registrations);
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const formatSortData = (columnName: string, sort: string) => `${columnName},${sort}`;

    /* eslint-disable-next-line */
    const onHeaderItemClick = (item: any) => {
      if (!memo.value.length && item.sortOrder) {
        memo.value.push(formatSortData(item.item.value, item.sortOrder));
      } else {
        memo.value = memo.value.filter((el) => el.split(",")[0] !== item.item.value);

        if (item.sortOrder)
          memo.value.push(formatSortData(item.item.value, item.sortOrder));
      }

      pageIndex.value = 0;
      registrationList.value = [];
      getRegistrationsOverviewByStatus([...selectedTabType.value], memo.value);
    };

    const forceRerender = () => {
      componentKey.value += 1;
    };

    const tabClicked = (index: number) => {
      pageIndex.value = 0;
      selectedTabType.value = tabItems.value[index].type;
      registrationList.value = [];

      selectAllIsChecked.value = false;
      deSelectAllRegistrationLines();

      forceRerender();

      memo.value = [];

      getRegistrationsOverviewByStatus([...selectedTabType.value], memo.value);
    };

    const removeRegistrationFromArray = (
      array: Array<number>,
      registrationId: number
    ) => {
      const index = array.indexOf(registrationId);
      if (index > -1) {
        array.splice(index, 1);
      }
    };

    const removeRegistrationIdFromArray = (
        registrationLine: selectedTimeLineForCheckbox
    ) => {
      switch (registrationLine.registrationClass) {
        case "FREELANCE_REGISTRATION":
          removeRegistrationFromArray(freelanceRegistrationLines.value, registrationLine.registrationId)
          break;
        case "SHIFTS_ONLY_REGISTRATION":
          removeRegistrationFromArray(shiftOnlyRegistrationLines.value, registrationLine.registrationId)
          break;
        case "TIME_REGISTRATION":
        default:
          removeRegistrationFromArray(timeRegistrationLines.value, registrationLine.registrationId)
      }
    };

    const sortRegistrationLine = (
      registrationLine: selectedTimeLineForCheckbox
    ) => {
      switch (registrationLine.registrationClass) {
        case "FREELANCE_REGISTRATION":
          freelanceRegistrationLines.value.push(registrationLine.registrationId);
          break;
        case "SHIFTS_ONLY_REGISTRATION":
          shiftOnlyRegistrationLines.value.push(registrationLine.registrationId);
          break;
        case "TIME_REGISTRATION":
        default:
          timeRegistrationLines.value.push(registrationLine.registrationId);
      }
    }

    const onChange = (registrationLine: selectedTimeLineForCheckbox) => {
      if (registrationLine.isChecked) {
        sortRegistrationLine(registrationLine);
        return;
      }

      removeRegistrationIdFromArray(registrationLine)
    };

    const onScrollReachedBottom = () => {
      if (pageIndex.value >= totalPages.value - 1) return;

      pageIndex.value += 1;
      getRegistrationsOverviewByStatus([...selectedTabType.value], memo.value);
    };

    const selectAllRegistrationLines = () => {
      registrationList.value.forEach((registrationLine) => {
        if (
          !checkIfRegistrationIdExistsInList(
            registrationLine.registrationId,
            registrationLine.registrationClass
          )
        ) {
          sortRegistrationLine({
            registrationId: registrationLine.registrationId,
            registrationClass: registrationLine.registrationClass,
          } as selectedTimeLineForCheckbox);
        }
      });
    };

    const onSelectAllChange = (value: boolean) => {
      if (value) {
        selectAllIsChecked.value = true;
        selectAllRegistrationLines();
      } else {
        selectAllIsChecked.value = false;
        deSelectAllRegistrationLines();
      }
    };

    const addCheckboxColumn = () => {
      const checkboxColumn = {
        header: "",
        id: "selectLine",
        value: "selectLine",
        width: "60px",
      };

      regColumns.value.unshift(checkboxColumn);
    };

    const addActionMenu = () => {
      const menu =  {
        header: "",
        id: "menu",
        value: "menu",
        width: "100px",
        rowAlign: "right"
      };

      regColumns.value.push(menu);
    }

    const hideSelector = computed(() =>
    selectedTabType.value[0] !== RegistrationsForCompanyClientRequestFiltersStatusEnum.ForApproval
      || selectedTabType.value[1] !== RegistrationsForCompanyClientRequestFiltersStatusEnum.CompanyRegistered);

    const editRegistration = (item: ShiftRegistrationDto) => {
      selectedRegistration.value = item;
      isModalOpen.value = true;
    }

    const closeModal = () => {
      isModalOpen.value = false;
      isPageFirstLoad.value = true;
      pageIndex.value = 0;

      getRegistrationsOverviewByStatus([...selectedTabType.value], memo.value);
    }

    const cancelDialog = () => {
      isModalOpen.value = false;
    }

    onMounted(() => {
      addCheckboxColumn();
      addActionMenu();

      getRegistrationsOverviewByStatus([...selectedTabType.value], memo.value);
    });

    watch(hideSelector, (value) => {
      if (value) {
        regColumns.value.splice(regColumns.value.findIndex((i) => i.id === "selectLine"), 1);

        regColumns.value.splice(regColumns.value.findIndex((i) => i.id === "menu"), 1);
      } else {
        addCheckboxColumn();
        addActionMenu();
      }
    });

    const approveRegistrationLines = async () => {
      isLoading.value = true;

      const params = {
        bulkRegistrationLinesRequest: {
          timeRegistrationLines: timeRegistrationLines.value,
          freelanceRegistrationLines: freelanceRegistrationLines.value,
          shiftOnlyRegistrationLines: shiftOnlyRegistrationLines.value,
        },
      } as ApproveBulkRegistrationsRequest;

      const parameters: RegistrationsByCompanyClientIdRequest = {
        size: perPage.value,
        page: pageIndex.value,
        registrationsForCompanyClientRequest: {
          filters: {
            status: [
              RegistrationsForCompanyClientRequestFiltersStatusEnum.ForApproval,
              RegistrationsForCompanyClientRequestFiltersStatusEnum.CompanyRegistered
            ],
            wageCompTypes: wageFilter.value
          } as RegistrationsForCompanyClientRequestFilters,
        },
      };

      try {
        const resApprove = await store.dispatch(ActionTypes.APPROVE_REGISTRATION_LINE, params);

        if (!resApprove.approved) {
          ToastService.errorToast(t("errors.something_went_wrong"));
        } else {
          ToastService.successToast(t("global.approved"));
          registrationList.value = [];
        }

        const regOverview = await
        store.dispatch(ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW, parameters);

        registrationList.value.push(...regOverview.registrations);

        totalPages.value = regOverview.page.totalPages;
        totalElements.value = regOverview.page.totalElements;
        perPage.value = regOverview.page.size;

      } catch (err) {
        console.log("Error:", err);
        ToastService.errorToast(t("errors.something_went_wrong"));
      } finally {
        isLoading.value = false;
        selectAllIsChecked.value = false;
        deSelectAllRegistrationLines();
      }
    };

    return {
      dashboardShown,
      tabItems,
      tabClicked,
      regColumns,
      registrationList,
      customMsg,
      onScrollReachedBottom,
      onHeaderItemClick,
      // tableHeight,
      isLoading,
      totalElements,
      onSelectAllChange,
      checkIfRegistrationIdExistsInList,
      onChange,
      selectedRegistrationsCount,
      selectAllIsChecked,
      selectedTabType,
      hideSelector,
      approveRegistrationLines,
      editRegistration,
      isModalOpen,
      closeModal,
      cancelDialog,
      selectedRegistration,
      formatTotalTime,
      componentKey
    };
  },
});
