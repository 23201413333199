
import { defineComponent, computed } from "vue";
import { Icon } from "mq-ui-kit/lib/Icon";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ProcessRegistrationLinesBar",

  components: {
    Icon,
  },

  props: {
    itemCount: {
      type: Number,
      required: true,
    }
  },

  emits: ["approve"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const approveLines = () => {
      emit("approve");
    };

    const itemCountText = computed(() =>
      t("global.items_selected", { n: props.itemCount })
    );

    return {
      approveLines,
      itemCountText,
      t,
    };
  },
});
