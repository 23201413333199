/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientReviewOnShift,
    CompanyClientReviewOnShiftFromJSON,
    CompanyClientReviewOnShiftFromJSONTyped,
    CompanyClientReviewOnShiftToJSON,
} from './CompanyClientReviewOnShift';

/**
 * 
 * @export
 * @interface CompanyClientReviewOnShiftResponse
 */
export interface CompanyClientReviewOnShiftResponse {
    /**
     * Total number of results
     * @type {number}
     * @memberof CompanyClientReviewOnShiftResponse
     */
    totalElements: number;
    /**
     * 
     * @type {Array<CompanyClientReviewOnShift>}
     * @memberof CompanyClientReviewOnShiftResponse
     */
    resultList: Array<CompanyClientReviewOnShift>;
}

export function CompanyClientReviewOnShiftResponseFromJSON(json: any): CompanyClientReviewOnShiftResponse {
    return CompanyClientReviewOnShiftResponseFromJSONTyped(json, false);
}

export function CompanyClientReviewOnShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientReviewOnShiftResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalElements': json['totalElements'],
        'resultList': ((json['resultList'] as Array<any>).map(CompanyClientReviewOnShiftFromJSON)),
    };
}

export function CompanyClientReviewOnShiftResponseToJSON(value?: CompanyClientReviewOnShiftResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalElements': value.totalElements,
        'resultList': ((value.resultList as Array<any>).map(CompanyClientReviewOnShiftToJSON)),
    };
}

