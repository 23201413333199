import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c4ac1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "reviews-over"
}
const _hoisted_2 = { class: "reviews-over__header" }
const _hoisted_3 = { class: "reviews-over__title" }
const _hoisted_4 = { class: "user-info" }
const _hoisted_5 = { class: "user-info__name" }
const _hoisted_6 = {
  key: 0,
  class: "user-info__function ellipsis"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabFilter = _resolveComponent("TabFilter")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_NoRecord = _resolveComponent("NoRecord")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_ListItemDate = _resolveComponent("ListItemDate")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CardWrap = _resolveComponent("CardWrap")!
  const _component_EmployeeReviewModal = _resolveComponent("EmployeeReviewModal")!

  return (_ctx.dashboardShown && _ctx.$store.state.selectedClient)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("routes.reviews_overview")), 1)
        ]),
        _createVNode(_component_TabFilter, {
          items: _ctx.tabItems,
          onClick: _ctx.tabClicked
        }, null, 8, ["items", "onClick"]),
        _createVNode(_component_CardWrap, { class: "reviews-over__table-wrap" }, {
          default: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_Spinner, {
                  key: 0,
                  spinning: _ctx.isLoading,
                  inline: true
                }, null, 8, ["spinning"]))
              : _createCommentVNode("", true),
            (!_ctx.reviewsList.length && !_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_NoRecord, { key: 1 }))
              : (_openBlock(), _createBlock(_component_DataTable, {
                  key: _ctx.componentKey,
                  class: "reviews-over__table",
                  columns: _ctx.reviewsColumns,
                  items: _ctx.reviewsList,
                  "item-logo-link": "logoLink",
                  "item-select-line": "selectLine",
                  "item-name": "name",
                  "item-shift": "shift",
                  "item-date": "date",
                  "item-total": "total",
                  onScrollReachedBottom: _ctx.onScrollReachedBottom,
                  onHeaderItemClick: _ctx.onHeaderItemClick,
                  "total-elements": _ctx.totalElements
                }, _createSlots({
                  item_logoLink: _withCtx(({ item }) => [
                    _createVNode(_component_Avatar, {
                      name: item.employee.firstName,
                      "last-name": item.employee.lastName,
                      "img-path": item.employee.profilePictureUrl
                    }, null, 8, ["name", "last-name", "img-path"])
                  ]),
                  item_name: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(item.employee.fullName), 1),
                      (item.functionName)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.functionName), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  item_shift: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.gigName), 1)
                  ]),
                  item_date: _withCtx(({ item }) => [
                    _createVNode(_component_ListItemDate, {
                      "start-date": item.shiftStartTimestamp,
                      "start-time": item.shiftStartTimestamp.toString(),
                      "end-time": item.shiftEndTimestamp.toString()
                    }, null, 8, ["start-date", "start-time", "end-time"])
                  ]),
                  _: 2
                }, [
                  (!_ctx.hideSelector)
                    ? {
                        name: "item_menu",
                        fn: _withCtx(({item }) => [
                          _createVNode(_component_Dropdown, { class: "tab-general__dropdown" }, {
                            "dropdown-menu": _withCtx(() => [
                              _createVNode(_component_DropdownItem, {
                                onClick: ($event: any) => (_ctx.openReview(item.employee, item.shiftId))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.selectedTabType === 'FOR_REVIEW')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('add_review')), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('edit_review')), 1))
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, { icon: "ellipsis" })
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["columns", "items", "onScrollReachedBottom", "onHeaderItemClick", "total-elements"]))
          ]),
          _: 1
        }),
        _createVNode(_component_EmployeeReviewModal, {
          member: _ctx.singleMemberShift,
          onSave: _ctx.updateList
        }, null, 8, ["member", "onSave"])
      ]))
    : _createCommentVNode("", true)
}