import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bce07f98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "req-shift__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestShiftForm = _resolveComponent("RequestShiftForm")!
  const _component_OverlayDialog = _resolveComponent("OverlayDialog")!

  return (_openBlock(), _createBlock(_component_OverlayDialog, {
    "modal-id": "request-shift-form",
    "is-open": _ctx.isModalOpen,
    onClose: _ctx.closeDialog,
    "disable-close": _ctx.isUploadingShiftReq
  }, {
    header: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("shift_request.main_title")), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_RequestShiftForm, {
        onCloseDialog: _ctx.closeDialog,
        ref: "reqShiftFormComp"
      }, null, 8, ["onCloseDialog"])
    ]),
    _: 1
  }, 8, ["is-open", "onClose", "disable-close"]))
}