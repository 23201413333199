
import { defineComponent } from "vue";

export default defineComponent({
  /*eslint-disable*/
  name: "Breadcrumbs",
  data() {
    return {
      breadcrumbList: [] as any,
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo: number) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumbs;
    },
  },
});
