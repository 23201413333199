import { ActionContext, ActionTree } from "vuex";
import ActionTypes from "@/store/types/action-types";
import { State } from "@/store/state";
//
import dashboardApi, {
  UpcomingShiftsRequest,
  UpcomingShiftResponse,
  RequiredActionsResponse,
  CompanyClientShiftsRequest,
  CompanyClientShiftsResponse,
  GetRegistrationsByCompanyClientIdRequest,
  GetRegistrationsResponse,
  NumberOfCompanyClientShiftsResponse,
  RegistrationsByCompanyClientIdRequest,
  RegistrationsForCompanyClientResponse,
  ShiftRequestRequest,
  ShiftRequestResponse,
  CompanyClientShiftDetailsRequest,
  ShiftMostRecentResponse,
  ShiftResponse,
  EmployeesOfCompanyClientRequest,
  EmployeesOfCompanyClientResponse,
  EmployeeFavouriteOperationRequest,
  EmployeeFavouriteResponse,
  ApproveBulkRegistrationsRequest,
  BulkRegistrationLinesResponse,
  CompanyClientsOfUserResponse,
  EmployeeOfCompanyClientRequest,
  Employees,
  GetEmployeeReviewOrPrepareTemplateRequest,
  CompanyClientEmployeeReviewResponse,
  CompanyClientEmployeeSimpleReviewResponse,
  CompanyClientReviewLabelResponse,
  CompanyClientEmployeeReviewCreateRequest,
  CompanyClientEmployeeReviewUpdateRequest,
  EditRegistrationForCompanyClientEmployeeRequest,
  EditShiftRegistrationDto,
  GetEmployeeReviewOverviewsRequest,
  EmployeeOnShiftResponse,
  GetEmployeeReviewCountRequest,
  CompanyClientEmployeeReviewCount,
  GetEmployeeReviewOverviewScoreRequest,
  CompanyClientReviewScoreSummaryResponse,
  GetEmployeeReviewOverviewRequest,
  CompanyClientReviewOnShiftResponse,
  CompanyClientShiftApplicantsRequest,
  GetForUserRequest,
  CompanyClientOfUserOptionalResponse,
  ShiftApplicantsResponse,
} from "@/services/rest/dashboard";
//
import { Mutations } from "./mutations";
//

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [ActionTypes.GET_UPCOMING_SHIFTS](
    { commit }: AugmentedActionContext,
    payload: UpcomingShiftsRequest
  ): Promise<Array<UpcomingShiftResponse>>;
  //
  [ActionTypes.GET_REQUIRED_ACTIONS](
    { commit }: AugmentedActionContext
  ): Promise<RequiredActionsResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENTS_SHIFTS](
    { commit }: AugmentedActionContext,
    payload: CompanyClientShiftsRequest
  ): Promise<CompanyClientShiftsResponse>;
  //
  [ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID](
    { commit }: AugmentedActionContext,
    payload: GetRegistrationsByCompanyClientIdRequest
  ): Promise<GetRegistrationsResponse>;
  //
  [ActionTypes.NUMBER_OF_SHIFTS](
    { commit }: AugmentedActionContext
  ): Promise<NumberOfCompanyClientShiftsResponse>;
  //
  [ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW](
    { commit }: AugmentedActionContext,
    payload: RegistrationsByCompanyClientIdRequest
  ): Promise<RegistrationsForCompanyClientResponse>;
  //
  [ActionTypes.CREATE_SHIFT_REQUEST](
    { commit }: AugmentedActionContext,
    payload: ShiftRequestRequest
  ): Promise<ShiftRequestResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_SHIFT_DETAILS](
    { commit }: AugmentedActionContext,
    payload: CompanyClientShiftDetailsRequest
  ): Promise<ShiftResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_EMPLOYEES](
    { commit }: AugmentedActionContext,
    payload: EmployeesOfCompanyClientRequest
  ): Promise<EmployeesOfCompanyClientResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_EMPLOYEE](
    { commit }: AugmentedActionContext,
    payload: EmployeeOfCompanyClientRequest
  ): Promise<Employees>;
  //
  [ActionTypes.FAVOURITE_EMPLOYEE](
    { commit }: AugmentedActionContext,
    payload: EmployeeFavouriteOperationRequest
  ): Promise<EmployeeFavouriteResponse>;
  //
  [ActionTypes.APPROVE_REGISTRATION_LINE](
    { commit }: AugmentedActionContext,
    payload: ApproveBulkRegistrationsRequest
  ): Promise<BulkRegistrationLinesResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_LIST](
    { commit }: AugmentedActionContext,
    payload: GetForUserRequest
  ): Promise<CompanyClientsOfUserResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<CompanyClientOfUserOptionalResponse>;
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OR_PREPARE_TEMPLATE](
    { commit }: AugmentedActionContext,
    payload: GetEmployeeReviewOrPrepareTemplateRequest
  ): Promise<CompanyClientEmployeeReviewResponse>;
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_LABELS](
    { commit }: AugmentedActionContext,
  ): Promise<Array<CompanyClientReviewLabelResponse>>;
  //
  [ActionTypes.CREATE_EMPLOYEE_REVIEW](
    { commit }: AugmentedActionContext,
    payload: CompanyClientEmployeeReviewCreateRequest
  ): Promise<CompanyClientEmployeeReviewResponse>;
  //
  [ActionTypes.UPDATE_EMPLOYEE_REVIEW](
    { commit }: AugmentedActionContext,
    payload: CompanyClientEmployeeReviewUpdateRequest
  ): Promise<CompanyClientEmployeeSimpleReviewResponse>;
  //
  [ActionTypes.EDIT_REGISTRATION_CLIENT_EMPLOYEE](
    { commit }: AugmentedActionContext,
    payload: EditRegistrationForCompanyClientEmployeeRequest
  ): Promise<EditShiftRegistrationDto>;
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEWS](
    { commit }: AugmentedActionContext,
    payload: GetEmployeeReviewOverviewsRequest
  ): Promise<EmployeeOnShiftResponse>;
  [ActionTypes.GET_EMPLOYEE_REVIEW_COUNT](
    { commit }: AugmentedActionContext,
    payload: GetEmployeeReviewCountRequest
  ): Promise<CompanyClientEmployeeReviewCount>;
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW_SCORE](
    { commit }: AugmentedActionContext,
    payload: GetEmployeeReviewOverviewScoreRequest
  ): Promise<CompanyClientReviewScoreSummaryResponse>;
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW](
    { commit }: AugmentedActionContext,
    payload: GetEmployeeReviewOverviewRequest
  ): Promise<CompanyClientReviewOnShiftResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_SHIFT_APPLICANTS](
    { commit }: AugmentedActionContext,
    payload: CompanyClientShiftApplicantsRequest
  ): Promise<ShiftApplicantsResponse>;
  //
  [ActionTypes.GET_COMPANY_CLIENT_RECENT_SHIFT_DETAILS](
    { commit }: AugmentedActionContext
  ): Promise<ShiftMostRecentResponse>;
  //
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.GET_UPCOMING_SHIFTS](context, shiftObj: UpcomingShiftsRequest) {
    return dashboardApi.getUpcomingShifts(shiftObj);
  },
  //
  [ActionTypes.GET_REQUIRED_ACTIONS]() {
    return dashboardApi.getRequiredActions();
  },
  //
  [ActionTypes.GET_COMPANY_CLIENTS_SHIFTS](
    context,
    shiftObj: CompanyClientShiftsRequest
  ) {
    return dashboardApi.getCompanyClientShifts(shiftObj);
  },
  //
  [ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID](
    context,
    shiftObj: GetRegistrationsByCompanyClientIdRequest
  ) {
    return dashboardApi.getRegistrationsByCompanyClientId(shiftObj);
  },
  //
  [ActionTypes.NUMBER_OF_SHIFTS]() {
    return dashboardApi.getNumberOfShifts();
  },
  //
  [ActionTypes.GET_REGISTRATIONS_BY_COMPANY_CLIENT_ID_OVERVIEW](
    context,
    shiftObj: RegistrationsByCompanyClientIdRequest
  ) {
    return dashboardApi.getRegistrationsByCompanyClientIdOverview(shiftObj);
  },
  //
  [ActionTypes.CREATE_SHIFT_REQUEST](
    context,
    shiftObj: ShiftRequestRequest
  ) {
    return dashboardApi.createShiftRequest(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_SHIFT_DETAILS](
    context,
    shiftObj: CompanyClientShiftDetailsRequest
  ) {
    return dashboardApi.getCompanyClientShiftDetails(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_EMPLOYEES](context, shiftObj: EmployeesOfCompanyClientRequest) {
    return dashboardApi.getCompanyClientEmployees(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_EMPLOYEE](context, reqObj: EmployeeOfCompanyClientRequest) {
    return dashboardApi.companyClientEmployee(reqObj);
  },
  //
  [ActionTypes.FAVOURITE_EMPLOYEE](
    context,
    shiftObj: EmployeeFavouriteOperationRequest
  ) {
    return dashboardApi.favouriteEmployee(shiftObj);
  },
  //
  [ActionTypes.APPROVE_REGISTRATION_LINE](
    context,
    shiftObj: ApproveBulkRegistrationsRequest
  ) {
    return dashboardApi.approveBulkRegistrations(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_LIST](
    context,
    shiftObj: GetForUserRequest
  ) {
    return dashboardApi.getCompanyClientList(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT](
    context,
    shiftObj: number
  ) {
    return dashboardApi.getCompanyClient(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OR_PREPARE_TEMPLATE](
    context,
    shiftObj: GetEmployeeReviewOrPrepareTemplateRequest
  ) {
    return dashboardApi.getEmployeeReviewOrPrepareTemplate(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_LABELS]() {
    return dashboardApi.getEmployeeReviewLabels();
  },
  //
  [ActionTypes.CREATE_EMPLOYEE_REVIEW](
    context,
    shiftObj: CompanyClientEmployeeReviewCreateRequest
  ) {
    return dashboardApi.createEmployeeReview(shiftObj);
  },
  //
  [ActionTypes.UPDATE_EMPLOYEE_REVIEW](
    context,
    shiftObj: CompanyClientEmployeeReviewUpdateRequest
  ) {
    return dashboardApi.updateEmployeeReview(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_COUNT](
    context,
    shiftObj: GetEmployeeReviewCountRequest
  ) {
    return dashboardApi.getEmployeeReviewCount(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW_SCORE](
    context,
    shiftObj: GetEmployeeReviewOverviewScoreRequest
  ) {
    return dashboardApi.getEmployeeReviewOverviewScore(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW](
    context,
    shiftObj: GetEmployeeReviewOverviewRequest
  ) {
    return dashboardApi.getEmployeeReviewOverview(shiftObj);
  },
  //
  [ActionTypes.EDIT_REGISTRATION_CLIENT_EMPLOYEE](
    context,
    shiftObj: EditRegistrationForCompanyClientEmployeeRequest
  ) {
    return dashboardApi.editRegistrationForCompanyClientEmployee(shiftObj);
  },
  //
  [ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEWS](
    context,
    shiftObj: GetEmployeeReviewOverviewsRequest
  ) {
    return dashboardApi.getEmployeeReviewOverviews(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_SHIFT_APPLICANTS](
    context,
    shiftObj: CompanyClientShiftApplicantsRequest
  ) {
    return dashboardApi.getCompanyClientShiftApplicants(shiftObj);
  },
  //
  [ActionTypes.GET_COMPANY_CLIENT_RECENT_SHIFT_DETAILS]() {
    return dashboardApi.getCompanyClientRecentShiftDetails();
  },
};
