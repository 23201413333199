/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeOnShiftOverview,
    EmployeeOnShiftOverviewFromJSON,
    EmployeeOnShiftOverviewFromJSONTyped,
    EmployeeOnShiftOverviewToJSON,
} from './EmployeeOnShiftOverview';
import {
    PaginationPage,
    PaginationPageFromJSON,
    PaginationPageFromJSONTyped,
    PaginationPageToJSON,
} from './PaginationPage';

/**
 * 
 * @export
 * @interface EmployeeOnShiftResponse
 */
export interface EmployeeOnShiftResponse {
    /**
     * An array, containing all review statuses performed by company client.
     * @type {Array<EmployeeOnShiftOverview>}
     * @memberof EmployeeOnShiftResponse
     */
    resultList: Array<EmployeeOnShiftOverview>;
    /**
     * 
     * @type {PaginationPage}
     * @memberof EmployeeOnShiftResponse
     */
    page: PaginationPage;
}

export function EmployeeOnShiftResponseFromJSON(json: any): EmployeeOnShiftResponse {
    return EmployeeOnShiftResponseFromJSONTyped(json, false);
}

export function EmployeeOnShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeOnShiftResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultList': ((json['resultList'] as Array<any>).map(EmployeeOnShiftOverviewFromJSON)),
        'page': PaginationPageFromJSON(json['page']),
    };
}

export function EmployeeOnShiftResponseToJSON(value?: EmployeeOnShiftResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultList': ((value.resultList as Array<any>).map(EmployeeOnShiftOverviewToJSON)),
        'page': PaginationPageToJSON(value.page),
    };
}

