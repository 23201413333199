/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewer
 */
export interface CompanyClientEmployeeReviewer {
    /**
     * Reviewer's first name
     * @type {string}
     * @memberof CompanyClientEmployeeReviewer
     */
    firstName: string;
    /**
     * Reviewer's middle name
     * @type {string}
     * @memberof CompanyClientEmployeeReviewer
     */
    middleName?: string;
    /**
     * Reviewer's last name
     * @type {string}
     * @memberof CompanyClientEmployeeReviewer
     */
    lastName: string;
}

export function CompanyClientEmployeeReviewerFromJSON(json: any): CompanyClientEmployeeReviewer {
    return CompanyClientEmployeeReviewerFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': json['lastName'],
    };
}

export function CompanyClientEmployeeReviewerToJSON(value?: CompanyClientEmployeeReviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
    };
}

