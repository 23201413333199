import { KeycloakInstance } from "keycloak-js";

export default function setTheme(kcInstance: KeycloakInstance): void {
  // create link element for favicon
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  // change favicon according to realm
  if (kcInstance.realm === "cp-maqqie") {
    (
      document.querySelector("link[rel*='icon']") as HTMLLinkElement
    ).href = `${window.location.origin}/favicon.ico`;
  }

  // change theme according to realm
  if (kcInstance.realm === "cp-mep") {
    document.documentElement.setAttribute("theme", "mep");

    (
      document.querySelector("link[rel*='icon']") as HTMLLinkElement
    ).href = `${window.location.origin}/favicon-mep.ico`;
  }
}
