
import { onMounted, computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import ToastService from "mq-ui-kit/lib/toast";
import { Rating } from "mq-ui-kit/lib/Rating";
import { useDebounceFn } from "@vueuse/core";
import ActionTypes from "@/store/types/action-types";
import Avatar from "@/components/ui/Avatar.vue";
import ExpandCollapse from "@/components/ui/ExpandCollapse.vue";
import SvgStar from "@/components/svg/SvgStar.vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import ProgressBarEmployee from "@/components/ui/ProgressBarEmployee.vue";
import {
  Employees,
  EmployeeFavouriteOperationRequest,
  CompanyClientReviewScoreSummaryResponse,
  CompanyClientReviewOnShiftResponse,
  CompanyClientReviewOnShift,
} from "@/services/rest/dashboard";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "EmployeeReviews",
  components: {
    CardWrap,
    Avatar,
    SvgStar,
    ProgressBarEmployee,
    Rating,
    ExpandCollapse,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const isLoading = ref(false);
    const route = useRoute();
    const dashboardShown = computed(() => store.state.isDashboardShown);
    const starRating = ref(3);
    const employeeReviewCount = ref(0);
    const paginationParams = ref({
      page: 0,
      size: 10,
      totalPages: 0
    });

    const employeeReviewOverviewScore = ref<CompanyClientReviewScoreSummaryResponse>({
      reviewScoreAverage: 0,
      countOfReviews: 0,
    });
    const employeeReviewOverview = ref<CompanyClientReviewOnShiftResponse>({
      totalElements: 0,
      resultList: [] as CompanyClientReviewOnShift[],
    });

    const isActiveTab = ref("about");
    const scrollPosition = ref(false);

    const employee = ref<Employees>({
      employeeId: 0,
      fullName: "",
      firstName: "",
      lastName: "",
      profilePictureUrl: "",
      favourite: false,
      isTeamLeader: false,
      functionName: "",
    });

    const selectTab = (tab: string) => {
      isActiveTab.value = tab;
    };

    const getEmployee = (employeeId: number) => {
      const params = {
        employeeId,
      };

      isLoading.value = true;

      store
        .dispatch(ActionTypes.GET_COMPANY_CLIENT_EMPLOYEE, params)
        .then((res) => {
          employee.value = res;
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getEmployeeReviewCount = (employeeId: number) => {
      const params = {
        employeeId,
      };

      store
        .dispatch(ActionTypes.GET_EMPLOYEE_REVIEW_COUNT, params)
        .then((res) => {
          employeeReviewCount.value = res.totalElements;
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getEmployeeReviewOverviewScore = (employeeId: number) => {
      const params = {
        employeeId,
      };

      store
        .dispatch(ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW_SCORE, params)
        .then((res) => {
          employeeReviewOverviewScore.value = res;
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getEmployeeReviewOverview = (employeeId: number) => {
      const params = {
        employeeId,
        page: paginationParams.value.page,
        size: paginationParams.value.size,
        sort: ["shift_start_timestamp, desc"],
      };

      store
        .dispatch(ActionTypes.GET_EMPLOYEE_REVIEW_OVERVIEW, params)
        .then((res: CompanyClientReviewOnShiftResponse) => {
          employeeReviewOverview.value.totalElements = res.totalElements;
          employeeReviewOverview.value.resultList.push(...res.resultList);
        })
        .catch((err) => {
          console.log("Error:", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const favouriteEmployee = (employeeId: number) => {
      const params: EmployeeFavouriteOperationRequest = {
        employeeFavouriteRequest: {
          employeeId,
        },
      };

      store
        .dispatch(ActionTypes.FAVOURITE_EMPLOYEE, params)
        .then((res) => {
          employee.value.favourite = res.favourite;
        })
        .catch((err) => {
          ToastService.errorToast(t("errors.something_went_wrong"));
          console.log("Error:", err);
        });
    };

    const handleScroll = (event: UIEvent) => {
      const totalNumOfPages = Math.ceil(employeeReviewOverview.value.totalElements/paginationParams.value.size);

      if (paginationParams.value.page >= totalNumOfPages - 1) return;

      const element = event.target as HTMLDivElement;

      if (element.scrollTop === 0) {
        scrollPosition.value = true;
      } else if (element.scrollTop + element.clientHeight === element.scrollHeight) {
        paginationParams.value.page++;
        getEmployeeReviewOverview(employee.value.employeeId);
        scrollPosition.value = false;
      }
    };

    const dateFormat = (item: Date) => {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      } as const;

      const customDate = new Date(item);

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        return customDate.toLocaleDateString("nl-NL", options);
      }

      return customDate.toLocaleDateString("en-GB", options);
    };

    const debouncedFn = useDebounceFn((event: UIEvent) => {
      handleScroll(event);
    }, 100);

    const roundDecimals = (num: number) => {
      return num % 1 !== 0 ? num.toFixed(1) : num;
    };

    onMounted(() => {
      employee.value.employeeId = Number(route.params.id);

      getEmployee(employee.value.employeeId);
      getEmployeeReviewCount(employee.value.employeeId);
      getEmployeeReviewOverviewScore(employee.value.employeeId);
      getEmployeeReviewOverview(employee.value.employeeId);
    });

    return {
      dashboardShown,
      isLoading,
      employee,
      favouriteEmployee,
      starRating,
      employeeReviewCount,
      employeeReviewOverviewScore,
      employeeReviewOverview,
      dateFormat,
      selectTab,
      isActiveTab,
      handleScroll,
      debouncedFn,
      roundDecimals,
    };
  },
});
