/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BulkRegistrationLinesRequest,
    BulkRegistrationLinesRequestFromJSON,
    BulkRegistrationLinesRequestToJSON,
    BulkRegistrationLinesResponse,
    BulkRegistrationLinesResponseFromJSON,
    BulkRegistrationLinesResponseToJSON,
    EditShiftRegistrationDto,
    EditShiftRegistrationDtoFromJSON,
    EditShiftRegistrationDtoToJSON,
    RegistrationsForCompanyClientRequest,
    RegistrationsForCompanyClientRequestFromJSON,
    RegistrationsForCompanyClientRequestToJSON,
    RegistrationsForCompanyClientResponse,
    RegistrationsForCompanyClientResponseFromJSON,
    RegistrationsForCompanyClientResponseToJSON,
} from '../models';

export interface ApproveBulkRegistrationsRequest {
    bulkRegistrationLinesRequest: BulkRegistrationLinesRequest;
}

export interface EditRegistrationForCompanyClientEmployeeRequest {
    editShiftRegistrationDto: EditShiftRegistrationDto;
}

export interface RegistrationsByCompanyClientIdRequest {
    registrationsForCompanyClientRequest: RegistrationsForCompanyClientRequest;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class RegistrationsApi extends runtime.BaseAPI {

    /**
     * Approves a bulk of registrations for company client. Can include freelance, time and shift registration lines.
     * Approves a bulk of registrations
     */
    async approveBulkRegistrationsRaw(requestParameters: ApproveBulkRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BulkRegistrationLinesResponse>> {
        if (requestParameters.bulkRegistrationLinesRequest === null || requestParameters.bulkRegistrationLinesRequest === undefined) {
            throw new runtime.RequiredError('bulkRegistrationLinesRequest','Required parameter requestParameters.bulkRegistrationLinesRequest was null or undefined when calling approveBulkRegistrations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/registrations/bulk-approval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkRegistrationLinesRequestToJSON(requestParameters.bulkRegistrationLinesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkRegistrationLinesResponseFromJSON(jsonValue));
    }

    /**
     * Approves a bulk of registrations for company client. Can include freelance, time and shift registration lines.
     * Approves a bulk of registrations
     */
    async approveBulkRegistrations(requestParameters: ApproveBulkRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BulkRegistrationLinesResponse> {
        const response = await this.approveBulkRegistrationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates one registration line for Company Client
     * Updates one registration
     */
    async editRegistrationForCompanyClientEmployeeRaw(requestParameters: EditRegistrationForCompanyClientEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EditShiftRegistrationDto>> {
        if (requestParameters.editShiftRegistrationDto === null || requestParameters.editShiftRegistrationDto === undefined) {
            throw new runtime.RequiredError('editShiftRegistrationDto','Required parameter requestParameters.editShiftRegistrationDto was null or undefined when calling editRegistrationForCompanyClientEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/registrations/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditShiftRegistrationDtoToJSON(requestParameters.editShiftRegistrationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditShiftRegistrationDtoFromJSON(jsonValue));
    }

    /**
     * Updates one registration line for Company Client
     * Updates one registration
     */
    async editRegistrationForCompanyClientEmployee(requestParameters: EditRegistrationForCompanyClientEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EditShiftRegistrationDto> {
        const response = await this.editRegistrationForCompanyClientEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all shifts-only-registrations, time-registrations and freelance-registrations for a CompanyClient
     * Lists all registrations
     */
    async registrationsByCompanyClientIdRaw(requestParameters: RegistrationsByCompanyClientIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RegistrationsForCompanyClientResponse>> {
        if (requestParameters.registrationsForCompanyClientRequest === null || requestParameters.registrationsForCompanyClientRequest === undefined) {
            throw new runtime.RequiredError('registrationsForCompanyClientRequest','Required parameter requestParameters.registrationsForCompanyClientRequest was null or undefined when calling registrationsByCompanyClientId.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationsForCompanyClientRequestToJSON(requestParameters.registrationsForCompanyClientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationsForCompanyClientResponseFromJSON(jsonValue));
    }

    /**
     * Lists all shifts-only-registrations, time-registrations and freelance-registrations for a CompanyClient
     * Lists all registrations
     */
    async registrationsByCompanyClientId(requestParameters: RegistrationsByCompanyClientIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RegistrationsForCompanyClientResponse> {
        const response = await this.registrationsByCompanyClientIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
