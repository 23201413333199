
import { computed, defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { setCookie } from "typescript-cookie";
import { FormRow } from "mq-ui-kit/lib/FormRow";
import { FormControlModel } from "mq-ui-kit/lib/form-control-model";
import ToastService from "mq-ui-kit/lib/toast";
import { useRouter } from "vue-router";
import { useDebounceFn } from "@vueuse/core";
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";
import Avatar from "@/components/ui/Avatar.vue";
import { CookieProps } from "@/types/global";
import MutationTypes from "@/store/types/mutation-types";
import ActionTypes from "@/store/types/action-types";
import OverlayDialog from "@/components/ui/OverlayDialog.vue";
import Search from "@/components/ui/Search.vue";
import {
  CompanyClientsOfUserResponse,
  GetForUserRequest,
} from "@/services/rest/dashboard";

export default defineComponent({
  name: "PageHeader",

  components: {
    Breadcrumbs,
    Avatar,
    FormRow,
    OverlayDialog,
    Search,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    const modalIdChooseClient = ref("choose-client");
    const isChooseClientModalOpen = ref(false);
    const isNavMenuOpen = ref(false);
    const paginationParams = ref({
      companyClientTradeName: "",
      page: 0,
      // when changing size property, alter the scss variable $perPage in <style> block
      size: 4,
      totalElements: 0,
    });

    // all clients without "search" results
    const numOfCompanyClients = ref(0);

    const selectedCompanyClient = computed(() => store.state.selectedClientFull);
    const companyClientsList = computed(() => store.state.companyClientList);
    const searchValue = ref("");

    const getCompanyClientList = () => {
      const params = {
        page: paginationParams.value.page,
        size: paginationParams.value.size,
        ...(paginationParams.value.companyClientTradeName
        && { companyClientTradeName: paginationParams.value.companyClientTradeName }),
      } as GetForUserRequest;

      return  store.dispatch(ActionTypes.GET_COMPANY_CLIENT_LIST, params);
    }

    const handleScroll = (event: UIEvent) => {
      const totalNumOfPages = Math.ceil(
        paginationParams.value.totalElements / paginationParams.value.size
      );

      if (paginationParams.value.page >= totalNumOfPages - 1) return;

      const element = event.target as HTMLDivElement;

      if (element.scrollTop + element.clientHeight === element.scrollHeight) {
        const params = {
          page: paginationParams.value.page + 1,
          size: paginationParams.value.size,
          ...(paginationParams.value.companyClientTradeName
        && { companyClientTradeName: paginationParams.value.companyClientTradeName }),
        } as GetForUserRequest;

        store
          .dispatch(ActionTypes.GET_COMPANY_CLIENT_LIST, params)
          .then((res: CompanyClientsOfUserResponse) => {
            paginationParams.value.totalElements = res.page.totalElements;
            paginationParams.value.page++;

            const restOfTheList = store.state.companyClientList;
            const newList = [...restOfTheList, ...res.resultList];

            store.commit(MutationTypes.SET_COMPANY_CLIENT_LIST, newList);

          })
          .catch((err) => {
            console.log("Error: ", err);
            ToastService.errorToast(t("errors.something_went_wrong"));
          });
      }
    };

    const debouncedFn = useDebounceFn((event: UIEvent) => {
      handleScroll(event);
    }, 100);

    const debounceCompClientList = useDebounceFn(() => {
      getCompanyClientList().then((res: CompanyClientsOfUserResponse) => {
          store.commit(MutationTypes.SET_COMPANY_CLIENT_LIST, res.resultList);

          paginationParams.value.totalElements = res.page.totalElements;
        })
        .catch((err) => {
          console.log("Error: ", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        });
    }, 500);

    const updateSearch = (data: string) => {
      if(!data.length && data.trim().length === 0) return;

      paginationParams.value.companyClientTradeName = data;
      paginationParams.value.page = 0;

      debounceCompClientList();
    };

    const clearSearch = (data: string) => {
      paginationParams.value.companyClientTradeName = data;
      paginationParams.value.page = 0;
      searchValue.value = "";

      debounceCompClientList();
    }

    const openModalChooseClient = () => {
        getCompanyClientList().then((res: CompanyClientsOfUserResponse) => {
          store.commit(MutationTypes.SET_COMPANY_CLIENT_LIST, res.resultList);

          paginationParams.value.totalElements = res.page.totalElements;
          isChooseClientModalOpen.value = true;
        })
        .catch((err) => {
          console.log("Error: ", err);
          ToastService.errorToast(t("errors.something_went_wrong"));
        });
    };

    const closeModalChooseClient = () => {
      isChooseClientModalOpen.value = false;
      paginationParams.value.page = 0;
      paginationParams.value.companyClientTradeName = "";
      searchValue.value = "";
      updateSearch(searchValue.value);

      router.push({ name: "Dashboard" });
    };

    const selectCompany = ref();
    selectCompany.value = new FormControlModel({
      value: "",
    });

    const selectClient = (client: object | any) => {
      if (!selectCompany.value.valid && isChooseClientModalOpen.value) return;

      setCookie(CookieProps.ClientId, client.companyClientId);

      store.commit(MutationTypes.SET_SELECTED_CLIENT, client.companyClientId);
      store.commit(MutationTypes.SET_SELECTED_CLIENT_FULL, client);
      store.commit(MutationTypes.SET_DASHBOARD_SHOWN, true);

      closeModalChooseClient();
    };

    const triggerNavigationMenu = () => {
      if (numOfCompanyClients.value > 1) isNavMenuOpen.value = !isNavMenuOpen.value;
    };

    const closeNavigationMenu = () => {
      isNavMenuOpen.value = false;
    };

    const compClientInitials = computed(() => {
      const name = selectedCompanyClient.value.tradeName
        .split(" ")
        .map((word: string) => word[0])
        .join("")
        .substring(0, 2);

      return name;
    });

    const companyLastName = (tradeName: string) =>
      tradeName.substring(tradeName.indexOf(" ") + 1);

    const companyName = computed(() => selectedCompanyClient.value.tradeName);

    const companyId = computed(() => selectedCompanyClient.value.companyClientId);

    onMounted(() => {
      numOfCompanyClients.value = store.state.companyClientList.length;
    });

    return {
      companyClientsList,
      compClientInitials,
      companyName,
      companyId,
      modalIdChooseClient,
      openModalChooseClient,
      selectClient,
      isChooseClientModalOpen,
      closeModalChooseClient,
      companyLastName,
      triggerNavigationMenu,
      isNavMenuOpen,
      searchValue,
      closeNavigationMenu,
      updateSearch,
      debouncedFn,
      numOfCompanyClients,
      clearSearch,
    };
  },
});
