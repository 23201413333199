enum MutationTypes {
  SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT",
  SET_SELECTED_CLIENT_FULL = "SET_SELECTED_CLIENT_FULL",
  SET_DASHBOARD_SHOWN = "SET_DASHBOARD_SHOWN",
  SET_IS_REQ_SHIFT_MODAL_OPEN = "SET_IS_REQ_SHIFT_MODAL_OPEN",
  SET_IS_MEMBER_DETAILS_MODAL_OPEN = "SET_IS_MEMBER_DETAILS_MODAL_OPEN",
  SET_IS_EMPLOYEE_REVIEW_MODAL_OPEN = "IS_EMPLOYEE_REVIEW_MODAL_OPEN",
  SET_COMPANY_CLIENT_LIST = "SET_COMPANY_CLIENT_LIST",
}

export default MutationTypes;
