import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueKeycloak from "@dsb-norge/vue-keycloak-js";
import Keycloak from "keycloak-js";
import VueClickAway from "vue3-click-away";
import VueGtag from "vue-gtag";
// eslint-disable-next-line
import { VueKeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types";
import { setCookie } from "typescript-cookie";
import DKToast from "vue-dk-toast";
import PrimeVue from "primevue/config";
import ToastService from "mq-ui-kit/lib/toast";
import { Tabs, Tab } from "vue3-tabs-component";
import VueUploadComponent from "vue-upload-component";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import initKcConfig from "@/helpers/keycloak-config";
import getGoogleAnalyKey from "@/services/rest/gtag";
import setTheme from "@/helpers/theme-handler";
import { CookieProps } from "@/types/global";
import { logoutClearCookie } from "@/helpers/helper-methods";
import router from "./router";
import { store } from "./store";
import { defaultLocale, locale, messages } from "./i18n";
import datepickerLocale from "./locales/datepicker-locale";
import { FaIcon } from "./helpers";
import App from "./App.vue";

import "mq-ui-kit/css/mq-ui-kit.scss";
import "@/assets/styles/main.scss";
import "primevue/resources/primevue.min.css";



const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: defaultLocale,
  messages,
  // This is needed if u have translations in optionsAPI and compositionAPI (if legacy:false)
  globalInjection: true,
});

document.title = "Client Portal";

initKcConfig().then((kcConfig) => {
  // https:// github.com/dsb-norge/vue-keycloak-js

  const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueClickAway)
    .use(PrimeVue, {
      locale: datepickerLocale.datepickerLocale,
    })
    .use(DKToast)
    .use(ElementPlus)
    .component("tabs", Tabs)
    .component("tab", Tab)
    .component("FaIcon", FaIcon)
    .component("file-upload", VueUploadComponent)
    .use(VueKeycloak, {
      /* eslint-disable */
      config: kcConfig,
      init: {
        onLoad: "login-required",
      },
      async onReady(keycloak: Keycloak) {

        console.log("Keycloak onReady", keycloak.realm);
        if (keycloak.authenticated) {
          setCookie(CookieProps.UserToken, keycloak.token as string);
          setCookie(CookieProps.RealmName, keycloak.realm as string);
          setCookie(CookieProps.LogoutUri, keycloak.createLogoutUrl({ redirectUri: window.location.origin }) as string);

          try {
            const results = await getGoogleAnalyKey(keycloak.token as string);

            if (results.ok) {
              const googleAnalyKey = await results.text();

              app.use(VueGtag, {
                config: { id: googleAnalyKey },
              }, router);
            }

          } catch (e) {
            console.log("Error", e);
          }
        };

        app.provide("provideKeycloak", keycloak);
        app.mount("#app");
        setTheme(keycloak);

        // It will no longer be required after Vue 3.3.
        app.config.unwrapInjectedRef = true;

        ToastService.provideToastInstance(app.config.globalProperties.$toast);
      },
      onAuthRefreshError(kc: any) {
        logoutClearCookie();
        kc.logout();
      },
      onAuthRefreshSuccess(kc: any) {
        setCookie(CookieProps.UserToken, kc.token as string);
      }
    } as any);
});


declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $keycloak: VueKeycloakInstance;
  }
}
