/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeFavouriteRequest
 */
export interface EmployeeFavouriteRequest {
    /**
     * Employee ID
     * @type {number}
     * @memberof EmployeeFavouriteRequest
     */
    employeeId: number;
}

export function EmployeeFavouriteRequestFromJSON(json: any): EmployeeFavouriteRequest {
    return EmployeeFavouriteRequestFromJSONTyped(json, false);
}

export function EmployeeFavouriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeFavouriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
    };
}

export function EmployeeFavouriteRequestToJSON(value?: EmployeeFavouriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
    };
}

