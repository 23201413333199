// check what roles user have

import { computed } from "vue";
import { useStore } from "vuex";

/* eslint-disable-next-line */
export function useRoleCheck(role: string) {
  const store = useStore();
  const selectedClientMemoRoles = computed(() => store.state.selectedClientFull.roles);

  return selectedClientMemoRoles.value.includes(role);
}