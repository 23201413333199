/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientReviewLabelOfEmployee,
    CompanyClientReviewLabelOfEmployeeFromJSON,
    CompanyClientReviewLabelOfEmployeeFromJSONTyped,
    CompanyClientReviewLabelOfEmployeeToJSON,
} from './CompanyClientReviewLabelOfEmployee';

/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewUpdateRequest
 */
export interface CompanyClientEmployeeReviewUpdateRequest {
    /**
     * The ID of review record
     * @type {number}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    reviewId: number;
    /**
     * Review score of this employee
     * @type {number}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    reviewScore: number;
    /**
     * Employee ID
     * @type {number}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    employeeId: number;
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    labels: Array<CompanyClientReviewLabelOfEmployee>;
    /**
     * Additional notes after on review
     * @type {string}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    note?: string;
    /**
     * Is employee favored by Company Client
     * @type {boolean}
     * @memberof CompanyClientEmployeeReviewUpdateRequest
     */
    favourite: boolean;
}

export function CompanyClientEmployeeReviewUpdateRequestFromJSON(json: any): CompanyClientEmployeeReviewUpdateRequest {
    return CompanyClientEmployeeReviewUpdateRequestFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewId': json['reviewId'],
        'reviewScore': json['reviewScore'],
        'employeeId': json['employeeId'],
        'labels': ((json['labels'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'favourite': json['favourite'],
    };
}

export function CompanyClientEmployeeReviewUpdateRequestToJSON(value?: CompanyClientEmployeeReviewUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewId': value.reviewId,
        'reviewScore': value.reviewScore,
        'employeeId': value.employeeId,
        'labels': ((value.labels as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
        'note': value.note,
        'favourite': value.favourite,
    };
}

