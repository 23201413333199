import { computed, ref } from "vue";

/* eslint-disable-next-line */
export function useErrorImgHandler() {
  const isImgError = ref(false);

  const imgErrorClass = computed(() => isImgError.value ? "img--error-hide": "");

  const handleErrorImg = () => {
    isImgError.value = true;
  };

  return {isImgError, handleErrorImg, imgErrorClass};
}