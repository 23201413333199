/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyClientShiftsResponse,
    CompanyClientShiftsResponseFromJSON,
    CompanyClientShiftsResponseToJSON,
    NumberOfCompanyClientShiftsResponse,
    NumberOfCompanyClientShiftsResponseFromJSON,
    NumberOfCompanyClientShiftsResponseToJSON,
    ShiftApplicantsResponse,
    ShiftApplicantsResponseFromJSON,
    ShiftApplicantsResponseToJSON,
    ShiftFilter,
    ShiftFilterFromJSON,
    ShiftFilterToJSON,
    ShiftMostRecentResponse,
    ShiftMostRecentResponseFromJSON,
    ShiftMostRecentResponseToJSON,
    ShiftRequestResponse,
    ShiftRequestResponseFromJSON,
    ShiftRequestResponseToJSON,
    ShiftResponse,
    ShiftResponseFromJSON,
    ShiftResponseToJSON,
} from '../models';

export interface CompanyClientShiftApplicantsRequest {
    shiftId: number;
    page?: number;
    size?: number;
}

export interface CompanyClientShiftDetailsRequest {
    shiftId: number;
}

export interface CompanyClientShiftsRequest {
    shiftFilter: ShiftFilter;
    page?: number;
    size?: number;
}

export interface ShiftRequestRequest {
    title: string;
    description: string;
    startTimestamp: Date;
    endTimestamp: Date;
    file?: Array<Blob>;
}

/**
 * 
 */
export class ShiftsApi extends runtime.BaseAPI {

    /**
     * Get details for most recent shift of company client
     * Get details for most recent shift of company client
     */
    async companyClientRecentShiftDetailsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ShiftMostRecentResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/shifts/most-recent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftMostRecentResponseFromJSON(jsonValue));
    }

    /**
     * Get details for most recent shift of company client
     * Get details for most recent shift of company client
     */
    async companyClientRecentShiftDetails(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ShiftMostRecentResponse> {
        const response = await this.companyClientRecentShiftDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all shift applicants (employees)
     * Get all shift applicants
     */
    async companyClientShiftApplicantsRaw(requestParameters: CompanyClientShiftApplicantsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ShiftApplicantsResponse>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling companyClientShiftApplicants.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/shifts/{shiftId}/applicants`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftApplicantsResponseFromJSON(jsonValue));
    }

    /**
     * Get all shift applicants (employees)
     * Get all shift applicants
     */
    async companyClientShiftApplicants(requestParameters: CompanyClientShiftApplicantsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ShiftApplicantsResponse> {
        const response = await this.companyClientShiftApplicantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get details for requested shift ID of Company Client
     * Get details for requested shift ID
     */
    async companyClientShiftDetailsRaw(requestParameters: CompanyClientShiftDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ShiftResponse>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling companyClientShiftDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/shifts/{shiftId}`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftResponseFromJSON(jsonValue));
    }

    /**
     * Get details for requested shift ID of Company Client
     * Get details for requested shift ID
     */
    async companyClientShiftDetails(requestParameters: CompanyClientShiftDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ShiftResponse> {
        const response = await this.companyClientShiftDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all company client shifts with optional filters
     * Get all company client shifts with optional filters
     */
    async companyClientShiftsRaw(requestParameters: CompanyClientShiftsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyClientShiftsResponse>> {
        if (requestParameters.shiftFilter === null || requestParameters.shiftFilter === undefined) {
            throw new runtime.RequiredError('shiftFilter','Required parameter requestParameters.shiftFilter was null or undefined when calling companyClientShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/shifts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftFilterToJSON(requestParameters.shiftFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyClientShiftsResponseFromJSON(jsonValue));
    }

    /**
     * Get all company client shifts with optional filters
     * Get all company client shifts with optional filters
     */
    async companyClientShifts(requestParameters: CompanyClientShiftsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyClientShiftsResponse> {
        const response = await this.companyClientShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get number of shifts
     */
    async numberOfShiftsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<NumberOfCompanyClientShiftsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/shifts/number-of-company-client-shifts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NumberOfCompanyClientShiftsResponseFromJSON(jsonValue));
    }

    /**
     * Get number of shifts
     */
    async numberOfShifts(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<NumberOfCompanyClientShiftsResponse> {
        const response = await this.numberOfShiftsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Add new shift request containing note and optionally attachments. Content is to be forwarded to the email.
     * Add new shift request
     */
    async shiftRequestRaw(requestParameters: ShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ShiftRequestResponse>> {
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling shiftRequest.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling shiftRequest.');
        }

        if (requestParameters.startTimestamp === null || requestParameters.startTimestamp === undefined) {
            throw new runtime.RequiredError('startTimestamp','Required parameter requestParameters.startTimestamp was null or undefined when calling shiftRequest.');
        }

        if (requestParameters.endTimestamp === null || requestParameters.endTimestamp === undefined) {
            throw new runtime.RequiredError('endTimestamp','Required parameter requestParameters.endTimestamp was null or undefined when calling shiftRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.startTimestamp !== undefined) {
            formParams.append('start_timestamp', requestParameters.startTimestamp as any);
        }

        if (requestParameters.endTimestamp !== undefined) {
            formParams.append('end_timestamp', requestParameters.endTimestamp as any);
        }

        if (requestParameters.file) {
            requestParameters.file.forEach((element) => {
                formParams.append('file', element as any);
            })
        }

        const response = await this.request({
            path: `/shifts/shift-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftRequestResponseFromJSON(jsonValue));
    }

    /**
     * Add new shift request containing note and optionally attachments. Content is to be forwarded to the email.
     * Add new shift request
     */
    async shiftRequest(requestParameters: ShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ShiftRequestResponse> {
        const response = await this.shiftRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
