
import { computed, defineComponent, nextTick, PropType, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { Rating } from "mq-ui-kit/lib/Rating";
import { useI18n } from "vue-i18n";
import { FormControlModel } from "mq-ui-kit/lib/form-control-model";
import OverlayDialog from "@/components/ui/OverlayDialog.vue";
import MutationTypes from "@/store/types/mutation-types";
import ActionTypes from "@/store/types/action-types";
import Avatar from "@/components/ui/Avatar.vue";
import { Employees, EmployeeFavouriteOperationRequest } from "@/services/rest/dashboard";

export default defineComponent({
  name: "ShiftDetailsMemberModal",
  components: { OverlayDialog, Avatar, Rating },
  props: {
    member: {
      type: Object as PropType<Employees>,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, {emit}) {
    const store = useStore();
    const { t } = useI18n();
    const { member: memberHolder } = toRefs(props);
    const isModalOpen = computed(() => store.state.isMemberDetailsModalOpen);
    const isExpandedDesc = ref(false);
    const showReadMoreBtn = ref(false);
    const isLoading = ref(false);

    const starRating = ref(new FormControlModel({
      value: 0,
    }));

    const isExpandedDescText = computed(() =>
      isExpandedDesc.value ? t("global.read_less") : t("global.read_more")
    );

    const closeDialog = () => {
      store.commit(MutationTypes.SET_IS_MEMBER_DETAILS_MODAL_OPEN, false);
      isExpandedDesc.value = false;
      starRating.value.setValue(0);
      emit("close", memberHolder.value);
    };

    const likeMember = () => {
      isLoading.value = true;

      const params: EmployeeFavouriteOperationRequest = {
        employeeFavouriteRequest: {
          employeeId: memberHolder.value.employeeId || 0,
        },
      };

      store
        .dispatch(ActionTypes.FAVOURITE_EMPLOYEE, params)
        .then((res) => {
          memberHolder.value.favourite = res.favourite;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(()=>{
          isLoading.value = false;
        });
    };

    const expandAbout = () => {
      isExpandedDesc.value = !isExpandedDesc.value;
    };

    const checkShowMoreBtn = () => {
      const el = document.querySelector(".member__desc--height") as HTMLElement;
      showReadMoreBtn.value = el.scrollHeight > el.clientHeight;
    };

    watch(isModalOpen, (newVal) => {
      if (newVal) {
        starRating.value.setValue(memberHolder.value.reviewScoreAverage);

        nextTick(() => {
          checkShowMoreBtn();
        });
      }
    });

    return {
      isModalOpen,
      closeDialog,
      likeMember,
      memberHolder,
      expandAbout,
      isExpandedDesc,
      isExpandedDescText,
      showReadMoreBtn,
      starRating,
      isLoading
    };
  },
});
