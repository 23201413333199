/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';
import {
    ShiftDto,
    ShiftDtoFromJSON,
    ShiftDtoFromJSONTyped,
    ShiftDtoToJSON,
} from './ShiftDto';
import {
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface RegistrationDto
 */
export interface RegistrationDto {
    /**
     * The id of the registration. Registrations can have duplicate id's, depending on the registrationClass. Each registrationClass has its own id-range.
     * @type {number}
     * @memberof RegistrationDto
     */
    registrationId: number;
    /**
     * The client tradename
     * @type {string}
     * @memberof RegistrationDto
     */
    clientName?: string;
    /**
     * The class of a registration, TIME_REGISTRATION for TimeRegistrationLines, FREELANCE_REGISTRATION for FreelanceRegistrationLines or SHIFTS_ONLY_REGISTRATION for ShiftsOnlyRegistrationLines.
     * @type {string}
     * @memberof RegistrationDto
     */
    registrationClass: RegistrationDtoRegistrationClassEnum;
    /**
     * The type of a registration
     * @type {string}
     * @memberof RegistrationDto
     */
    type: string;
    /**
     * 
     * @type {Status}
     * @memberof RegistrationDto
     */
    status: Status;
    /**
     * 
     * @type {Employees}
     * @memberof RegistrationDto
     */
    employeeData: Employees;
    /**
     * 
     * @type {ShiftDto}
     * @memberof RegistrationDto
     */
    shiftData?: ShiftDto;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationDto
     */
    startDate: Date;
    /**
     * The start- and end-timestamps of a registration.
     * @type {string}
     * @memberof RegistrationDto
     */
    dateTimeRange: string;
    /**
     * The amount of a registration (hours, kilometers, days or euro, depending on unit).
     * @type {string}
     * @memberof RegistrationDto
     */
    quantity: string;
    /**
     * The unit for amount-property.
     * @type {string}
     * @memberof RegistrationDto
     */
    unit?: RegistrationDtoUnitEnum;
}


/**
 * @export
 */
export const RegistrationDtoRegistrationClassEnum = {
    ShiftsOnlyRegistration: 'SHIFTS_ONLY_REGISTRATION',
    TimeRegistration: 'TIME_REGISTRATION',
    FreelanceRegistration: 'FREELANCE_REGISTRATION'
} as const;
export type RegistrationDtoRegistrationClassEnum = typeof RegistrationDtoRegistrationClassEnum[keyof typeof RegistrationDtoRegistrationClassEnum];

/**
 * @export
 */
export const RegistrationDtoUnitEnum = {
    Km: 'KM',
    Uur: 'UUR',
    Dag: 'DAG',
    Euro: 'EURO',
    Day: 'DAY',
    Delivery: 'DELIVERY',
    Expenses: 'EXPENSES',
    Hour: 'HOUR',
    Kilometer: 'KILOMETER',
    Project: 'PROJECT',
    Service: 'SERVICE'
} as const;
export type RegistrationDtoUnitEnum = typeof RegistrationDtoUnitEnum[keyof typeof RegistrationDtoUnitEnum];


export function RegistrationDtoFromJSON(json: any): RegistrationDto {
    return RegistrationDtoFromJSONTyped(json, false);
}

export function RegistrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationId': json['registrationId'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'registrationClass': json['registrationClass'],
        'type': json['type'],
        'status': StatusFromJSON(json['status']),
        'employeeData': EmployeesFromJSON(json['employeeData']),
        'shiftData': !exists(json, 'shiftData') ? undefined : ShiftDtoFromJSON(json['shiftData']),
        'startDate': (new Date(json['startDate'])),
        'dateTimeRange': json['dateTimeRange'],
        'quantity': json['quantity'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function RegistrationDtoToJSON(value?: RegistrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationId': value.registrationId,
        'clientName': value.clientName,
        'registrationClass': value.registrationClass,
        'type': value.type,
        'status': StatusToJSON(value.status),
        'employeeData': EmployeesToJSON(value.employeeData),
        'shiftData': ShiftDtoToJSON(value.shiftData),
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'dateTimeRange': value.dateTimeRange,
        'quantity': value.quantity,
        'unit': value.unit,
    };
}

