import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7adcfa8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_RequestShiftFormDialog = _resolveComponent("RequestShiftFormDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideBar, {
      items: _ctx.sidebarItemsToShow,
      "util-items": _ctx.sidebarUtilItems,
      onItemClick: _ctx.sidebarItemClick,
      "profile-picture-url": _ctx.sidebarImg
    }, null, 8, ["items", "util-items", "onItemClick", "profile-picture-url"]),
    (_ctx.$store.state.selectedClient)
      ? (_openBlock(), _createBlock(_component_PageHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.selectedClient)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$store.state.selectedClient
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_RequestShiftFormDialog)
  ]))
}