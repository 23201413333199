/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditShiftRegistrationDto
 */
export interface EditShiftRegistrationDto {
    /**
     * The id of the registration. Registrations can have duplicate id's, depending on the registrationClass. Each registrationClass has its own id-range.
     * @type {number}
     * @memberof EditShiftRegistrationDto
     */
    registrationId: number;
    /**
     * The class of a registration, TIME_REGISTRATION for TimeRegistrationLines, FREELANCE_REGISTRATION for FreelanceRegistrationLines or SHIFTS_ONLY_REGISTRATION for TimeRegistrationLines that are for a shift (this is *not* a ShiftsOnlyRegistrationLine in maqqie_core).
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    registrationClass: EditShiftRegistrationDtoRegistrationClassEnum;
    /**
     * 
     * @type {number}
     * @memberof EditShiftRegistrationDto
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    breakDuration: string;
    /**
     * The amount of a registration (hours, kilometers, days or euro, depending on unit).
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    quantity: string;
    /**
     * The unit for amount-property.
     * @type {string}
     * @memberof EditShiftRegistrationDto
     */
    registrationUnit?: EditShiftRegistrationDtoRegistrationUnitEnum;
}


/**
 * @export
 */
export const EditShiftRegistrationDtoRegistrationClassEnum = {
    ShiftsOnlyRegistration: 'SHIFTS_ONLY_REGISTRATION',
    TimeRegistration: 'TIME_REGISTRATION',
    FreelanceRegistration: 'FREELANCE_REGISTRATION'
} as const;
export type EditShiftRegistrationDtoRegistrationClassEnum = typeof EditShiftRegistrationDtoRegistrationClassEnum[keyof typeof EditShiftRegistrationDtoRegistrationClassEnum];

/**
 * @export
 */
export const EditShiftRegistrationDtoRegistrationUnitEnum = {
    Uur: 'UUR',
    Hour: 'HOUR'
} as const;
export type EditShiftRegistrationDtoRegistrationUnitEnum = typeof EditShiftRegistrationDtoRegistrationUnitEnum[keyof typeof EditShiftRegistrationDtoRegistrationUnitEnum];


export function EditShiftRegistrationDtoFromJSON(json: any): EditShiftRegistrationDto {
    return EditShiftRegistrationDtoFromJSONTyped(json, false);
}

export function EditShiftRegistrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditShiftRegistrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationId': json['registrationId'],
        'registrationClass': json['registrationClass'],
        'employeeId': json['employeeId'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'breakDuration': json['breakDuration'],
        'quantity': json['quantity'],
        'registrationUnit': !exists(json, 'registrationUnit') ? undefined : json['registrationUnit'],
    };
}

export function EditShiftRegistrationDtoToJSON(value?: EditShiftRegistrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationId': value.registrationId,
        'registrationClass': value.registrationClass,
        'employeeId': value.employeeId,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'breakDuration': value.breakDuration,
        'quantity': value.quantity,
        'registrationUnit': value.registrationUnit,
    };
}

