const datepickerLocale = {
  clear: "reset",
  apply: "toepassen",
  cancel: "annuleren",
  today: "vandaag",
  weekHeader: "wk",
  firstDayOfWeek: 1,
  dateFormat: "mm/dd/yy",

  dayNames: [
    "Zondag",
    "Maandag",
    "Dinsdag",
    "Woensdag",
    "Donderdag",
    "Vrijdag",
    "Zaterdag",
  ],

  dayNamesShort: [
    "zo",
    "ma",
    "di",
    "wo",
    "do",
    "vr",
    "za",
  ],

  dayNamesMin: [
    "zo",
    "ma",
    "di",
    "wo",
    "do",
    "vr",
    "za",
  ],

  monthNames: [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ],

  monthNamesShort: [
    "jan",
    "feb",
    "mrt",
    "apr",
    "mei",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ],
};

export default {
  datepickerLocale,
};
