
import { defineComponent } from "vue";

export default defineComponent({
  name: "CheckBoxSmall",

  components: {},

  props: {
    id: {
      type: Number
    },
    checked: {
      type: Boolean,
    },
  },

  emits: ["change"],

  methods: {
    onChange($event: Event) {
      const isChecked = ($event.target as HTMLInputElement).checked;
      this.$emit("change", isChecked);
    },
  },
});
