import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b08ca7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_DashboardTodo = _resolveComponent("DashboardTodo")!
  const _component_DashboardShifts = _resolveComponent("DashboardShifts")!
  const _component_DashboardRegistrations = _resolveComponent("DashboardRegistrations")!

  return (_ctx.dashboardShown && _ctx.$store.state.selectedClient)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DashboardHeader),
        _createVNode(_component_DashboardTodo),
        (!_ctx.checkPermissions('bookkeeper'))
          ? (_openBlock(), _createBlock(_component_DashboardShifts, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.checkPermissions('planner') && !_ctx.checkPermissions('floormanager'))
          ? (_openBlock(), _createBlock(_component_DashboardRegistrations, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}