
import { defineComponent } from "vue";
import MicroModal from "micromodal";
import { Icon } from "mq-ui-kit/lib/Icon";

export default defineComponent({
  name: "FilterOverlay",

  components: {
    Icon,
  },

  props: {
    modalId: {
      type: String,
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: false,
    },
  },

  emits: ["show", "close", "update:isOpen"],

  data() {
    return {
      isMounted: false,
      overlayCloseButton: "xmark",
      closeTrigger: "data-micromodal-close-overlay",
    };
  },

  watch: {
    isOpen(newVal) {
      this.onChangeState(newVal);
    },
  },

  mounted() {
    this.isMounted = true;
    
    MicroModal.init({
      closeTrigger: this.closeTrigger,
      awaitOpenAnimation: true,
    });

    const modalEl = document.getElementById(this.modalId);
    if (!modalEl) {
      return;
    }

    const closeTriggers = modalEl.querySelectorAll(`[${this.closeTrigger}]`);
    for (let i = 0; i < closeTriggers.length; i += 1) {
      closeTriggers[i].addEventListener("click", this.close);
    }

    window.addEventListener("keyup", this.removeBlurredClass);
  },

  unmounted() {
    window.removeEventListener("keyup", this.removeBlurredClass);
  },

  methods: {
    show() {
      MicroModal.show(this.modalId, {
        awaitCloseAnimation: true,
      });

      this.$emit("show");
    },

    close(e: Event) {
      if (e) {
        if (e.target !== e.currentTarget) {
          return;
        }
        e.stopPropagation();
      }

      MicroModal.close(this.modalId);

      this.$emit("close");
      this.$emit("update:isOpen", false);
    },

    onChangeState(isOpen: boolean) {
      if (isOpen) {
        this.show();
        document.querySelector(".main")?.classList.add("blurred");
      } else {
        MicroModal.close(this.modalId);
        document.querySelector(".main")?.classList.remove("blurred");
      }
    },

    removeBlurredClass(event: KeyboardEvent) {
      event.preventDefault();
      if (event.code === "Escape") {
        document.querySelector(".main")?.classList.remove("blurred");
      }
    },
  },
});
