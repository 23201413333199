import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26fba33e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "applicants-prev" }
const _hoisted_2 = {
  key: 0,
  class: "avatars"
}
const _hoisted_3 = { class: "avatar__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (user, index) => {
            return (_openBlock(), _createBlock(_component_Avatar, {
              key: index,
              name: user.firstName,
              "last-name": user.lastName,
              "img-path": user.profilePictureUrl,
              "custom-size": 40,
              style: _normalizeStyle({ 'z-index': index + 1 })
            }, null, 8, ["name", "last-name", "img-path", "style"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("new_applicants", _ctx.numOfApplicants)), 1)
  ]))
}