import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cab43a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circle" }
const _hoisted_2 = { class: "el-upload__text" }
const _hoisted_3 = { class: "upload-item" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "upload-item__body" }
const _hoisted_6 = { class: "upload-item__top" }
const _hoisted_7 = { class: "upload-item__icon" }
const _hoisted_8 = { class: "upload-item__title" }
const _hoisted_9 = { class: "upload-item__size" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    ref: "uploadRef",
    drag: "",
    action: "https://jsonplaceholder.typicode.com/posts/",
    multiple: "",
    "before-upload": _ctx.beforeAvatarUpload,
    "on-exceed": _ctx.handleExceed,
    "auto-upload": false,
    limit: _ctx.limit,
    "on-change": _ctx.onChangeList,
    "on-remove": _ctx.onRemoveHook
  }, {
    file: _withCtx((item) => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "upload-item__delete",
          onClick: ($event: any) => (_ctx.handleRemove(item))
        }, [
          _createVNode(_component_FaIcon, { icon: ['fal', 'trash-can'] })
        ], 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FaIcon, { icon: ['fal', 'file'] })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(item.file.name), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.formatBytes(item.file.size)), 1)
            ])
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FaIcon, { icon: ['fal', 'upload'] })
      ]),
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("shift_request.drop_your_documents_here")) + " ", 1),
        _createElementVNode("em", null, _toDisplayString(_ctx.$t("shift_request.select_a_file")), 1)
      ])
    ]),
    _: 1
  }, 8, ["before-upload", "on-exceed", "limit", "on-change", "on-remove"]))
}