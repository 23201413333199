
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { Employees } from "@/services/rest/dashboard";
import { useErrorImgHandler } from "@/composables/error-img-hanlder";
import Avatar from "@/components/ui/Avatar.vue";

export default defineComponent({
  name: "ApplicantsPreview",
  components: {
    Avatar,
  },
  props: {
    items: {
      type: Array as PropType<Employees[]>,
      required: true,
    },
    numOfApplicants: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const { isImgError, handleErrorImg, imgErrorClass } = useErrorImgHandler();

    const nameInitials = (user: Employees) => user.lastName
        ? `${user.firstName?.charAt(0)}${user.lastName.charAt(0)}`
        : `${user.firstName?.charAt(0)}`;

    return { t, nameInitials, isImgError, handleErrorImg, imgErrorClass };
  },
});
