
import { defineComponent, PropType } from "vue";
import { CompanyClientReviewLabelOfEmployee } from "@/services/rest/dashboard";

export default defineComponent({
  name: "ExpandCollapse",
  data() {
    return {
      visible: false,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<CompanyClientReviewLabelOfEmployee[]>,
    }
  }
});
