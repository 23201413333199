/* tslint:disable */
/* eslint-disable */
/**
 * Company Clients API
 * API to get company clients data
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientOfUserResponse,
    CompanyClientOfUserResponseFromJSON,
    CompanyClientOfUserResponseFromJSONTyped,
    CompanyClientOfUserResponseToJSON,
} from './CompanyClientOfUserResponse';
import {
    PaginationPage,
    PaginationPageFromJSON,
    PaginationPageFromJSONTyped,
    PaginationPageToJSON,
} from './PaginationPage';

/**
 * 
 * @export
 * @interface CompanyClientsOfUserResponse
 */
export interface CompanyClientsOfUserResponse {
    /**
     * 
     * @type {Array<CompanyClientOfUserResponse>}
     * @memberof CompanyClientsOfUserResponse
     */
    resultList: Array<CompanyClientOfUserResponse>;
    /**
     * 
     * @type {PaginationPage}
     * @memberof CompanyClientsOfUserResponse
     */
    page: PaginationPage;
}

export function CompanyClientsOfUserResponseFromJSON(json: any): CompanyClientsOfUserResponse {
    return CompanyClientsOfUserResponseFromJSONTyped(json, false);
}

export function CompanyClientsOfUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientsOfUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultList': ((json['resultList'] as Array<any>).map(CompanyClientOfUserResponseFromJSON)),
        'page': PaginationPageFromJSON(json['page']),
    };
}

export function CompanyClientsOfUserResponseToJSON(value?: CompanyClientsOfUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultList': ((value.resultList as Array<any>).map(CompanyClientOfUserResponseToJSON)),
        'page': PaginationPageToJSON(value.page),
    };
}

