import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c2a5064"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "avatar__initials"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", [{ 'avatar--no-img': !_ctx.imgPath }, _ctx.customClazz]]),
    style: _normalizeStyle({
      height: _ctx.customSize + 'px',
      width: _ctx.customSize + 'px',
      'min-width': _ctx.customSize + 'px',
    })
  }, [
    (!_ctx.imgPath)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "avatar__initials",
          style: _normalizeStyle({
        'font-size': _ctx.customFontSize + 'px'
      })
        }, [
          (_ctx.name)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.nameInitials(_ctx.name, _ctx.lastName)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 4))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.isImgError)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass(["avatar__img", _ctx.imgErrorClass]),
                src: _ctx.imgPath,
                alt: `${_ctx.name} ${_ctx.lastName}`,
                onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleErrorImg && _ctx.handleErrorImg(...args)))
              }, null, 42, _hoisted_1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.nameInitials(_ctx.name, _ctx.lastName)), 1))
        ], 64)),
    (_ctx.isLikeable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "avatar__btn-like",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.likeMember && _ctx.likeMember(...args)))
        }, [
          (_ctx.isLiked)
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 0,
                class: "mq-p-icon__heart mq-p-icon__heart--red",
                icon: "heart"
              }))
            : (_openBlock(), _createBlock(_component_FaIcon, {
                key: 1,
                class: "mq-p-icon__heart",
                icon: ['fal', 'heart']
              }))
        ]))
      : _createCommentVNode("", true)
  ], 6))
}