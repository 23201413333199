/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetRegistrationsResponse,
    GetRegistrationsResponseFromJSON,
    GetRegistrationsResponseToJSON,
    RequiredActionsResponse,
    RequiredActionsResponseFromJSON,
    RequiredActionsResponseToJSON,
    UpcomingShiftResponse,
    UpcomingShiftResponseFromJSON,
    UpcomingShiftResponseToJSON,
} from '../models';

export interface GetRegistrationsByCompanyClientIdRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpcomingShiftsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     * List of registrations for a company client with various statuses.
     * Get the newest registrations for company client
     */
    async getRegistrationsByCompanyClientIdRaw(requestParameters: GetRegistrationsByCompanyClientIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GetRegistrationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/dashboard/registrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRegistrationsResponseFromJSON(jsonValue));
    }

    /**
     * List of registrations for a company client with various statuses.
     * Get the newest registrations for company client
     */
    async getRegistrationsByCompanyClientId(requestParameters: GetRegistrationsByCompanyClientIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetRegistrationsResponse> {
        const response = await this.getRegistrationsByCompanyClientIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint collects count of required actions to be performed on other screens. Actions include registrations to approve, applicants to approve and how many shifts are planned for the upcoming period in time.
     * Get all user required actions
     */
    async getRequiredActionsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequiredActionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/dashboard/required-actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequiredActionsResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint collects count of required actions to be performed on other screens. Actions include registrations to approve, applicants to approve and how many shifts are planned for the upcoming period in time.
     * Get all user required actions
     */
    async getRequiredActions(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequiredActionsResponse> {
        const response = await this.getRequiredActionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get upcoming company client shifts that haven\'t yet, or already started but are not finished.
     * Get all upcoming company client shifts
     */
    async upcomingShiftsRaw(requestParameters: UpcomingShiftsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UpcomingShiftResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearerAuth", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-COMPANY-CLIENT-ID"] = this.configuration.apiKey("X-COMPANY-CLIENT-ID"); // companyClientId authentication
        }

        const response = await this.request({
            path: `/dashboard/upcoming-shifts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UpcomingShiftResponseFromJSON));
    }

    /**
     * Get upcoming company client shifts that haven\'t yet, or already started but are not finished.
     * Get all upcoming company client shifts
     */
    async upcomingShifts(requestParameters: UpcomingShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UpcomingShiftResponse>> {
        const response = await this.upcomingShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
