/* For local development */

// TRUE if ure using local LOCALHOST
// FALSE for PRODUCTION
const isLocalDevEnv = process.env.NODE_ENV !== "production" || window.location.host === "localhost:8080";
// const isLocalEnv = window.location.host === "localhost:8080";

// default realm for local develop
// options: cp-maqqie, cp-mep
const currentRealm = "cp-maqqie" as string;

const keycloakDomainLocal =
  currentRealm === "cp-maqqie"
    ? "https://dev-client-portal.maqqie.app/auth"
    : "https://dev-clientportal.miseenplace.nl/auth";

/* END */

// realm client name
const clientName = "client-portal";
const keycloakDomain = `${window.location.origin}/auth`;

// for local develop switch
const currentRealmDomain = isLocalDevEnv ? keycloakDomainLocal : keycloakDomain;

function initKcConfig(): Promise<Record<string, unknown>> {
  // if (isLocalEnv) {
  //   return new Promise((res) => {
  //     const kcConfig = {
  //       clientId: clientName,
  //       url: "http://localhost:8180/auth",
  //       realm: "cp-maqqie",
  //     };
  //     res(kcConfig);
  //   });
  // }
  if (isLocalDevEnv) {
    return new Promise((res) => {
      const kcConfig = {
        clientId: clientName,
        url: currentRealmDomain,
        realm: currentRealm,
      };
      res(kcConfig);
    });
  }

  return fetch(`${window.location.origin}/rpc/tenant-name`)
    .then((res) => {
      if (!res.ok) {
        const h1 = document.createElement("h1");
        const errorText = document.createTextNode("Error: Tenant not configured");
        h1.appendChild(errorText);

        h1.style.textAlign = "center";
        h1.style.marginTop = "150px";
        h1.style.fontSize = "60px";

        document.querySelector("#app")?.appendChild(h1);

        throw Error("Tenant not configured.");
      }

      return res.text();
    })
    .then((res) => {
      const kcConfig = {
        clientId: clientName,
        url: currentRealmDomain,
        realm: res,
      };

      return kcConfig;
    });
}

export default initKcConfig;
