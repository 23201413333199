
import { defineComponent } from "vue";
import CardWrap from "@/components/ui/cards/CardWrap.vue";

export default defineComponent({
  name: "CardUpcomingShiftSkeleton",
  components: {
    CardWrap,
  },
  setup() {
    return {};
  },
});
