import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-458b99f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTableHeaderItem = _resolveComponent("DataTableHeaderItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "table-header-inner",
      style: _normalizeStyle({ 'grid-template-columns': _ctx.gridColumnTemplates })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
        return (_openBlock(), _createBlock(_component_DataTableHeaderItem, {
          key: column.id,
          ref_for: true,
          ref: column.id,
          column: column,
          onHeaderItemSortClick: _ctx.onHeaderItemClick
        }, null, 8, ["column", "onHeaderItemSortClick"]))
      }), 128))
    ], 4)
  ]))
}