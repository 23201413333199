/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientEmployeeReviewer,
    CompanyClientEmployeeReviewerFromJSON,
    CompanyClientEmployeeReviewerFromJSONTyped,
    CompanyClientEmployeeReviewerToJSON,
} from './CompanyClientEmployeeReviewer';
import {
    CompanyClientReviewLabelOfEmployee,
    CompanyClientReviewLabelOfEmployeeFromJSON,
    CompanyClientReviewLabelOfEmployeeFromJSONTyped,
    CompanyClientReviewLabelOfEmployeeToJSON,
} from './CompanyClientReviewLabelOfEmployee';

/**
 * 
 * @export
 * @interface CompanyClientReviewOnShift
 */
export interface CompanyClientReviewOnShift {
    /**
     * 
     * @type {CompanyClientEmployeeReviewer}
     * @memberof CompanyClientReviewOnShift
     */
    reviewer?: CompanyClientEmployeeReviewer;
    /**
     * Review score of this employee
     * @type {number}
     * @memberof CompanyClientReviewOnShift
     */
    reviewScore: number;
    /**
     * Additional notes after on review
     * @type {string}
     * @memberof CompanyClientReviewOnShift
     */
    note?: string;
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientReviewOnShift
     */
    labelsPositive: Array<CompanyClientReviewLabelOfEmployee>;
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientReviewOnShift
     */
    labelsToImprove: Array<CompanyClientReviewLabelOfEmployee>;
    /**
     * Shift's gig name
     * @type {string}
     * @memberof CompanyClientReviewOnShift
     */
    gigName: string;
    /**
     * Time when shift starts
     * @type {Date}
     * @memberof CompanyClientReviewOnShift
     */
    shiftStartTimestamp: Date;
    /**
     * Shift ID for which the review is given
     * @type {number}
     * @memberof CompanyClientReviewOnShift
     */
    shiftId: number;
}

export function CompanyClientReviewOnShiftFromJSON(json: any): CompanyClientReviewOnShift {
    return CompanyClientReviewOnShiftFromJSONTyped(json, false);
}

export function CompanyClientReviewOnShiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientReviewOnShift {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewer': !exists(json, 'reviewer') ? undefined : CompanyClientEmployeeReviewerFromJSON(json['reviewer']),
        'reviewScore': json['reviewScore'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'labelsPositive': ((json['labelsPositive'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
        'labelsToImprove': ((json['labelsToImprove'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
        'gigName': json['gigName'],
        'shiftStartTimestamp': (new Date(json['shiftStartTimestamp'])),
        'shiftId': json['shiftId'],
    };
}

export function CompanyClientReviewOnShiftToJSON(value?: CompanyClientReviewOnShift | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewer': CompanyClientEmployeeReviewerToJSON(value.reviewer),
        'reviewScore': value.reviewScore,
        'note': value.note,
        'labelsPositive': ((value.labelsPositive as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
        'labelsToImprove': ((value.labelsToImprove as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
        'gigName': value.gigName,
        'shiftStartTimestamp': (value.shiftStartTimestamp.toISOString()),
        'shiftId': value.shiftId,
    };
}

