
import { defineComponent } from "vue";

export default defineComponent({
  name: "PeopleCountTag",
  props: {
    needed: {
      type: Number,
      required: true,
    },
    accepted: {
      type: Number,
      required: true,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    peopleRightTag: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    customClass() {
      if (this.colored) {
        return this.colored && this.needed <= this.accepted
          ? "ppl-tag--blue"
          : "ppl-tag--red";
      }
      return "";
    },
  },
});
