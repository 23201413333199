
import { defineComponent, PropType } from "vue";

import { ColumnInfo } from "..";

import DataTableHeaderItem from "../DataTableHeaderItem.vue";

type Order = null | "asc" | "desc";

export default defineComponent({
  name: "DataTableHeader",
  components: { 
    DataTableHeaderItem
  },
  props: {
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      default: () => [],
    },
    sortable: {
      type: Boolean,
    },
  },

  data() {
    return {
      sortOrder: null as Order,
    };
  },

  emits: ["header-item-click"],

  computed: {
    gridColumnTemplates() {
      let templates = "";

      this.columns.forEach((column) => {
        templates += `${column.width || "1fr"} `;
      });

      return templates.trim();
    },
    sortClass() {
      return `sort--${this.sortOrder}`;
    },
  },

  methods: {
    onHeaderItemClick(item: any) {
      this.$emit("header-item-click", item);
    },
  },
});
