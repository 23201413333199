import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37f81904"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-container disable-scrollbars" }
const _hoisted_3 = { class: "close-container" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      id: _ctx.modalId,
      class: "mq-overlay micromodal-slide"
    }, [
      _createElementVNode("div", _normalizeProps({
        class: "overlay",
        [_ctx.closeTrigger || ""]: ''
      }), [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", _normalizeProps({
              class: "close",
              [_ctx.closeTrigger || ""]: ''
            }), [
              _createVNode(_component_Icon, {
                class: "icon",
                icon: _ctx.overlayCloseButton
              }, null, 8, ["icon"])
            ], 16)
          ]),
          _createElementVNode("main", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])
      ], 16)
    ], 8, _hoisted_1)), [
      [_directive_click_away, _ctx.close]
    ])
  ]))
}