import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

// Solid icons - import alphabetically
import {
  faAngleLeft,
  faAngleRight,
  faBarsStaggered,
  faBarsFilter,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarDays,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faEye,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faFile,
  faFilter,
  faFlag,
  faGear,
  faGift,
  faGrid,
  faHandshake,
  faHeart,
  faHome,
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faMessage,
  faPen,
  faPhone,
  faPlus,
  faMinus,
  faSignal,
  faStar,
  faUser,
  faUsers,
  faUserCheck,
  faUserGroup,
  faUserPlus,
  faQuestion,
  faXmark,
  faTrash,
  faRightFromBracket,
  faCircleQuestion,
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/pro-solid-svg-icons";

// Regular icons - import alphabetically
import {
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faArrowsRepeat as farArrowsRepeat,
  faCalendar as farCalendar,
  faCircleDot as farCircleDot,
  faEnvelope as farEnvelope,
  faFile as farFile,
  faImage as farImage,
  faUpload as farUpload,
  faTrashCan as farTrashCan,
  faHeart as farHearth,
  faThumbsUp as farThumbsUp
} from "@fortawesome/pro-regular-svg-icons";

// Light icons
import {
  faCalendar as falCalendar,
  faLocationDot as falLocationDot,
  faClock as falClock,
  faTrashCan as falTrashCan,
  faFile as falFile,
  faUpload as falUpload,
  faHeart as falHeart,
  faStar as falStar,
  faPen as falPen,
} from "@fortawesome/pro-light-svg-icons";

// Solid icons
library.add(
  faAngleLeft,
  faAngleRight,
  faBarsStaggered,
  faBarsFilter,
  faBuilding,
  faBriefcase,
  faCalendar,
  faCalendarDays,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faEye,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faFile,
  faFilter,
  faFlag,
  faGear,
  faGift,
  faGrid,
  faHandshake,
  faHeart,
  faHome,
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faMessage,
  faPen,
  faPhone,
  faPlus,
  faMinus,
  faSignal,
  faStar,
  faUser,
  faUsers,
  faUserCheck,
  faUserGroup,
  faUserPlus,
  faQuestion,
  faXmark,
  faTrash,
  faRightFromBracket,
  faCircleQuestion,
  faSortUp,
  faSortDown,
  faSort,
);

// Regular icons
library.add(
  farAngleDown,
  farAngleUp,
  farArrowsRepeat,
  farCalendar,
  farCircleDot,
  farEnvelope,
  farFile,
  farImage,
  farUpload,
  farTrashCan,
  farHearth,
  farThumbsUp
);

// Light icons
library.add(
  falClock,
  falCalendar,
  falLocationDot,
  falTrashCan,
  falFile,
  falUpload,
  falHeart,
  falStar,
  falPen,
);

export default FontAwesomeIcon;
