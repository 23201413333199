
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ToastService from "mq-ui-kit/lib/toast";
import CardUpcomingShift from "@/components/ui/cards/CardUpcomingShift.vue";
import CardUpcomingShiftSkeleton from "@/components/ui/cards/CardUpcomingShiftSkeleton.vue";
import SvgBell from "@/components/svg/SvgBell.vue";
import { UpcomingShiftsRequest, UpcomingShiftResponse } from "@/services/rest/dashboard";
import ActionTypes from "@/store/types/action-types";

export default defineComponent({
  name: "DashboardShifts",
  components: {
    CardUpcomingShift,
    CardUpcomingShiftSkeleton,
    SvgBell,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const errorToggle = ref(false);
    const isLoading = ref(false);

    const params: UpcomingShiftsRequest = {
      size: 3,
      sort: ["shift_date,asc"],
    };

    /* eslint-disable-next-line */
    const customUpcomingShifts = ref<any>([]);
    const numOfSkeletonShifts = ref(3);
    const originalUpcomingShifts = ref<UpcomingShiftResponse[]>();

    const createShiftList = (arr: UpcomingShiftResponse[]) => {
      if (arr.length < 3) {
        /* eslint-disable-next-line */
        const dummyList: any = new Array(3 - arr.length).fill(0).map(() => ({}));
        const customList = arr.concat(dummyList);
        return customList;
      }

      return arr.slice(0, 3);
    };

    onMounted(() => {
      errorToggle.value = false;
      isLoading.value = true;

      store
        .dispatch(ActionTypes.GET_UPCOMING_SHIFTS, params)
        .then((res) => {
          customUpcomingShifts.value = createShiftList(res);
          originalUpcomingShifts.value = res;
        })
        .catch((err) => {
          console.log("Error:", err);
          errorToggle.value = true;
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      errorToggle,
      customUpcomingShifts,
      isLoading,
      numOfSkeletonShifts,
      originalUpcomingShifts,
    };
  },
});
