import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c00e1226"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "shift-sidebar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShiftDetailsSidebarItemSkeleton = _resolveComponent("ShiftDetailsSidebarItemSkeleton")!
  const _component_ShiftDetailsSidebarItem = _resolveComponent("ShiftDetailsSidebarItem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isLoading && !_ctx.firstPageLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.perPageSkeleton, (item) => {
          return (_openBlock(), _createBlock(_component_ShiftDetailsSidebarItemSkeleton, { key: item }))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.sidebarItems)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "shift-sidebar",
              onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.debouncedFn && _ctx.debouncedFn(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarItems, (item, index) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: item.shiftId,
                  class: "shift-sidebar__link",
                  to: { name: 'ShiftDetailsDescription', params: { id: item.shiftId } },
                  id: `shift-details-sidebar__${item.shiftId}`,
                  replace: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShiftDetailsSidebarItem, {
                      item: item,
                      "is-active": _ctx.activeIndexHolder === index
                    }, null, 8, ["item", "is-active"])
                  ]),
                  _: 2
                }, 1032, ["to", "id"]))
              }), 128))
            ], 32))
          : _createCommentVNode("", true)
      ], 64))
}