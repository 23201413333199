import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a688207"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-wrapper", {'has-left-icon': _ctx.iconLeft, 'has-right-icon': _ctx.iconRight}])
  }, [
    (_ctx.modelValue)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "icon icon-xmark",
          icon: "xmark",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeInputValue()))
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.iconRight || _ctx.iconLeft)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: "icon",
                icon: "magnifying-glass"
              }))
            : _createCommentVNode("", true)
        ], 64)),
    _createElementVNode("input", {
      value: _ctx.modelValue,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
      placeholder: _ctx.placeholder
    }, null, 40, _hoisted_1)
  ], 2))
}