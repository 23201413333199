/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationPage,
    PaginationPageFromJSON,
    PaginationPageFromJSONTyped,
    PaginationPageToJSON,
} from './PaginationPage';
import {
    ShiftRegistrationDto,
    ShiftRegistrationDtoFromJSON,
    ShiftRegistrationDtoFromJSONTyped,
    ShiftRegistrationDtoToJSON,
} from './ShiftRegistrationDto';

/**
 * 
 * @export
 * @interface RegistrationsForCompanyClientResponse
 */
export interface RegistrationsForCompanyClientResponse {
    /**
     * An array, containing all registrations (shifts-only-, time- and freelance-registrations).
     * @type {Array<ShiftRegistrationDto>}
     * @memberof RegistrationsForCompanyClientResponse
     */
    registrations: Array<ShiftRegistrationDto>;
    /**
     * 
     * @type {PaginationPage}
     * @memberof RegistrationsForCompanyClientResponse
     */
    page: PaginationPage;
}

export function RegistrationsForCompanyClientResponseFromJSON(json: any): RegistrationsForCompanyClientResponse {
    return RegistrationsForCompanyClientResponseFromJSONTyped(json, false);
}

export function RegistrationsForCompanyClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationsForCompanyClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrations': ((json['registrations'] as Array<any>).map(ShiftRegistrationDtoFromJSON)),
        'page': PaginationPageFromJSON(json['page']),
    };
}

export function RegistrationsForCompanyClientResponseToJSON(value?: RegistrationsForCompanyClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrations': ((value.registrations as Array<any>).map(ShiftRegistrationDtoToJSON)),
        'page': PaginationPageToJSON(value.page),
    };
}

