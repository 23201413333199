
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Button } from "mq-ui-kit/lib/Button";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import ActionTypes from "@/store/types/action-types";
import MutationTypes from "@/store/types/mutation-types";
import {
  CompanyClientShiftsRequest,
  ShiftFilterShiftTypeEnum,
  ShiftResponse,
  NumberOfCompanyClientShiftsResponse,
  ShiftFilter,
  CompanyClientShiftsResponse,
} from "@/services/rest/dashboard";

import TabFilter from "@/components/ui/TabFilter.vue";
import CardUpcomingShiftInline from "@/components/ui/cards/CardUpcomingShiftInline.vue";
import CardUpcomingShiftInlineSkeleton from "@/components/ui/cards/CardUpcomingShiftInlineSkeleton.vue";
import NoRecord from "@/components/ui/DataTable/NoRecord/NoRecord.vue";

import { TabFilterInterface } from "@/types/global";

export default defineComponent({
  name: "ShiftsOverview",
  components: {
    CardUpcomingShiftInline,
    TabFilter,
    Button,
    CardUpcomingShiftInlineSkeleton,
    NoRecord,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const errorToggle = ref(false);
    const isLoading = ref(false);
    const customUpcomingShifts = ref<ShiftResponse[]>([]);
    const dashboardShown = computed(() => store.state.isDashboardShown);
    const activeTabIndex = ref(1);

    const tabItems = ref<TabFilterInterface[]>([
      {
        name: t("global.all_upcoming"),
        type: ShiftFilterShiftTypeEnum.Upcoming,
        numOfItems: 0,
      },
      {
        name: t("global.open"),
        type: ShiftFilterShiftTypeEnum.Open,
        numOfItems: 0,
      },
      {
        name: t("global.filled"),
        type: ShiftFilterShiftTypeEnum.Filled,
        numOfItems: 0,
      },
      {
        name: t("global.past"),
        type: ShiftFilterShiftTypeEnum.Past,
        numOfItems: 0,
      },
    ]);

    // pagination settings
    const currentPage = ref(1);
    const shiftCount = ref(0);
    const perPage = ref(6);
    const selectedTabType = ref<ShiftFilterShiftTypeEnum>(
      ShiftFilterShiftTypeEnum.Upcoming
    );

    // TODO: remove maybe this when there would be many shifts to reduce layout shift
    const perPageSkeleton = computed(() => perPage.value);

    const openRequestShiftDialog = () => {
      store.commit(MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN, true);
    };

    const numberOfShiftsHolder = ref<NumberOfCompanyClientShiftsResponse>();

    const setQueryParams = (selectedTab: string, currPage = 1) => {
      router.replace({ path: "/shifts-overview", query: { page: currPage, tab: selectedTab }  });
    };

    const getCompanyClientsShiftsByType = (type: ShiftFilterShiftTypeEnum) => {
      const shiftFilter: ShiftFilter = {
        shiftType: type,
      };

      setQueryParams(selectedTabType.value, currentPage.value);

      const params: CompanyClientShiftsRequest = {
        shiftFilter,
        size: perPage.value,
        page: currentPage.value - 1,
      };

      isLoading.value = true;
      errorToggle.value = false;

      store
        .dispatch(ActionTypes.GET_COMPANY_CLIENTS_SHIFTS, params)
        .then((res: CompanyClientShiftsResponse) => {
          customUpcomingShifts.value = res.resultList;
          shiftCount.value = res.page.totalElements;
        })
        .catch((err) => {
          console.log("Error:", err);
          errorToggle.value = true;
          customUpcomingShifts.value = [];
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const tabClicked = (index: number, isQueryParam: boolean) => {
      if(!isQueryParam) {
        currentPage.value = 1;
      }

      selectedTabType.value = tabItems.value[index].type;
      getCompanyClientsShiftsByType(tabItems.value[index].type);
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      getCompanyClientsShiftsByType(selectedTabType.value);
    };

    const getNumberOfAllShifts = () => {
      store
        .dispatch(ActionTypes.NUMBER_OF_SHIFTS)
        .then((res) => {
          numberOfShiftsHolder.value = {
            numberOfFilledShifts: res.numberOfFilledShifts,
            numberOfOpenShifts: res.numberOfOpenShifts,
            numberOfPastShifts: res.numberOfPastShifts,
            numberOfUpcomingShifts: res.numberOfUpcomingShifts,
            numberOfShifts: res.numberOfShifts,
          };

          tabItems.value.forEach((item: TabFilterInterface, index: number) => {
            if (item.type === ShiftFilterShiftTypeEnum.Open) {
              tabItems.value[index].numOfItems = numberOfShiftsHolder.value
                ?.numberOfOpenShifts as number;
            }
            if (item.type === ShiftFilterShiftTypeEnum.Upcoming) {
              tabItems.value[index].numOfItems = numberOfShiftsHolder.value
                ?.numberOfUpcomingShifts as number;
            }
            if (item.type === ShiftFilterShiftTypeEnum.Filled) {
              tabItems.value[index].numOfItems = numberOfShiftsHolder.value
                ?.numberOfFilledShifts as number;
            }
            if (item.type === ShiftFilterShiftTypeEnum.Past) {
              tabItems.value[index].numOfItems = numberOfShiftsHolder.value
                ?.numberOfPastShifts as number;
            }
          });
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    };

    const shiftClick = (item: ShiftResponse) => {
      router.push({ name: "ShiftDetailsDescription", params: { id: item.shiftId } });
    };

    onMounted(() => {
      if(route.query.page) currentPage.value = parseInt(route.query.page as string, 10);

      if(route.query.tab) {
        selectedTabType.value = route.query.tab as ShiftFilterShiftTypeEnum;
        activeTabIndex.value = tabItems.value.findIndex((item: TabFilterInterface) => route.query.tab === item.type);
      }

      getCompanyClientsShiftsByType(selectedTabType.value);

      getNumberOfAllShifts();
    });

    return {
      dashboardShown,
      customUpcomingShifts,
      errorToggle,
      isLoading,
      currentPage,
      onPageChange,
      tabItems,
      tabClicked,
      shiftCount,
      perPage,
      perPageSkeleton,
      selectedTabType,
      openRequestShiftDialog,
      shiftClick,
      activeTabIndex,
    };
  },
});
