
import { computed, defineComponent, PropType, toRefs } from "vue";
import { useStore } from "vuex";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import ApplicantsPreview from "@/components/ui/ApplicantsPreview.vue";
import ProgressBarEmployee from "@/components/ui/ProgressBarEmployee.vue";
import { UpcomingShiftResponse } from "@/services/rest/dashboard";
import MutationTypes from "@/store/types/mutation-types";
import localeList from "@/helpers/langLocale";

export default defineComponent({
  name: "CardUpcomingShift",
  components: {
    CardWrap,
    ProgressBarEmployee,
    ApplicantsPreview,
  },
  props: {
    item: {
      type: Object as PropType<UpcomingShiftResponse>,
      required: true,
    },
    // add it if u want to have empty states for adding new shifts
    position: {
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();

    const itemProp = toRefs(props.item);

    const openRequestShiftDialog = () => {
      store.commit(MutationTypes.SET_IS_REQ_SHIFT_MODAL_OPEN, true);
    };

    // TODO: to composables
    const formatTimeToHoursMinutes = (time: Date) => {
      const date = new Date(time);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const shiftDate = computed(() => {
      const options = {
        day: "2-digit",
        month: "short",
      } as const;
      const properDate = new Date(itemProp.startTimestamp.value);

      if (localeList.dutchLocaleList.includes(window.navigator.language)) {
        const customDate = properDate.toLocaleDateString("nl-NL", options).split(" ");

        // remove dot on dutch translations
        customDate[1] = customDate[1].replace(/\.$/, "");
        return customDate;
      }

      return properDate.toLocaleDateString("en-GB", options).split(" ");
    });

    const startEndShift = computed(() => {
      const one = new Date(itemProp.startTimestamp.value);
      const two = new Date(itemProp.endTimestamp.value);

      return `${formatTimeToHoursMinutes(one)} - ${formatTimeToHoursMinutes(two)}`;
    });

    const imgUsers = computed(() => itemProp.newApplicants.value.slice(0, 3));

    return {
      shiftDate,
      startEndShift,
      imgUsers,
      openRequestShiftDialog
    };
  },
});
