import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba762f48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dash-header" }
const _hoisted_2 = { class: "user" }
const _hoisted_3 = { class: "user__img" }
const _hoisted_4 = { class: "user__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$keycloak.tokenParsed?.given_name.charAt(0)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("hello")) + ", " + _toDisplayString(_ctx.$keycloak.tokenParsed?.given_name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("today_is")) + " " + _toDisplayString(_ctx.customDate), 1)
      ])
    ]),
    (!_ctx.checkPermissions('bookkeeper'))
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          size: "medium",
          class: "user__btn",
          onClick: _ctx.openRequestShiftDialog
        }, {
          default: _withCtx(() => [
            _createTextVNode(" + " + _toDisplayString(_ctx.$t("global.add_new_request")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}