import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc60af12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-upcom__date" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "card-upcom__text-wrap" }
const _hoisted_4 = { class: "card-upcom__desc" }
const _hoisted_5 = { class: "card-upcom__text" }
const _hoisted_6 = { class: "card-upcom__time" }
const _hoisted_7 = { class: "card-upcom__location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeopleCountTag = _resolveComponent("PeopleCountTag")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_ApplicantsPreview = _resolveComponent("ApplicantsPreview")!
  const _component_CardWrap = _resolveComponent("CardWrap")!

  return (_openBlock(), _createBlock(_component_CardWrap, {
    class: _normalizeClass(["card-upcom card-upcom--padding-fix card-upcom--order", { 'card-upcom--empty': _ctx.imgUsers }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.shiftDate[0]), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.shiftDate[1]), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["card-upcom__img", _ctx.useRandomBg(_ctx.item.shiftId)])
      }, [
        (_ctx.item.shiftCoverImageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: _normalizeClass(_ctx.imgErrorClass),
              src: _ctx.item.shiftCoverImageUrl,
              alt: _ctx.item.gigName,
              onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleErrorImg && _ctx.handleErrorImg(...args)))
            }, null, 42, _hoisted_2))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.item.gigName), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PeopleCountTag, {
            colored: "",
            needed: _ctx.item.numberOfEmployeesNeeded,
            accepted: _ctx.item.numberOfEmployeesAccepted
          }, null, 8, ["needed", "accepted"]),
          _createElementVNode("p", _hoisted_6, [
            _createVNode(_component_FaIcon, {
              class: "mq-p-icon",
              icon: "clock"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.startEndShift), 1)
          ]),
          _createElementVNode("p", _hoisted_7, [
            _createVNode(_component_FaIcon, {
              class: "mq-p-icon",
              icon: "location-dot"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.item.workLocation), 1)
          ])
        ])
      ]),
      _createVNode(_component_ApplicantsPreview, {
        items: _ctx.imgUsers,
        "num-of-applicants": _ctx.item.numberOfNewApplicants
      }, null, 8, ["items", "num-of-applicants"])
    ]),
    _: 1
  }, 8, ["class"]))
}