import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0296deeb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "reg-over"
}
const _hoisted_2 = { class: "reg-over__header" }
const _hoisted_3 = { class: "reg-over__title" }
const _hoisted_4 = { class: "user-info" }
const _hoisted_5 = { class: "user-info__name" }
const _hoisted_6 = {
  key: 0,
  class: "user-info__function ellipsis"
}
const _hoisted_7 = { class: "reg-over__amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabFilter = _resolveComponent("TabFilter")!
  const _component_SelectAll = _resolveComponent("SelectAll")!
  const _component_ProcessRegistrationLinesBar = _resolveComponent("ProcessRegistrationLinesBar")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_NoRecord = _resolveComponent("NoRecord")!
  const _component_RegistrationListItemCheckbox = _resolveComponent("RegistrationListItemCheckbox")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_ListItemDate = _resolveComponent("ListItemDate")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CardWrap = _resolveComponent("CardWrap")!
  const _component_EditRegistrationLineModal = _resolveComponent("EditRegistrationLineModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dashboardShown && _ctx.$store.state.selectedClient)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("routes.registrations_overview")), 1)
          ]),
          _createVNode(_component_TabFilter, {
            items: _ctx.tabItems,
            onClick: _ctx.tabClicked
          }, null, 8, ["items", "onClick"]),
          (_ctx.registrationList.length && !_ctx.isLoading && !_ctx.hideSelector)
            ? (_openBlock(), _createBlock(_component_SelectAll, {
                key: 0,
                checked: _ctx.selectAllIsChecked,
                onChange: _ctx.onSelectAllChange
              }, null, 8, ["checked", "onChange"]))
            : _createCommentVNode("", true),
          (_ctx.selectedRegistrationsCount > 0 && _ctx.registrationList.length && !_ctx.hideSelector)
            ? (_openBlock(), _createBlock(_component_ProcessRegistrationLinesBar, {
                key: 1,
                "item-count": _ctx.selectedRegistrationsCount,
                onApprove: _ctx.approveRegistrationLines
              }, null, 8, ["item-count", "onApprove"]))
            : _createCommentVNode("", true),
          _createVNode(_component_CardWrap, {
            class: _normalizeClass([{'reg-over__table-wrap-checkbox': _ctx.selectAllIsChecked || _ctx.selectedRegistrationsCount}, "reg-over__table-wrap"])
          }, {
            default: _withCtx(() => [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_Spinner, {
                    key: 0,
                    spinning: _ctx.isLoading,
                    inline: true
                  }, null, 8, ["spinning"]))
                : _createCommentVNode("", true),
              (!_ctx.registrationList.length && !_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_NoRecord, { key: 1 }))
                : (_openBlock(), _createBlock(_component_DataTable, {
                    key: _ctx.componentKey,
                    class: "reg-over__table",
                    columns: _ctx.regColumns,
                    items: _ctx.registrationList,
                    "item-logo-link": "logoLink",
                    "item-select-line": "selectLine",
                    "item-name": "name",
                    "item-registration-type": "registrationType",
                    "item-shift": "shift",
                    "item-date": "date",
                    "item-total": "total",
                    "item-status": "status",
                    onScrollReachedBottom: _ctx.onScrollReachedBottom,
                    onHeaderItemClick: _ctx.onHeaderItemClick,
                    "total-elements": _ctx.totalElements
                  }, _createSlots({
                    item_logoLink: _withCtx(({ item }) => [
                      _createVNode(_component_Avatar, {
                        name: item.employeeData.firstName,
                        "last-name": item.employeeData.lastName,
                        "img-path": item.employeeData.profilePictureUrl
                      }, null, 8, ["name", "last-name", "img-path"])
                    ]),
                    item_name: _withCtx(({ item }) => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(item.employeeData.firstName) + " " + _toDisplayString(item.employeeData.lastName), 1),
                        (item.employeeData.functionName)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.employeeData.functionName), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    item_registrationType: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.type), 1)
                    ]),
                    item_shift: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.shiftData?.shiftName ?? _ctx.$t('not_applicable')), 1)
                    ]),
                    item_date: _withCtx(({ item }) => [
                      _createVNode(_component_ListItemDate, {
                        "start-date": item.startDate,
                        "start-time": item.dateTimeRange.startTimestamp,
                        "end-time": item.dateTimeRange.endTimestamp
                      }, null, 8, ["start-date", "start-time", "end-time"])
                    ]),
                    item_total: _withCtx(({ item }) => [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.formatTotalTime(item.quantity)) + " " + _toDisplayString(item.unit), 1)
                    ]),
                    item_status: _withCtx(({ item }) => [
                      _createVNode(_component_Tag, {
                        color: item.status
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.customMsg(item.status)), 1)
                        ]),
                        _: 2
                      }, 1032, ["color"])
                    ]),
                    _: 2
                  }, [
                    (!_ctx.hideSelector)
                      ? {
                          name: "item_selectLine",
                          fn: _withCtx(({ item }) => [
                            _createVNode(_component_RegistrationListItemCheckbox, {
                              id: item.registrationId,
                              "is-checked": 
              _ctx.checkIfRegistrationIdExistsInList(
                item.registrationId,
                item.registrationClass
              )
            ,
                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                              onChange: ($event: any) => (
              _ctx.onChange({
                isChecked: $event,
                registrationId: item.registrationId,
                registrationClass: item.registrationClass,
              })
            )
                            }, null, 8, ["id", "is-checked", "onChange"])
                          ]),
                          key: "0"
                        }
                      : undefined,
                    (!_ctx.hideSelector)
                      ? {
                          name: "item_menu",
                          fn: _withCtx(({ item }) => [
                            _createVNode(_component_Dropdown, { class: "tab-general__dropdown" }, {
                              "dropdown-menu": _withCtx(() => [
                                _createVNode(_component_DropdownItem, {
                                  onClick: ($event: any) => (_ctx.editRegistration(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('global.edit')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_Icon, { icon: "ellipsis" })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          key: "1"
                        }
                      : undefined
                  ]), 1032, ["columns", "items", "onScrollReachedBottom", "onHeaderItemClick", "total-elements"]))
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_EditRegistrationLineModal, {
      "is-open": _ctx.isModalOpen,
      registration: _ctx.selectedRegistration,
      onClose: _ctx.closeModal,
      onCancelDialog: _ctx.cancelDialog
    }, null, 8, ["is-open", "registration", "onClose", "onCancelDialog"])
  ], 64))
}