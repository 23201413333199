
import { computed, defineComponent, inject, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { SideBar } from "mq-ui-kit/lib/SideBar";
import { getCookie, setCookie } from "typescript-cookie";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ActionTypes from "@/store/types/action-types";
import MutationTypes from "@/store/types/mutation-types";
import { CookieProps, Roles } from "@/types/global";
import PageHeader from "@/components/ui/PageHeader.vue";
import RequestShiftFormDialog from "@/components/request-shift-form/RequestShiftFormDialog.vue";
import { logoutClearCookie } from "@/helpers/helper-methods";
import {
  CompanyClientsOfUserResponse,
  GetForUserRequest,
} from "@/services/rest/dashboard";

export default defineComponent({
  name: "App",
  components: {
    SideBar,
    PageHeader,
    RequestShiftFormDialog,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    /* eslint-disable-next-line */
    const kcInstance = inject("provideKeycloak") as any;
    const logoutUrl = getCookie(CookieProps.LogoutUri) as string;

    const sidebarImg = computed(() => {
      if (kcInstance.realm === "cp-mep") return require("@/assets/img/logo/logo-mep.png");

      return require("@/assets/img/logo/logo-maqqie.svg");
    });

    const sidebarItems = ref([
      {
        icon: "home",
        name: t("routes.dashboard"),
        routeName: "Dashboard",
        active: false,
      },
      {
        icon: "calendar-days",
        name: t("routes.shifts_overview"),
        routeName: "ShiftsOverview",
        active: false,
      },
      {
        icon: "clock",
        name: t("routes.registrations_overview"),
        routeName: "RegistrationsOverview",
        active: false,
      },
      {
        icon: "building",
        name: t("routes.flexpool"),
        routeName: "Flexpool",
        active: false,
      },
      {
        icon: "star",
        name: t("routes.reviews_overview"),
        routeName: "ReviewsOverview",
        active: false,
      },
    ]);

    /* eslint-disable-next-line */
    const sidebarItemsToShow = ref<any[]>([]);

    const sidebarUtilItems = ref([
      {
        icon: "right-from-bracket",
        name: "Logout",
      },
    ]);

    const hideNavigationItems = () => {
      const selectedClientMemo = computed(() => store.state.selectedClientFull);

      if(!Object.keys(selectedClientMemo.value).length) return;

      if (selectedClientMemo.value.roles.includes(Roles.Bookkeeper)) {
        sidebarItemsToShow.value = sidebarItems.value.filter(
          (item) => item.routeName !== "ShiftsOverview"
        );
        return;
      }

      if (
        selectedClientMemo.value.roles.includes(Roles.Planner) ||
        selectedClientMemo.value.roles.includes(Roles.Floormanager)
      ) {
        sidebarItemsToShow.value = sidebarItems.value.filter(
          (item) => item.routeName !== "RegistrationsOverview"
        );
        return;
      }

      sidebarItemsToShow.value = sidebarItems.value;
    };

    const logoutKeycloak = () => {
      logoutClearCookie();
      kcInstance.logout({ redirectUri: logoutUrl });
    };

    watch(
      [() => router.currentRoute.value, () => store.state.selectedClientFull],
      ([val]) => {
        hideNavigationItems();

        // setting active states on sidebar in regards on changing breadcrumbs navigation
        const routeNames = val.meta.breadcrumbs
          /* eslint-disable-next-line */
          .filter((el: any) => {
            return el.routeName;
          })
          /* eslint-disable-next-line */
          .map((el: any) => el.routeName);

        /* eslint-disable-next-line */
        sidebarItems.value.forEach((el: any) => {
          const elHold = el;
          elHold.active = false;

          if (
            val.name === "Dashboard" &&
            el.routeName === "Dashboard" &&
            !routeNames.length
          ) {
            elHold.active = true;
            return;
          }

          if (
            (val.name === "ShiftsOverview" && el.routeName === "ShiftsOverview") ||
            (routeNames[1] === "ShiftsOverview" && el.routeName === "ShiftsOverview")
          ) {
            elHold.active = true;
            return;
          }

          if (
            (val.name === "RegistrationsOverview" &&
              el.routeName === "RegistrationsOverview") ||
            (routeNames[1] === "RegistrationsOverview" &&
              el.routeName === "RegistrationsOverview")
          ) {
            elHold.active = true;
            return;
          }

          if (
            (val.name === "Flexpool" && el.routeName === "Flexpool") ||
            (routeNames[1] === "Flexpool" && el.routeName === "Flexpool")
          ) {
            elHold.active = true;
            return;
          }

          if (
            (val.name === "ReviewsOverview" && el.routeName === "ReviewsOverview") ||
            (routeNames[1] === "ReviewsOverview" && el.routeName === "ReviewsOverview")
          ) {
            elHold.active = true;
          }
        });
      },
      {
        immediate: true,
      }
    );

    /* eslint-disable-next-line */
    const sidebarItemClick = (item: any) => {
      if (item.name === t("routes.dashboard")) {
        router.push({ name: "Dashboard" });
        return;
      }

      if (item.name === t("routes.shifts_overview")) {
        router.push({ name: "ShiftsOverview" });
        return;
      }
      if (item.name === t("routes.registrations_overview")) {
        router.push({ name: "RegistrationsOverview" });
        return;
      }
      if (item.name === t("routes.flexpool")) {
        router.push({ name: "Flexpool" });
        return;
      }
      if (item.name === t("routes.reviews_overview")) {
        router.push({ name: "ReviewsOverview" });
        return;
      }
      if (item.name === "Logout") {
        logoutKeycloak();
      }
    };

    const selectClient = (client: object | any) => {
      setCookie(CookieProps.ClientId, client.companyClientId);

      store.commit(MutationTypes.SET_SELECTED_CLIENT, client.companyClientId);
      store.commit(MutationTypes.SET_SELECTED_CLIENT_FULL, client);
      store.commit(MutationTypes.SET_DASHBOARD_SHOWN, true);

      hideNavigationItems();
    };

    const getListOfCompanyClients = () => {
      const params = {
        page: 0,
        size: 2,
      } as GetForUserRequest;

      return store
        .dispatch(ActionTypes.GET_COMPANY_CLIENT_LIST, params)
        .then((res: CompanyClientsOfUserResponse) => {
          store.commit(MutationTypes.SET_COMPANY_CLIENT_LIST, res.resultList);
          return res;
        });
    };

    onMounted(async () => {
      const companyClientList = await getListOfCompanyClients();

      const clientIdMemo = parseInt(getCookie(CookieProps.ClientId) as string, 10);

      if (clientIdMemo) {
        await store.dispatch(ActionTypes.GET_COMPANY_CLIENT, clientIdMemo).then((res) => {
          selectClient(res.client || companyClientList.resultList[0]);
        });
      } else {
        selectClient(companyClientList.resultList[0]);
      }
    });

    return {
      sidebarItemClick,
      sidebarItems,
      sidebarUtilItems,
      sidebarImg,
      sidebarItemsToShow,
    };
  },
});
