
import { defineComponent } from "vue";
import MicroModal from "micromodal";

export default defineComponent({
  name: "OverlayDialog",
  emits: ["show", "close", "update:isOpen"],
  props: {
    modalId: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    darkOverlay: {
      type: Boolean,
      default: false,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: "modal__content",
    }
  },
  data() {
    return {
      resolve: null as null | ((name?: string) => void),
      closeTrigger: "data-micromodal-close-dialog",
    };
  },

  watch: {
    isOpen(newVal) {
      this.onChangeState(newVal);
    },
  },

  methods: {
    show() {
      const onDialogShow = () => this.$emit("show");
      const onDialogClose = () => this.$emit("close");
      const emitOpenValue = () => this.$emit("update:isOpen", false);

      MicroModal.show(this.modalId, {
        onShow() {
          onDialogShow();
        },
        onClose() {
          onDialogClose();
          emitOpenValue();
        },
        awaitCloseAnimation: true,
        closeTrigger: "data-micromodal-close-dialog",
      });
    },

    close() {
      MicroModal.close(this.modalId);
    },

    onChangeState(isOpen: boolean) {
      if (isOpen) {
        this.show();
      } else {
        this.close();
      }
    }
  }
});
