
import { defineComponent, ref } from "vue";
import type {
  UploadProps,
  // UploadUserFile,
  UploadInstance,
  UploadFile,
  UploadRawFile,
  UploadFiles,
} from "element-plus";
import { useI18n } from "vue-i18n";
import ToastService from "mq-ui-kit/lib/toast";

export default defineComponent({
  name: "DragDropFileUpload",
  props: {
    limit: {
      type: Number,
      default: 3,
    },
    // file size in MB
    fileSize: {
      type: Number,
      default: 3,
    },
  },
  emits: ["on-list-update", "on-list-remove"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const supportedFileTypes: { [key: string]: string } = {
      jpg: "image/jpeg",
      png: "image/png",
      pdf: "application/pdf",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      txt: "text/plain",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      csv: "text/csv",
    };

    // check the size of the file
    const beforeAvatarUpload: UploadProps["beforeUpload"] = (rawFile: UploadRawFile) => {
      const listOfFileTypes = Object.values(supportedFileTypes);

      if (!listOfFileTypes.includes(rawFile.type)) {
        ToastService.warningToast(t("shift_request.format_not_supported"));
        return false;
      }
      if (rawFile.size / 1024 / 1024 > props.fileSize) {
        ToastService.warningToast(t("shift_request.file_is_bigger_then", props.fileSize));
        return false;
      }
      return true;
    };

    const percOfUsers = (perc: number | string) => ({ width: `${perc}%` });

    const uploadRef = ref<UploadInstance>();

    const emitUpdateList = (uploadFiles: UploadFiles) => {
      emit("on-list-update", uploadFiles);
    };

    const onRemoveHook: UploadProps["onRemove"] = (file: UploadFile, uploadFiles: UploadFiles) => {
      emitUpdateList(uploadFiles);
    };

    const handleRemove = (file: UploadFile) => {
      (uploadRef.value as UploadInstance).handleRemove(file);
    };

    const clearList = () => {
      (uploadRef.value as UploadInstance).clearFiles();
    };

    const handleExceed = () => {
      ToastService.warningToast(
        t("shift_request.max_number_files_exceeded", props.limit)
      );
    };

    const onChangeList: UploadProps["onChange"] = (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
      if (beforeAvatarUpload(uploadFile.raw as UploadRawFile)) {
        emitUpdateList(uploadFiles);
      } else {
        handleRemove(uploadFile);
        emitUpdateList(uploadFiles);
      }
    };

    const formatBytes = (bytes: number | string, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      const i = Math.floor(Math.log(bytes as number) / Math.log(k));

      return `${parseFloat(((bytes as number) / k ** i).toFixed(dm))} ${sizes[i]}`;
    };

    return {
      beforeAvatarUpload,
      handleExceed,
      percOfUsers,
      formatBytes,
      uploadRef,
      onChangeList,
      clearList,
      handleRemove,
      onRemoveHook,
    };
  },
});
