/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyClientReviewLabelOfEmployee,
    CompanyClientReviewLabelOfEmployeeFromJSON,
    CompanyClientReviewLabelOfEmployeeFromJSONTyped,
    CompanyClientReviewLabelOfEmployeeToJSON,
} from './CompanyClientReviewLabelOfEmployee';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';

/**
 * 
 * @export
 * @interface CompanyClientEmployeeReviewResponse
 */
export interface CompanyClientEmployeeReviewResponse {
    /**
     * The ID of review record
     * @type {number}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    reviewId?: number;
    /**
     * Review score of this employee
     * @type {number}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    reviewScore: number;
    /**
     * Shift's gig name
     * @type {string}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    gigName: string;
    /**
     * Shift ID for which the review is given
     * @type {number}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    shiftId: number;
    /**
     * Time when shift starts
     * @type {Date}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    shiftStartTimestamp: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    dateCreated?: Date;
    /**
     * Already chosen labels for this review
     * @type {Array<CompanyClientReviewLabelOfEmployee>}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    labels: Array<CompanyClientReviewLabelOfEmployee>;
    /**
     * 
     * @type {Employees}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    employee: Employees;
    /**
     * Additional notes after on review
     * @type {string}
     * @memberof CompanyClientEmployeeReviewResponse
     */
    note?: string;
}

export function CompanyClientEmployeeReviewResponseFromJSON(json: any): CompanyClientEmployeeReviewResponse {
    return CompanyClientEmployeeReviewResponseFromJSONTyped(json, false);
}

export function CompanyClientEmployeeReviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientEmployeeReviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewId': !exists(json, 'reviewId') ? undefined : json['reviewId'],
        'reviewScore': json['reviewScore'],
        'gigName': json['gigName'],
        'shiftId': json['shiftId'],
        'shiftStartTimestamp': (new Date(json['shiftStartTimestamp'])),
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'labels': ((json['labels'] as Array<any>).map(CompanyClientReviewLabelOfEmployeeFromJSON)),
        'employee': EmployeesFromJSON(json['employee']),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function CompanyClientEmployeeReviewResponseToJSON(value?: CompanyClientEmployeeReviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewId': value.reviewId,
        'reviewScore': value.reviewScore,
        'gigName': value.gigName,
        'shiftId': value.shiftId,
        'shiftStartTimestamp': (value.shiftStartTimestamp.toISOString()),
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'labels': ((value.labels as Array<any>).map(CompanyClientReviewLabelOfEmployeeToJSON)),
        'employee': EmployeesToJSON(value.employee),
        'note': value.note,
    };
}

