<template>
  <svg
    width="42" height="40" viewBox="0 0 42 40"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable -->
    <path
      d="M18.9427 33.9717L10.4783 38.4492C9.48317 38.9756 8.3167 38.1313 8.50588 37.0215L10.1305 27.4905C10.3547 26.1753 9.92034 24.8337 8.96785 23.8996L2.07336 17.1376C1.27206 16.3517 1.71676 14.9881 2.82733 14.8258L12.3259 13.437C13.6541 13.2428 14.8012 12.4065 15.3924 11.2013L19.628 2.56598C20.1247 1.55331 21.5682 1.55331 22.0649 2.56598L26.3006 11.2013C26.8917 12.4065 28.0388 13.2428 29.367 13.437L38.8656 14.8258C39.9762 14.9881 40.4209 16.3517 39.6195 17.1376L32.7251 23.8996C31.7726 24.8337 31.3382 26.1753 31.5624 27.4905L33.187 37.0215C33.3762 38.1313 32.2097 38.9756 31.2146 38.4492L22.7502 33.9717C21.5592 33.3417 20.1337 33.3417 18.9427 33.9717Z" 
      stroke-width="2.71432"
    />
  </svg>
</template>
