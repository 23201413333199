/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The unit for amount-property.
 * @export
 */
export const Unit = {
    Km: 'KM',
    Uur: 'UUR',
    Dag: 'DAG',
    Euro: 'EURO',
    Day: 'DAY',
    Delivery: 'DELIVERY',
    Expenses: 'EXPENSES',
    Hour: 'HOUR',
    Kilometer: 'KILOMETER',
    Project: 'PROJECT',
    Service: 'SERVICE'
} as const;
export type Unit = typeof Unit[keyof typeof Unit];


export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    return json as Unit;
}

export function UnitToJSON(value?: Unit | null): any {
    return value as any;
}

