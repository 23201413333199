
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import ToastService from "mq-ui-kit/lib/toast";
import { Icon } from "mq-ui-kit/lib/Icon";
import CardWrap from "@/components/ui/cards/CardWrap.vue";
import ActionTypes from "@/store/types/action-types";
import Avatar from "@/components/ui/Avatar.vue";
import {
  Employee,
  EmployeesOfCompanyClientRequest,
  EmployeesOfCompanyClientFilter,
  EmployeeFavouriteOperationRequest,
  EmployeesOfCompanyClientResponse,
} from "@/services/rest/dashboard";
import FlexPoolSkeleton from "@/components/flexpool/FlexPoolSkeleton.vue";
import Search from "@/components/ui/Search.vue";
import { debounce } from "@/helpers/helper-methods";

import ShiftDetailsMemberModal from "@/components/shift-details/ShiftDetailsMemberModal.vue";
import FlexPoolFilter from "@/components/flexpool/FlexPoolFilter.vue";

export default defineComponent({
  name: "FlexPool",
  components: {
    CardWrap,
    Avatar,
    FlexPoolSkeleton,
    Icon,
    ShiftDetailsMemberModal,
    Search,
    FlexPoolFilter,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const dashboardShown = computed(() => store.state.isDashboardShown);
    const clientCompanyEmployees = ref<Employee[]>([]);
    const totalElements = ref(0);
    const searchValue = ref("");
    const isFilterOpen = ref(false);
    const asideId = ref("flexpool-filter");

    // object for aside filter (no fullName in this one)
    const filterObj = ref<EmployeesOfCompanyClientFilter>({
      favourite: false,
    });

    // pagination settings
    const currentPage = ref(1);
    const perPage = ref(8);

    const singleMember = ref<Employee>({
      employeeId: 0,
      fullName: "",
      firstName: "",
      lastName: "",
      profilePictureUrl: "",
      favourite: false,
      isTeamLeader: false,
      functionName: "",
      reviewScore: 0,
      reviewScoreAverage: 0,
      reviewScoreCount: 0,
      numberOfCompaniesShifts: 0,
      numberOfCompaniesClientsShifts: 0,
    });

    const openFilter = () => {
      isFilterOpen.value = true;
    };

    const closeFilter = () => {
      isFilterOpen.value = false;
    };

    const setQueryParams = (currPage = 1) => {
      router.replace({
        path: "/flexpool",
        query: { page: currPage },
      });
    };

    const getEmployee = (employeeId: number) => {
      const params = {
        employeeId,
      };

      return store.dispatch(ActionTypes.GET_COMPANY_CLIENT_EMPLOYEE, params);
    };

    const employeeReviewsProfileClick = (employee: Employee) => {
      router.push({ name: "EmployeeReviews", params: { id: employee.employeeId } });
    };

    const getCompanyClientEmployees = (
      params: EmployeesOfCompanyClientRequest = {
        size: perPage.value,
        page: currentPage.value - 1,
      }
    ) => {
      isLoading.value = true;

      setQueryParams(currentPage.value);

      store
        .dispatch(ActionTypes.GET_COMPANY_CLIENT_EMPLOYEES, params)
        .then((res : EmployeesOfCompanyClientResponse) => {
          clientCompanyEmployees.value = res.resultList;
          totalElements.value = res.page.totalElements;
        })
        .catch((err) => {
          console.log("Error:", err);
          clientCompanyEmployees.value = [];
          ToastService.errorToast(t("errors.something_went_wrong"));
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;

      getCompanyClientEmployees({
        size: perPage.value,
        page: currentPage.value - 1,
        employeesOfCompanyClientFilter: {
          fullName:
            searchValue.value.length !== 0 && searchValue.value.length >= 3
              ? searchValue.value
              : null,
          favourite: filterObj.value.favourite ? filterObj.value.favourite : null,
        } as EmployeesOfCompanyClientFilter,
      });
    };

    const debouncedSearch = debounce((searchValueData: string) => {
      searchValue.value = searchValueData;

      if (searchValue.value.length > 0 && searchValue.value.length < 3) {
        ToastService.warningToast(t("flexpool.min_lenght_search"));
        return;
      }

      onPageChange(1);
    }, 500);

    const favouriteEmployee = (employeeId: number) => {
      const params: EmployeeFavouriteOperationRequest = {
        employeeFavouriteRequest: {
          employeeId,
        },
      };

      store
        .dispatch(ActionTypes.FAVOURITE_EMPLOYEE, params)
        .then((res) => {
          const favourite = clientCompanyEmployees.value.find(
            (el) => el.employeeId === res.employeeId
          ) as Employee;

          favourite.favourite = res.favourite;
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    };

    const dialogClosed = (employee: Employee) => {
      getEmployee(employee.employeeId as number)
        .then((res) => {
          const index = clientCompanyEmployees.value.findIndex(
            (el) => el.employeeId === res.employeeId
          );

          clientCompanyEmployees.value[index] = employee;
        })
        .catch((e) => {
          console.log("Error", e);
        });
    };

    const resetFilterObj = () => {
      filterObj.value.favourite = false;
    };

    const clearFilterData = () => {
      closeFilter();
      resetFilterObj();
      onPageChange(1);
    };

    const applyFilterData = (data: EmployeesOfCompanyClientFilter) => {
      closeFilter();

      filterObj.value = { ...data };

      onPageChange(1);
    };

    onMounted(() => {
      if (route.query.page)
        currentPage.value = parseInt(route.query.page as string, 10);

      getCompanyClientEmployees();
    });

    return {
      dashboardShown,
      clientCompanyEmployees,
      isLoading,
      currentPage,
      perPage,
      favouriteEmployee,
      onPageChange,
      totalElements,
      singleMember,
      dialogClosed,
      searchValue,
      debouncedSearch,
      isFilterOpen,
      openFilter,
      closeFilter,
      clearFilterData,
      applyFilterData,
      asideId,
      employeeReviewsProfileClick,
    };
  },
});
