
import { defineComponent, PropType, ref } from "vue";

import { useDebounceFn } from "@vueuse/core";
import ShiftDetailsSidebarItem from "@/components/shift-details/ShiftDetailsSidebarItem.vue";
import ShiftDetailsSidebarItemSkeleton from "@/components/shift-details/ShiftDetailsSidebarItemSkeleton.vue";
import { ShiftResponse } from "@/services/rest/dashboard";

export default defineComponent({
  name: "ShiftDetailsSidebar",
  components: { ShiftDetailsSidebarItem, ShiftDetailsSidebarItemSkeleton },
  props: {
    sidebarItems: {
      type: Object as PropType<ShiftResponse[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    perPageSkeleton: {
      type: Number,
      default: 5,
    }
  },
  emits: ["on-top", "on-bottom", "scroll-position"],
  setup(props, { emit }) {
    const activeIndexHolder = ref<number>(0);
    const firstPageLoad = ref(false);

    const scrollPosition = ref(false);

    const handleScroll = (event: UIEvent) => {
      // prevent firing method when selected user is already
      // at the bottom or top of the scroll area
      if (!firstPageLoad.value) {
        firstPageLoad.value = true;
        return;
      }

      const element = event.target as HTMLDivElement;

      if (element.scrollTop === 0) {
        scrollPosition.value = true;
        emit("scroll-position", scrollPosition.value);
      } else if (element.scrollTop + element.clientHeight === element.scrollHeight) {
        scrollPosition.value = false;
        emit("scroll-position", scrollPosition.value);
      }
    };

    const debouncedFn = useDebounceFn((event: UIEvent) => {
      handleScroll(event);
    }, 100);

    return {
      activeIndexHolder,
      handleScroll,
      debouncedFn,
      firstPageLoad
    };
  },
});
