/* tslint:disable */
/* eslint-disable */
/**
 * Employees API
 * API to get company clients employees
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Employees,
    EmployeesFromJSON,
    EmployeesFromJSONTyped,
    EmployeesToJSON,
} from './Employees';

/**
 * 
 * @export
 * @interface EmployeeOnShiftOverview
 */
export interface EmployeeOnShiftOverview {
    /**
     * 
     * @type {Employees}
     * @memberof EmployeeOnShiftOverview
     */
    employee: Employees;
    /**
     * Shift ID for which the review is given
     * @type {number}
     * @memberof EmployeeOnShiftOverview
     */
    shiftId: number;
    /**
     * Shift's gig name
     * @type {string}
     * @memberof EmployeeOnShiftOverview
     */
    gigName: string;
    /**
     * Time when shift starts
     * @type {Date}
     * @memberof EmployeeOnShiftOverview
     */
    shiftStartTimestamp: Date;
    /**
     * Time when shift ends
     * @type {Date}
     * @memberof EmployeeOnShiftOverview
     */
    shiftEndTimestamp: Date;
}

export function EmployeeOnShiftOverviewFromJSON(json: any): EmployeeOnShiftOverview {
    return EmployeeOnShiftOverviewFromJSONTyped(json, false);
}

export function EmployeeOnShiftOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeOnShiftOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employee': EmployeesFromJSON(json['employee']),
        'shiftId': json['shiftId'],
        'gigName': json['gigName'],
        'shiftStartTimestamp': (new Date(json['shiftStartTimestamp'])),
        'shiftEndTimestamp': (new Date(json['shiftEndTimestamp'])),
    };
}

export function EmployeeOnShiftOverviewToJSON(value?: EmployeeOnShiftOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employee': EmployeesToJSON(value.employee),
        'shiftId': value.shiftId,
        'gigName': value.gigName,
        'shiftStartTimestamp': (value.shiftStartTimestamp.toISOString()),
        'shiftEndTimestamp': (value.shiftEndTimestamp.toISOString()),
    };
}

