/* tslint:disable */
/* eslint-disable */
/**
 * Get registrations to approve
 * Get registrations to approve count
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkRegistrationLinesResponse
 */
export interface BulkRegistrationLinesResponse {
    /**
     * Indication whether registration lines are approved
     * @type {boolean}
     * @memberof BulkRegistrationLinesResponse
     */
    approved: boolean;
}

export function BulkRegistrationLinesResponseFromJSON(json: any): BulkRegistrationLinesResponse {
    return BulkRegistrationLinesResponseFromJSONTyped(json, false);
}

export function BulkRegistrationLinesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkRegistrationLinesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approved': json['approved'],
    };
}

export function BulkRegistrationLinesResponseToJSON(value?: BulkRegistrationLinesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approved': value.approved,
    };
}

