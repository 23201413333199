import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b3476dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "breadcrumbs"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "breadcrumbs__separator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_ctx.breadcrumbList.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbList, (breadcrumb, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              onClick: ($event: any) => (_ctx.routeTo(index)),
              class: _normalizeClass({ linked: !!breadcrumb.link })
            }, [
              (!index)
                ? (_openBlock(), _createBlock(_component_FaIcon, {
                    key: 0,
                    class: "breadcrumbs__icon-house",
                    icon: "home"
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(`routes.${breadcrumb.translationKey}`)) + " ", 1),
              _createElementVNode("span", _hoisted_3, [
                (_ctx.breadcrumbList.length && index < _ctx.breadcrumbList.length - 1)
                  ? (_openBlock(), _createBlock(_component_FaIcon, {
                      key: 0,
                      icon: "chevron-right",
                      class: "breadcrumbs__icon-arrow icon"
                    }))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}