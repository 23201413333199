function getGoogleAnalyKey(token: string): Promise<Response> {
  const options = {
    method: "get",
    headers: {
      "Authorization": `Bearer ${token}`,
    }
  }

  return fetch("/rpc/google-analytics-key", options);
}


export default getGoogleAnalyKey;