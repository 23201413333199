/* tslint:disable */
/* eslint-disable */
/**
 * Company Clients API
 * API to get company clients data
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Single Company Client - user roles mapping object.
 * @export
 * @interface CompanyClientOfUserResponse
 */
export interface CompanyClientOfUserResponse {
    /**
     * Company Client ID
     * @type {number}
     * @memberof CompanyClientOfUserResponse
     */
    companyClientId: number;
    /**
     * Company Client Trade Name
     * @type {string}
     * @memberof CompanyClientOfUserResponse
     */
    tradeName: string;
    /**
     * Company Client Contact roles
     * @type {Array<string>}
     * @memberof CompanyClientOfUserResponse
     */
    roles: Array<string>;
}

export function CompanyClientOfUserResponseFromJSON(json: any): CompanyClientOfUserResponse {
    return CompanyClientOfUserResponseFromJSONTyped(json, false);
}

export function CompanyClientOfUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyClientOfUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyClientId': json['companyClientId'],
        'tradeName': json['tradeName'],
        'roles': json['roles'],
    };
}

export function CompanyClientOfUserResponseToJSON(value?: CompanyClientOfUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyClientId': value.companyClientId,
        'tradeName': value.tradeName,
        'roles': value.roles,
    };
}

