
import { computed, defineComponent, PropType, ref } from "vue";
import { FormRow } from "mq-ui-kit/lib/FormRow";
import { Button } from "mq-ui-kit/lib/Button";
import { CheckBox } from "mq-ui-kit/lib/CheckBox";
import { FormControlModel } from "mq-ui-kit/lib/form-control-model";
import OverlayAside from "@/components/ui/OverlayAside.vue";
import {
  EmployeesOfCompanyClientFilter
} from "@/services/rest/dashboard";

export default defineComponent({
  name: "FlexPoolFilter",
  components: {
    FormRow,
    OverlayAside,
    Button,
    CheckBox
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    asideId: {
      type: String,
      required: true
    },
    data: {
      type: Object as PropType<EmployeesOfCompanyClientFilter>,
    }
  },
  emits: ["close", "clear", "show-results"],
  setup(props, { emit }) {
    const favourite = ref(new FormControlModel({
      value: false
    }));

    const exportedObj = computed(() => ({
        favourite: favourite.value.getValue()
      } as EmployeesOfCompanyClientFilter));

    const clearSelection = () => {
      favourite.value.reset();
      emit("clear");
    };

    const showResults = () => {
      emit("show-results", exportedObj.value);
    };

    const closeFilter = () => {
      emit("close");
    };

    return {
      favourite,
      clearSelection,
      showResults,
      closeFilter,
    };
  }
});
