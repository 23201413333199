/* tslint:disable */
/* eslint-disable */
/**
 * Shifts API
 * API to get shifts
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Pagination response holding page size & result counts
 * @export
 * @interface PaginationPage
 */
export interface PaginationPage {
    /**
     * Number of items of the page
     * @type {number}
     * @memberof PaginationPage
     */
    size: number;
    /**
     * Total number of results
     * @type {number}
     * @memberof PaginationPage
     */
    totalElements: number;
    /**
     * Total number of pages
     * @type {number}
     * @memberof PaginationPage
     */
    totalPages: number;
    /**
     * Number of the current page
     * @type {number}
     * @memberof PaginationPage
     */
    number: number;
}

export function PaginationPageFromJSON(json: any): PaginationPage {
    return PaginationPageFromJSONTyped(json, false);
}

export function PaginationPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'number': json['number'],
    };
}

export function PaginationPageToJSON(value?: PaginationPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'number': value.number,
    };
}

